<template>
    <project  ref="project"
        classContainer="united-container united-2" 
        classOverlay="overlay-united"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"
        :vimeoContainer="this.vimeoContainer"/>
    
    <!--<img class="example" src="@/assets/united/home3.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    

    export default {
        components: { Project }, 
        name: "unitedThree",
        data: () => ({
            ghostVideo: true,
            vimeoContainer:{
                name: 'united3'
            },
            bubbleOneData: {
                class: 'bubble-one-united-3',
                text: "<p>"+
                                "Shot over 2 days at multiple locations<br/>"+ 
                                "in Philadelphia with a base studio<br/>"+
                                "packed with actors, extras, wardrobe,<br/>" + 
                                "props, etc it was a massive blur of a<br/>"+
                                "production, and it went wonderfully. I<br/>"+
                                "also worked with a musician to create<br/>"+
                                "original music that added<br/>"+
                                "tremendously to the video’s emotional<br/>"+
                                "impact – and the United Way loved it."+
                            " </p>",
                title:'Donation Video', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop:'4%', color:'#373960', colorOverlay:'#fff', colorText: '#0f3456'
            },
            videoOneData:{
                class: 'united-3-video-1',
                videoUrl: 'assets/united/full-video.mp4',
                poster: 'assets/united/h3-video.jpg',
                isUnitedCenter: true,
                img: 'assets/united/h3-video.jpg',
                onAxisY:false
            },
            videoTwoData:{
                class: 'united-3-video-2',
                videoUrl: 'assets/united/home3-left.mp4',
                poster:'assets/united/united3-cover-1.jpg',
                onAxisY:false
            },
            videoThreeData:{
                class: 'united-3-video-3',
                videoUrl: 'assets/united/home3-right.mp4',
                poster:'assets/united/united3-cover-3.jpg',
                onAxisY:false
            },
        }),
        
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            },
            closeMainVideo(){
                return this.$refs.project.closeMainVideo()
            }
        },
    }
</script>

<style>

    .united-3-video-1{
        top: 23.5% !important;
        left: 26.5% !important;
        width: 48.5vw !important;
        height: 27vw !important;
    }

    .united-3-video-2{
        top: 32.5% !important;
        left: 14.5% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .united-3-video-3{
        top: 58.5% !important;
        left: 66.25% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .bubble-one-united-3{
        left: 18%;
        top: 60%;
        height: 17.5vw;
        width: 17.5vw;
    }

    @media (orientation: portrait) {
        .bubble-one-united-3 {
            height: 57vw;
            width: 57vw;
            left: 10.25%;
            top: 50.25%;
        }

        .united-3-video-3{
            top: 58% !important;
            left: 52.25% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-3-video-2{
            top: 24% !important;
            left: 10.5% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-3-video-1{
            top: 33% !important;
            left: 6.5% !important;
            width: 84.5vw !important;
            height: 48vw !important;
        }
    }
</style>