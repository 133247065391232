<template>
  <home-1 ref="homeOne" />
</template>

<script>
import Home1 from './Home1.vue'
import gsap from 'gsap'


export default { 
    name: "HomeSlider",
    components: {
        Home1,
    },
    data: () => ({
      idleTimer: 0
    }),mounted() {
    },
    
    methods: {
      
      
      
      
      showLogo(){
        gsap.set('.home-chris', {autoAlpha:1})
        gsap.set('.loader-chris', {autoAlpha:0})
        
        gsap.set('.top-circle-hover-zone', {autoAlpha: 1});
        gsap.set('.bottom-circle-hover-zone', {autoAlpha: 1});

        setTimeout(()=>{ 
          this.$refs.homeOne.startAnimation()
          var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
          events.forEach((name)=> {
            document.addEventListener(name, this.$refs.homeOne.spyUser, true); 
            this.$refs.homeOne.spyUser()
          });
        }, 500);
      },
      
    }

    
}
</script>

<style>

#home-chris-non-change img{
  width: 100%;
  height: 100%;
}

#home-chris-non-change{
  visibility: hidden;
}

.say-hello-btn-container img{
  fill: #fff;
  width: 100%;
  height: 100%;
}

.say-hello-btn-container{
  position: absolute;
  width: 7.2vw;
  height: 0.8vw;
  bottom: 7%;
  right: 6%;
}

.top-circle-hover-zone{
  position: absolute;
  width: 20vw;
  height: 15vh;
  left: 0;
  top: 0;  
  z-index: 4;
}

.bottom-circle-hover-zone{
  position: absolute;
  width: 20vw;
  height: 15vh;
  right: 0;
  bottom: 0;  
  z-index: 4;
}



  .close-bio-btn::after,
  .close-bio-btn::before,
  .close-btn-say-hello::after,
  .close-btn-say-hello::before{
      background-color: var(--color);
      opacity: 0.5;
  }
  .close-hello-btn, .close-bio-btn{
    opacity: 0;
    visibility: 0;
  }

  .hello-container, .popup-container{
    opacity: 0;
    visibility: hidden;
  }
  .home-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    opacity: 0;
  }
  :root{
    
  }
  .home-top-red-circle{
    background-color: #ed1b24;
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: circle(70% at -26.5% var(--topCircle));
    
  }

  .home-top-red-circle-close{
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: circle(62.5% at -55% -55%);
  }

  .home-bottom-red-circle-close{
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path:  circle(62.5% at 134% 178.5%);
  }

  .home-bottom-red-circle{
    background-color: #ed1b24;
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: circle(70% at 128% var(--bottomCircle));
  }

  .home-bottom-red-circle .say-hello-btn-container svg{
    fill: #000;
  }



  .home-background{
    background-color: #000;
  }
 

  

  .popup-container{
    position: absolute;
    width: 100%;
    height: 100%;
  }


  .say-hello-black{
    color: #000 !important;
    cursor: pointer;
  }



  #home-chris-non-change, .home-chris{
    position: absolute;
    width: 10.925vw;
    height: 0.825vw;
    top: 7%;
    left: 6%;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
  }

  
  .home-chris2 svg{
    fill: #000 !important;
  }
  /*.home-chris2{
    color: #000 !important;
    top:7%  !important;
    left: 6%  !important;
    font-size: 1.5rem  !important;
    cursor: pointer;
  }*/

@media (orientation: portrait) {
    #home-chris-non-change, .home-chris{
        height: 3vw;
        width: 36vw;
        top: 4.5%;
        left: 6.5%;
    }

    .say-hello-btn-container {
        width: 23vw;
        height: 2.5vw;
        bottom: 4%;
        right: 6%;
    }

    .home-bottom-red-circle {
        clip-path: circle(62% at 135.5% var(--bottomCircle));
    }

    .home-top-red-circle{
        clip-path: circle(70% at -52.7% var(--topCircle));
    }

    .top-circle-hover-zone {
      width: 44vw;
      height: 10vh;
    }

    .bottom-circle-hover-zone {
      width: 30vw;
      height: 10vh;
    }
    
    
}
  
</style>