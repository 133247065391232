<template>
    <project ref="project"
        classContainer="vizio-container vizio-2" 
        classOverlay="overlay-vizio"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"/>

    <!--<img class="example" src="@/assets/vizio/vizio2.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: { Project }, 
        name: "VizioTwo",
        data: () => ({
            ghostVideo: true,
            bubbleOneData: {
                class: 'bubble-one-vizio-2',
                text: "<p>"+
                            "Vizio thought of themselves as<br/>"+ 
                            "disruptive, but they had yet to define<br/>"+
                            "what that looked like from a brand<br/>" + 
                            "perspective.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "They wanted their new ecosystem to<br/>"+
                            "clearly stand out and be the new,<br/>"+
                            "defining expression of the brand’s<br/>"+
                            "bold spirit.<br/>"+
                        "</p>",
                title:'Connected Devices', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop: "4%", color:"#03314e", 
                colorOverlay:"#fff", colorText:"#0f3456"
            },
            videoOneData:{
                class: 'vizio-2-video-1',
                videoUrl: 'assets/vizio/home2-center.mp4',
                poster: 'assets/vizio/vizio2-cover-2.jpg',
                isUnitedCenter: false,  
                onAxisY:true
            },
            videoTwoData:{
                class: 'vizio-2-video-2',
                videoUrl: 'assets/vizio/home2-left.mp4',
                poster:'assets/vizio/vizio2-cover-1.jpg',
                onAxisY:true
            },
            videoThreeData:{
                class: 'vizio-2-video-3',
                videoUrl: 'assets/vizio/home2-right.mp4',
                poster:'assets/vizio/vizio2-cover-3.jpg',
                onAxisY:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .vizio-2-video-3{
        top: 24% !important;
        left: 64% !important;
        width: 14.5vw !important;
        height: 25.5vw !important;
    }
    .vizio-2-video-2{
        top: 24% !important;
        left: 43.5% !important;
        width: 14.5vw !important;
        height: 25.5vw !important;
    }
    .vizio-2-video-1{
        top: 24% !important;
        left: 22.5% !important;
        width: 14.5vw !important;
        height: 25.5vw !important;
    }
    .bubble-one-vizio-2{
        left: 60.5%;
        top: 60.25%;
        height: 17.5vw;
        width: 17.5vw;
    }

    @media (orientation: portrait) {
        .bubble-one-vizio-2 {
            height: 57vw;
            width: 57vw;
            left: 35.5%;
            top: 47.25%;
        }

        .vizio-2-video-3, .vizio-2-video-2, .vizio-2-video-1{
            top: 40% !important;
            width: 20vw !important;
            height: 35vw !important;

        }

        .vizio-2-video-1{
            left: 10vw !important;
        }

        .vizio-2-video-2{
            left: 40vw !important;
        }
        
        .vizio-2-video-3{
            left: 70vw !important;
        }
    }
</style>