<template>
    <div class ="container detail-project-container">
        <div class ="slider-content" ref="sliderData">
            <slot > </slot>
        </div>
        
        <div class="slider-overlay"></div>

        <div class="arrow-safe-distance arrow-safe-distance-right project-arrow visible-landscape" ref="nextArrow" 
                @click="next()" 
                @mouseover="showSliderOverlay(0)" 
                @mouseleave="hideSliderOverlay(0)">
            <div class="detail-arrow arrow-container next-arrow next-arrow-1 arrow-btn" >
            </div>
        </div>
        <div class="arrow-safe-distance arrow-safe-distance-left project-arrow visible-landscape" @click="prev()" 
                @mouseover="showSliderOverlay(1)"
                @mouseleave="hideSliderOverlay(1)">
            <div class="detail-arrow arrow-container prev-arrow prev-arrow-1 arrow-btn" ref="preArrow"  >
                
            </div>
        </div>
        <div class="bullets project-bullets" ref="sliderBullets">
            <div class="active" @click="goto(0)" 
                                @mouseover="showSliderOverlay(-1)"
                                @mouseleave="hideSliderOverlay(-1)">&bull;</div>
            <div @click="goto(1)"   @mouseover="showSliderOverlay(-1)"
                                    @mouseleave="hideSliderOverlay(-1)">&bull;</div>
            <div @click="goto(2)"   @mouseover="showSliderOverlay(-1)"
                                    @mouseleave="hideSliderOverlay(-1)">&bull;</div>
        </div>
        
    </div>
</template>

<script>
    import gsap from 'gsap'
    import CSSRulePlugin from 'gsap/CSSRulePlugin'
    gsap.registerPlugin(CSSRulePlugin);
    export default {
        name: "slider",
        props: ['color'],
        data: () => ({
            items: [],
            bullets: [],
            actual: 1,
            isAnimating: false
        }),
        computed: {
            arrowColor(){
                
                return this.color
            }
        },
        mounted() {
            
            this.items = this.$refs.sliderData.children;
            
            this.bullets = this.$refs.sliderBullets.children
            
            this.items[0].classList.add('visible')
            for(var i = 1; i < this.items.length; ++i){
                this.items[i].classList.add('hide')
            }
            this.actual = 0

            //alert(this.color)
            gsap.set(this.$refs.nextArrow, {"--color": '#fff'})
            gsap.set(this.$refs.preArrow, {"--color": '#fff'})
            gsap.set('.next-arrow-1', {opacity: 0.15})
            gsap.set('.prev-arrow-1', {opacity: 0.15})
            
            //this.$emit('changedTo', this.actual)
            
        },
        methods: {
            goto: function (val) {
                if(val==this.actual) return
                if(this.isAnimating) return
                
                this.isAnimating = true
                var before = this.actual;
                var after = val;
                this.bullets[before].classList.remove('active')
                
                this.actual = val
                this.bullets[after].classList.add('active')
                this.$emit('changedTo', before, this.actual)
            },
            prev: function () {
                if(this.isAnimating) return
                
                var temp = this.actual - 1
                if(temp<0) temp = this.items.length-1
                this.goto(temp)
            },
            next: function () {
                if(this.isAnimating) return
                var temp = this.actual + 1
                if(temp>=this.items.length) temp = 0
                this.goto(temp)
            },
            showSliderOverlay(index){
                if(index!==undefined)
                    this.$emit('arrowHover')
                
                gsap.to('.slider-overlay', {autoAlpha:0.3, duration: 0.25})
                //gsap.set(this.$refs.sliderData, {filter: 'blur(3px)'})
                if(index==1)
                    gsap.to('.prev-arrow-1', {opacity: 0.5})
                else if(index==0) gsap.to('.next-arrow-1', {opacity: 0.5})
            },
            hideSliderOverlay(index){
                if(index!==undefined)
                    this.$emit('arrowLeave')
                if(index==1)
                    gsap.to('.prev-arrow-1', {opacity: 0.15})
                else if(index==0) gsap.to('.next-arrow-1', {opacity: 0.15})
                gsap.to('.slider-overlay', {autoAlpha: 0, duration: 0.25})
                //gsap.set('.slider-content', {filter: 'blur(0px)'})
            }
        }
    }
</script>

<style>

.arrow-safe-distance-right{
    right: 0;
}

.arrow-safe-distance-left{
    left: 0;
}

.arrow-safe-distance{
    position: absolute;
    width: 10vw;
    height: 25vh;
    top: 37.5vh;

}

.visible{
    visibility: visible;
  }
  .hide{
    visibility: hidden;
    opacity: 0;
  }
.slider-overlay{
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
}

.slide-visible{
    visibility: visible;
}
.slider-content{
    width: 100%;
    height: 100%;
    position: absolute;
    /*background-color: blue;*/
}

.arrow-container{
    position: absolute;
    height: 5vw;
    width: 5vw;
    top: calc(50% - 2.5vw);
    cursor: pointer;
    
    text-align: center;
}

.bullets{
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 7%;
    color: #fff;
    text-align: center;    
}

.bullets div.active{
    opacity: 1;
    color: #fff;
}

.bullets div:hover{
    color: #fff;
    opacity: 1;
    z-index: 1;
}

.bullets div{
    font-size: 2rem;
    display: inline-block;
    padding-left: 1.25rem;
    cursor: pointer;
    opacity: 0.25;
    
}

.project-bullets{
    bottom: 10.5%;
}



.logo{
    position: absolute;
    height: auto;
    top: 7%;
    left: 6vw;
    width: 11.5%;
    display: none;
  }
  .arrow{
    height: 100%;
    opacity: 0.3;
  }
  .arrow:hover{
    opacity: 1;

  }

  .next-arrow{
    right: 6.5vw;
    color: white;
    top: 49%
  }

  .prev-arrow{
    left: 6.5vw;
    top: 49%;
    transform: rotate(180deg);
  }


.slider-background{
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

@media (orientation: portrait) {
    .logo {
        height: 8vw;
        min-height: 30px;
        top: 4.5%;
        left: 6vw;
        width: auto;
        display: none;
    }
}
</style>