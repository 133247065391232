<template>
    <project  ref="project"
        classContainer="thq-container game-3" 
        classOverlay="overlay-game"
        
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewSliderOneData="this.previewSliderOneData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"/>
    
    <!--<img class="example" src="@/assets/thq/home1.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {
            Project 
        }, 
        name: "sr3",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-thq',
                text: "<p>"+
                            "THQ was so excited about the work<br/>"+ 
                            "we were doing for Saints Row that<br/>"+
                            "they asked us to redesign their<br/>" + 
                            "company website, which housed all<br/>"+
                            "their game titles. After we began that<br/>"+
                            "process, even more impressed, they<br/>"+
                            "asked us to design UI for one of their<br/>"+
                            "game titles, Metro 2033.<br/>"+
                        " </p>",
                title: "Video Games", 
                fontSizeInnerText:"0.79rem", 
                marginLeft:"-1.6rem",
                marginTop:"4%",
                color:"#2a3134", 
                colorOverlay:"#fff", 
                colorText:"#2a3134"
            },
            bubbleTwoData:{
                class:  'bubble-two-wb',
                text:   "<p>BRANDING & CONCEPTING</p><br/>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL DESIGN & UI DESIGN</p><br/>"+
                        "<p>VIDEO GAME UI</p>",
                color:"#2a3134", 
                colorOverlay:"#56656c", 
                colorText:"#fff"
            },
            previewSliderOneData:{
                class: "game3-video",
                images:['assets/wb/THQ_Screen_1.jpg', 'assets/wb/THQ_Screen_2.jpg', 'assets/wb/THQ_Screen_3.jpg']
            },
            previewTwoData:{
                class:"thq-1-preview", 
                img:"assets/thq/thq_slide_2.jpg",
                forward:true,
                isBrik: true,
                upDown:false
                
            },
            previewOneData:{
                class:"thq-2-preview", 
                img:"assets/thq/thq_slide_1.jpg",
                forward:false,
                isBrik: true,
                upDown:false
                
            },

            nBubbles: ['.bubble-one-thq', '.bubble-two-thq'],
            previews: ['.thq-1-preview', '.thq-2-preview', '.game3-video'],
            gameDetailOne: "<p>"+
                                "THQ was so excited about the work<br/>"+ 
                                "we were doing for Saints Row that<br/>"+
                                "they asked us to redesign their<br/>" + 
                                "company website, which housed all<br/>"+
                                "their game titles. After we began that<br/>"+
                                "process, even more impressed, they<br/>"+
                                "asked us to design UI for one of their<br/>"+
                                "game titles, Metro 2033.<br/>"+
                            " </p>",
            gameDetailTwo:  "<p>BRANDING & CONCEPTING</p><br/>"+
                            "<p>CREATIVE DIRECTION</p><br/>"+
                            "<p>DIGITAL DESIGN & UI DESIGN</p><br/>"+
                            "<p>VIDEO GAME UI</p>"
        }),
         methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .thq-2-preview{
        width: 37vw;
        height: 28.8vw;
        top: 21%;
        left: 17%;
    }

    .thq-1-preview{
        width: 28vw;
        height: 27.7vw;
        top: 34%;
        left: 54%;
    }

    .game3-video{
        top: 60% !important;
        left: 68.5% !important;
        width: 15vw !important;
        height: 9.5vw !important;
    }
    .bubble-two-thq{
        left: 57%;
        top: 17.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-thq{
        left: 10%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .thq-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-thq {
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-thq{
            top: 21.5%;
            left: 54%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .thq-2-preview{
            width: 55.5vw;
            height: 43.2vw;
            top: 32%;
            left: 5%;
        }

        .thq-1-preview{
            width: 42vw;
            height: 41.55vw;
            top: 34%;
            left: 54%;
        }

        .game3-video{
            top: 60% !important;
            left: 68.5% !important;
            width: 22.5vw !important;
            height: 14.25vw !important;
        }
    }
</style>