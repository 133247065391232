<template>

    <div class="bio-container bio-container-2" ref="bioContainerTwo">
        <div class="bio2-background"/>
        <div class="bio2-circle-1-hide" ref="circleTopHide" />
        <div id="bio2-circle-1-non-change" class="bio2-circle-1" />
        <div class="bio2-circle-1" ref="circleTop" />
        
        <div class="bio2-circle-2-hide" ref="circleBottomHide" />
        <div id="bio2-circle-2-non-change" class="bio2-circle-2" />
        <div class="arrow-safe-distance arrow-safe-distance-right arrow-bio-2-2-right arrow-bio visible-landscape "  @click="next()" >
                <div class="arrow-container next-arrow arrow-btn" />
            </div>
        <div id="circleBottom-bio-2" class="bio2-circle-2" ref="circleBottom">
            <div class="arrow-safe-distance arrow-safe-distance-right arrow-bio-2-right arrow-bio visible-landscape "  
            @mouseover="hoverArrow('.arrow-bio-2-right')" @mouseleave="leaveArrow('.arrow-bio-2-right')"
            @click="next()">
                <div class="arrow-container next-arrow arrow-btn" />
            </div>
        </div>
        
        <img class="bio2-la" src="@/assets/bio/la.jpg">
        <img class="bio2-pen" src="@/assets/bio/pen.jpg">
        <div class="bio2-text">
            <div class="quote-symbol position-bio2" >“</div>
            <p class="bio2-header">I've been around.</p> 
            <p class="bio2-p visible-landscape">
                Through the dotcom boom and bust in San Francisco<br/>
                and Los Angeles and back - twice. I currently<br/>
                live outside of historic Philadelphia.<span>”</span>
            </p>
            <p class="bio2-p visible-portrait">
                Through the dotcom boom and bust in San <br/>Francisco
                and Los Angeles and back - twice. <br/>I currently
                live outside of historic <br/>Philadelphia.<span>”</span>
            </p>
        </div>
        <div class="dim-bio2"/>
        <div class="bio-name bio-name-2">
            <img :src="require('@/assets/chris.png')" />
        </div>
        <div class="close-btn close-bio-btn-2" @click="close()" @mouseover="closeHover()"  @mouseleave="closeLeave()"/> 
        
        <div class="arrow-safe-distance arrow-safe-distance-left arrow-bio-2-left arrow-bio visible-landscape"  
        @mouseover="hoverArrow('.arrow-bio-2-left')"  @mouseleave="leaveArrow('.arrow-bio-2-left')"
        @click="prev()">
            <div class="arrow-container prev-arrow arrow-btn" />
        </div>
        <!--<img class="example" src="@/assets/bio/bio2.jpg" />-->
        <!--<img class="example" src="@/assets/bio/bio2-portrait.jpg" />-->
    </div>
    
</template>

    
<script>
import gsap from 'gsap';
var elem0 = [];


export default {


    name: "BioTwo",
    components: {
    },
    data: () => ({
        initialCircleBottom : '',
        hideCircleBottom : '',
        initialCircleTop : '',
        hideCircleTop:'',
        initialNameStyle: undefined,
        direction: '',
        isAnimating: false,
        closing: false
    }),
    methods: {
        hoverArrow(arrow){
            gsap.to(arrow, {autoAlpha: 0.75})
        },
        leaveArrow(arrow){
            gsap.to(arrow, {autoAlpha: 0.5})
        },
        closeLeave(){
            if(!this.closing)
            gsap.to('.close-bio-btn-2', {autoAlpha: 0.5})
        },
        closeHover(){
            if(!this.closing)
            gsap.to('.close-bio-btn-2', {autoAlpha: 0.75})
        },
        close(){
            if(this.isAnimating) return
            this.closing = true
            var elem = window.getComputedStyle(document.querySelector("#home-chris-non-change"))
            var tl = gsap.timeline()
            tl.set('.dim-bio2', {autoAlpha:0.95})
                .set('.home-chris', {autoAlpha: 0})
                .to(this.$refs.circleTop, {clipPath: this.hideCircleTop, duration: 1.5})
                .to('#circleBottom-bio-2', {clipPath: this.hideCircleBottom}, '-=1.5')
                .to('.bio2-la', {scale: 0, duration: 1.5}, '-=1.5')
                .to('.bio2-pen', {scale: 0, duration: 1.5}, '-=1.5')
                .to('.bio2-text', {autoAlpha: 0, duration: 1.5}, '-=1.5')
                
                .to('.bio2-background', {autoAlpha: 0, duration: 1.5}, '-=1.5')
                .to('.dim-bio2', {autoAlpha:0, duration: 1.5}, '-=1.5')
                .to('.close-bio-btn-2', {autoAlpha:0, duration: 1.5}, '-=1.5')
                .fromTo('.bio-name-2', 
                    {
                        top: Math.round(elem0.top.replace('px', '')), 
                        left: Math.round(elem0.left.replace('px', '')),
                        width: Math.round(elem0.width.replace('px', '')),
                        height: Math.round(elem0.height.replace('px', '')),
                        
                    },
                    {  
                        top: Math.round(elem.top.replace('px', '')), 
                        left: Math.round(elem.left.replace('px', '')),
                        width: Math.round(elem.width.replace('px', '')),
                        height: Math.round(elem.height.replace('px', '')),
                        duration: 1, onComplete:()=>{
                            tl.set('.home-chris', {autoAlpha: 1})
                            tl.set('.bio-name-2', {autoAlpha: 0})
                        }
                    },
                    '-=1.5')
                .to('.arrow-bio-2-left', {autoAlpha: 0, duration: .75}, '-=1.5')
                .to('.arrow-bio-2-right', {autoAlpha: 0, duration: .75}, '-=1.5')
                    
                .set('.bio-container-2', {autoAlpha: 0})
                .set('.popup-container', {autoAlpha: 0})
                .set('.bio-slider-container', {autoAlpha:0, display:'none'})
                
                .set('.top-circle-hover-zone', {autoAlpha: 1})
                .set('.bottom-circle-hover-zone', {autoAlpha: 1})
                .set('.home-arrow', {display: 'block'})
                .set('.home-bullets', {display: 'block', onComplete:()=>{
                    this.closing = false
                    this.$emit('close')
                    gsap.set('.arrow-bio-2-left', {autoAlpha: 0.5})
                    gsap.set('.arrow-bio-2-right', {autoAlpha: 0.5})
                }})
                //tl.pause(0.95)
        },
        
        toBio(index, a, onComplete){
            a
            if(index == 0)
                this.toBioOne(onComplete)
            if(index == 2)
                this.toBioThree(onComplete)
        },
        toBioOne(onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            tl.set('.bio2-la', {zIndex: 2})
            tl.set('.close-bio-btn-2', { autoAlpha: 0})
            
                .set('.bio2-pen', {zIndex: 2})
                .set('#circleBottom-bio-2', {zIndex: 2})
                .to('#circleBottom-bio-2', {clipPath: this.hideCircleBottom, duration: time})
                .to('.dim-bio2', {autoAlpha:0.95, duration:time}, '-='+time)
                .to('.bio2-la', {scale: 0, duration: 0.3}, '-='+time)
                .to('.bio2-pen', {scale: 0, duration: 0.3}, '-='+time)
                .set('.bio-container-2', {autoAlpha: 0})
                .set('.bio2-la', {zIndex: 0})
                .set('.bio2-pen', {zIndex: 0, onComplete:()=>{
                    
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
        },
        toBioThree(onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            
            tl.set('.bio2-la', {zIndex: 2})
                //.set('.close-bio-btn-2', { autoAlpha: 0})
                .set('#circleBottom-bio-2', {autoAlpha: 0})
                .set('.bio2-pen', {zIndex: 2})
                
                .to('.dim-bio2', {autoAlpha:0.95, duration:time})
                .to(this.$refs.circleTop, {clipPath: this.hideCircleTop, duration: time}, '-='+time)
                .to('.bio2-la', {scale: 0, duration:0.35} ,'-='+time)
                .to('.bio2-pen', {scale: 0, duration:0.35}, '-='+time)
                .set('.bio-container-2', {autoAlpha: 0, onComplete:()=>{
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
            


        },
        fromBio(index, consistency, onFinish){
            consistency
            if(index==0)
                this.fromBioOne(onFinish)
            if(index==2)
                this.fromBioThree(onFinish)
        },
        fromBioThree(onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            tl.set('.bio-container-2', {autoAlpha: 1})
                .set(this.$refs.circleTop, {zIndex: 2})
                .set('#circleBottom-bio-2', {zIndex: 2, autoAlpha: 0})
                .set('.close-bio-btn-2', { autoAlpha: 0.5})
                .set('.bio-name-2', {
                    autoAlpha: 1,
                    top: Math.round(elem0.top.replace('px', '')), 
                    left: Math.round(elem0.left.replace('px', '')),
                    width: Math.round(elem0.width.replace('px', '')),
                    height: Math.round(elem0.height.replace('px', '')),
                })
                .set('.bio2-la', {zIndex: 2, scale: 0})
                .set('.bio2-pen', {zIndex: 2, scale: 0})
                .set('.bio-background', { backgroundColor: 'unset'})
                .set('.bio2-background', {autoAlpha: 1})
                
                .set('.close-bio-btn-2', {autoAlpha:0.5})
                .set('.bio2-text', {autoAlpha: 1})
                .set('.dim-bio2', {autoAlpha:0.95})
                
                .fromTo(this.$refs.circleTop, {clipPath: this.hideCircleTop}, {clipPath: this.initialCircleTop, duration: time})
                .to('.bio2-pen', {scale: 1, duration: time}, '-='+time)
                .to('.bio2-la', {scale: 1, duration: time}, '-='+time)
                .to('.dim-bio2', {autoAlpha:0, duration: time}, '-='+time)
                
                .set('#circleBottom-bio-2', {autoAlpha: 1, clipPath: this.initialCircleBottom, onComplete:()=>{
                    this.isAnimating = false
                    onFinish()
                    
                }})
                
                
                
        },
        fromBioOne(onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            tl.set('.bio-container-2', {autoAlpha: 1})
                .set(this.$refs.circleTop, {clipPath: this.initialCircleTop})
                .set('#circleBottom-bio-2', {autoAlpha: 1, zIndex: 2})
                .set('.close-bio-btn-2', {autoAlpha:0.5})
                .set('.bio2-la', {zIndex: 2, scale: 0})
                .set('.bio2-pen', {zIndex: 2, scale: 0})
                .set(this.$refs.circleTop, {zIndex: 2})
                .set('.bio-background', { backgroundColor: 'unset'})
                .set('.bio2-background', {autoAlpha: 1})
                .set('.bio-name-2', {
                    autoAlpha: 1,
                    top: Math.round(elem0.top.replace('px', '')), 
                    left: Math.round(elem0.left.replace('px', '')),
                    width: Math.round(elem0.width.replace('px', '')),
                    height: Math.round(elem0.height.replace('px', '')),
                })
                
                
                
                
                .set('.bio2-text', {autoAlpha: 1})
                
                .set('.dim-bio2', {autoAlpha: 0.95})


                
                .fromTo('#circleBottom-bio-2', {clipPath: this.hideCircleBottom}, {clipPath: this.initialCircleBottom, duration: time})
                .to('.bio2-la',  {scale: 1, duration: time}, '-='+time)
                .to('.bio2-pen',  {scale: 1, duration: time}, '-='+time)
                .to('.dim-bio2', {autoAlpha:0, duration: time}, '-='+time)
                .set('.close-bio-btn-2', {autoAlpha:0.5}, '-=0.3')
                
                
                .set('.bio2-la', {zIndex: 0})
                .set('.bio2-pen', {zIndex: 0})
                .set('#circleBottom-bio-2', {zIndex: 0})
                
                .set(this.$refs.circleTop, {zIndex: 0, onComplete:()=>{
                    this.isAnimating = false
                    onFinish()
                }})
        },
        updateScreen(){
            var circle =document.getElementById('bio2-circle-1-non-change')
            var circle0 =document.getElementById('bio2-circle-2-non-change')
            var tempDir =''
            
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none'){
                tempDir = 'portrait'
                circle.classList.remove('bio2-circle-1-landscape')
                circle.classList.add('bio2-circle-1-portrait')
                circle0.classList.remove('bio2-circle-2-landscape')
                circle0.classList.add('bio2-circle-2-portrait')
                gsap.set('.close-bio-btn-2', {autoAlpha: 1})
            }else{
                tempDir = 'landscape'
                circle.classList.add('bio2-circle-1-landscape')
                circle.classList.remove('bio2-circle-1-portrait')
                circle0.classList.add('bio2-circle-2-landscape')
                circle0.classList.remove('bio2-circle-2-portrait')
                gsap.set('.close-bio-btn-2', {autoAlpha: 0.5})
            }

            if(circle.style.clipPath){
                circle = circle.style
                circle0 = circle0.style
            }else{
                circle = window.getComputedStyle(document.querySelector('#bio2-circle-1-non-change'))
                circle0 = window.getComputedStyle(document.querySelector('#bio2-circle-2-non-change'))
                
            }

            if(tempDir && tempDir!=this.direction){
               gsap.set(this.$refs.circleTop,{clipPath: circle.clipPath})
               gsap.set('#circleBottom-bio-2',{clipPath: circle0.clipPath})
            }

            this.initialCircleTop = circle.clipPath
            this.initialCircleBottom = circle0.clipPath

            elem0 = window.getComputedStyle(document.querySelector('#bio-name-non-change'))
            
            gsap.set('.bio-name-2',  {  
                top: Math.round(elem0.top.replace('px', '')), 
                left: Math.round(elem0.left.replace('px', '')),
                width: Math.round(elem0.width.replace('px', '')),
                height: Math.round(elem0.height.replace('px', ''))
            })
        },
        next(){
            this.$emit('next')
        },
        prev(){
            this.$emit('prev')
        },
    },
    computed: {
        

    },
    mounted() {
        gsap.set('.bio-container-2', {autoAlpha: 0}) 
        this.initialCircleBottom = window.getComputedStyle(this.$refs.circleBottom).clipPath
        this.initialCircleTop = window.getComputedStyle(this.$refs.circleTop).clipPath

        this.hideCircleBottom = window.getComputedStyle(this.$refs.circleBottomHide).clipPath
        this.hideCircleTop = window.getComputedStyle(this.$refs.circleTopHide).clipPath
        this.updateScreen()
        gsap.set('.arrow-bio-2-left', {'--color' : '#ed1b24'})
        gsap.set('.arrow-bio-2-right', {'--color' : '#000'})
    },
    created(){
        window.addEventListener("resize", this.updateScreen);
    },
}
</script>

<style>
.arrow-bio-2-2-right::after,
.arrow-bio-2-2-right::before{
    background-color: yellow;
}

#bio2-circle-2-non-change, #bio2-circle-1-non-change{
    background-color: blue;
    visibility: hidden;
}
.bio2-background{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.close-bio-btn-2, .close-bio-btn-3{
    top: 7%;
    right: 6%;
    opacity: 0.5;
}

.close-bio-btn-2:hover{
      opacity: 0.75;
  }
.close-bio-btn-2::after,
.close-bio-btn-2::before{
    background-color: #ed1b24;
        
}

.position-bio2{
    top: 10% !important;
    left: -10.5% !important;
}
    .bio2-pen{
        position: absolute;
        width: 19vw;
        height: 19vw;
        top: 53%;
        left: 63%;
        border-radius: 99%;
    }
    .bio2-la{
        position: absolute;
        width: 10vw;
        height: 10vw;
        top: 13%;
        left: 7%;
        border-radius: 99%;
    }
    
    .bio2-chris{
        position: absolute;
        left: 62%;
        top: 54%;
        width: 18vw;
    }
    .bio2-header{
        font-size: 4.55rem;
    }
    
    .bio2-text{
        font-family: 'quoteFont';
        color: white;
        position: absolute;
        top: 27%;
        left: 26.5%;
    }
    .bio2-p{
        font-family: 'chrisFont';
        font-size: 1.42rem;
        margin-top: -4rem;
        line-height: 5.5vh;
    }


    
    .bio2-circle-1, .bio2-circle-1-landscape{
        position: absolute;
        background-color: #ed1b24;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(45% at -4% -36%);
    }   

    .bio2-circle-2, .bio2-circle-2-landscape{
        position: absolute;
        background-color: #ed1b24;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(51% at 108% 110%);
    }  

    .bio2-circle-2-hide{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(51% at 138% 140%);
        visibility: hidden;
    }

    .bio2-circle-1-hide{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(45% at -24% -56%);
        visibility: hidden;
    }
    .dim-bio2{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.7;
    }
    
@media (orientation: portrait) {
    .bio2-pen {
        width: 42vw;
        height: 42vw;
        top: 57%;
        left: 49%;
    }

    .bio2-la {
        width: 20vw;
        height: 20vw;
        top: 4%;
        left: 11%;
    }
    .bio2-circle-2, .bio2-circle-2-portrait{
        clip-path: circle(69% at 114% 117%);
    }

    .bio2-circle-1, .bio2-circle-1-portrait{
        clip-path: circle(55% at -29% -24%);
    }

    .bio2-text {
        left: 6.5%;
        top: 25%;
    }

    .bio2-header {
        font-size: 9.9rem;
        padding-left: 9rem;
        line-height: 11rem;
        
    }
    .bio2-p {
        font-size: 3.57rem;
        margin-top: -6rem;
        line-height: 4vh;
        padding-left: 9rem;
    }

    .position-bio2 {
        top: 9% !important;
        left: 0 !important;
        font-size: 17rem !important;
    }
}
</style>