<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import WawaOneVue from './wawa/WawaOne.vue'
import WawaThreeVue from './wawa/WawaThree.vue'
import WawaTwoVue from './wawa/WawaTwo.vue'

export default { 
    name: "wawaSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'wawa-slider-container',
        classOverlay: 'wawa-crop-overlay',
        classSlider: 'wawa-background',
        classCloseBtn: 'close-btn-wawa',
        logos:[
          {
            path:require('@/assets/wawa/logo.svg'),
            class: 'wawa-logo'
          }
        ],
        slides:[<WawaOneVue/>, <WawaTwoVue/>, <WawaThreeVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .wawa-background{
    background-image: url('~@/assets/wawa/background.jpg');

  }
  .wawa-logo{
    width: 10.5% !important;
  }

  @media (orientation: portrait) {
   .wawa-logo{
      height: 10vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
    } 
  }
  
</style>