<template>
    <div :class="this.classContainer" ref="container">
        <preview  ref="previewOne" 
            v-if="this.previewOneData"
            :class="this.previewOneData.class" 
            :img="this.previewOneData.img"
            :isBrik="this.previewOneData.isBrik" 
            :forward="this.previewOneData.forward"
            :upDown="this.previewOneData.upDown"
            @previewHover="this.showOverlay(0)" @previewLeave="this.hideOverlay()"/>

        <preview  ref="previewTwo" 
            v-if="this.previewTwoData"
            :class="this.previewTwoData.class" 
            :img="this.previewTwoData.img"
            :isBrik="this.previewTwoData.isBrik" 
            :forward="this.previewTwoData.forward"
            :upDown="this.previewTwoData.upDown"
            @previewHover="this.showOverlay(1)" @previewLeave="this.hideOverlay()"/>
        
        <preview  ref="previewThree" 
            v-if="this.previewThreeData"
            :class="this.previewThreeData.class" 
            :img="this.previewThreeData.img"
            :isBrik="this.previewThreeData.isBrik" 
            :forward="this.previewThreeData.forward"
            :upDown="this.previewThreeData.upDown"
            @previewHover="this.showOverlay(2)" @previewLeave="this.hideOverlay()"/>

        <video-container ref="videoOne"
            v-if="this.videoOneData"
            :class="'video-container ' + this.videoOneData.class" 
            :isUnitedCenter="this.videoOneData.isUnitedCenter" 
            :videoUrl='this.videoOneData.videoUrl'
            :poster="this.videoOneData.poster"
            :img='this.videoOneData.img'
            :onAxisY='this.videoOneData.onAxisY'
            @mouseover="hover()" @mouseleave="leave()" @videoClose="this.hideOverlay(0)"
            @videoHover="this.showOverlay()" @videoLeave="this.hideOverlay(0)"/>

        <video-container ref="videoTwo"
            v-if="this.videoTwoData"
            :class="'video-container ' + this.videoTwoData.class" 
            :videoUrl='this.videoTwoData.videoUrl'
            :poster="this.videoTwoData.poster"
            :onAxisY='this.videoTwoData.onAxisY'
            @videoHover="this.showOverlay()" @videoLeave="this.hideOverlay(1)"/>

        <video-container ref="videoThree"
            v-if="this.videoThreeData"
            :class="'video-container ' + this.videoThreeData.class" 
            :videoUrl='this.videoThreeData.videoUrl'
            :poster="this.videoThreeData.poster"
            :onAxisY='this.videoThreeData.onAxisY'
            @videoHover="this.showOverlay()" @videoLeave="this.hideOverlay(2)"/>

        <preview-dick ref="previewSliderOne" 
            v-if="this.previewSliderOneData"
            :class="this.previewSliderOneData.class"
            :images="this.previewSliderOneData.images"
            @videoHover="this.showOverlay()" @videoLeave="this.hideOverlay()"/>

        <preview-dick ref="previewSliderTwo" 
            v-if="this.previewSliderTwoData"
            :class="this.previewSliderTwoData.class"
            :images="this.previewSliderTwoData.images"
            @videoHover="this.showOverlay()" @videoLeave="this.hideOverlay()"/>

        <bubble-one ref="bubbleOne"
            v-if="this.bubbleOneData" :class="'bubble-one-container ' + this.bubbleOneData.class"
            :textSlice="this.bubbleOneData.textSlice"
            :text="this.bubbleOneData.text" :title="this.bubbleOneData.title" 
            :fontSizeInnerText="this.bubbleOneData.fontSizeInnerText" :marginLeft="this.bubbleOneData.marginLeft"
            :marginTop="this.bubbleOneData.marginTop" :color="this.bubbleOneData.color" 
            :colorOverlay="this.bubbleOneData.colorOverlay" :colorText="this.bubbleOneData.colorText"
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"/>
        
        <bubble-two ref="bubbleTwo"
            v-if="this.bubbleTwoData" :class="'bubble-two-container ' + this.bubbleTwoData.class"
            :color='this.bubbleTwoData.color'
            :text="this.bubbleTwoData.text"
            :colorOverlay='this.bubbleTwoData. colorOverlay'
            :colorText='this.bubbleTwoData.colorText' 
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"/>

        

        <div :class="'overlay-project ' +this.classOverlay" ref="overlay" @click="closeMainVideo()"/>
    </div>
</template>

<script>
    import BubbleOne from './BubbleOne.vue'
    import BubbleTwo from './BubbleTwo.vue'
    import VideoContainer from './VideoContainer.vue'
    import gsap from 'gsap'
    import Preview from './Preview.vue'
    import PreviewDick from './projects/dick/PreviewDick.vue'
    

    export default {
        components: { BubbleOne, BubbleTwo, VideoContainer, Preview, PreviewDick}, 
        name: "project",
        props:{
            classOverlay:{type: String, default: ''},
            classContainer: {type: String, default: ''},
            bubbleOneData: {type: Object, default: undefined},
            bubbleTwoData: {type: Object, default: undefined},
            previewOneData: {type: Object, default: undefined},
            previewTwoData: {type: Object, default: undefined},
            previewThreeData: {type: Object, default: undefined},
            videoOneData: {type: Object, default: undefined},
            videoTwoData: {type: Object, default: undefined},
            videoThreeData: {type: Object, default: undefined},
            previewSliderOneData: {type: Object, default: undefined},
            previewSliderTwoData: {type: Object, default: undefined},
            ghostVideo: {type: Boolean, default: false},
            vimeoContainer: {type: Object, default: undefined}
            
        }, 
        data: () => ({
            animationGhostVideo: undefined,
            isHovering: false
        }),
        methods:{
            isPortrait(){
                 var elem = document.getElementById("landscape")
                if(window.getComputedStyle(elem).display=='none')
                    return true
                return false
            },
           
            showOverlay(index){
                this.pause(index)
                gsap.to(this.$refs.overlay, {autoAlpha: 0.7})
                gsap.to('.project-bullets', {autoAlpha: 0.1}) 
                
                if(this.isPortrait()){
                    gsap.to('.h1-close-btn-2', {autoAlpha: 0}) 
                    gsap.to('.h1-close-btn-0', {autoAlpha: 1}) 
                }
                    
                    

                this.isHovering = true
            },
            hideOverlay(videoIndex){
                this.play()
                gsap.to(this.$refs.overlay, {autoAlpha: 0})
                gsap.to('.project-bullets', {autoAlpha: 1}) 
                if(this.isPortrait()){
                    gsap.to('.h1-close-btn-2', {autoAlpha: 1}) 
                    gsap.to('.h1-close-btn-0', {autoAlpha: 0}) 
                }
                
                if(this.videoOneData && videoIndex==0) this.$refs.videoOne.manualReset()
                if(this.videoTwoData && videoIndex==1) this.$refs.videoTwo.manualReset()
                if(this.videoThreeData && videoIndex==2) this.$refs.videoThree.manualReset()
                this.isHovering = false
            },
            hover(){
                if(!this.ghostVideo) return
                if(this.animationGhostVideo){
                    this.animationGhostVideo.resume()
                }else{
                    var t = gsap.timeline()
                    t.fromTo('.'+this.videoTwoData.class, {autoAlpha: 1}, {autoAlpha: 0.25, duration: 0.5})
                    t.fromTo('.'+this.videoThreeData.class, {autoAlpha: 1}, {autoAlpha: 0.25, duration: 0.5}, '-=0.5')
                    this.animationGhostVideo = t
                }
            },
            leave(){
                if(!this.ghostVideo) return
                if(!this.animationGhostVideo.reversed()) this.animationGhostVideo.reverse()
            },
            pause(index){
                if(this.previewSliderOneData) this.$refs.previewSliderOne.pauseAnimations()
                if(this.previewSliderTwoData) this.$refs.previewSliderTwo.pauseAnimations()

                if(this.previewOneData && index!=0) this.$refs.previewOne.fullPause()
                else if(this.previewOneData && index==0) this.$refs.previewOne.displacementPause()
                
                if(this.previewTwoData && index!=1) this.$refs.previewTwo.fullPause()
                else if(this.previewTwoData && index==1) this.$refs.previewTwo.displacementPause()
                
                if(this.previewThreeData && index!=2) this.$refs.previewThree.fullPause()
                else if(this.previewThreeData && index==2) this.$refs.previewThree.displacementPause()

                if(this.videoOneData) this.$refs.videoOne.pauseAnimations()
                if(this.videoTwoData) this.$refs.videoTwo.pauseAnimations()
                if(this.videoThreeData) this.$refs.videoThree.pauseAnimations()

                if(this.bubbleOneData) this.$refs.bubbleOne.pauseAnimations()
                if(this.bubbleTwoData) this.$refs.bubbleTwo.pauseAnimations()
            },
            play(){
                //var delay = (fromHover?0:1)
                //console.log('fromHover: ' + fromHover + ' delay: ' + delay)
                if(this.previewSliderOneData)  this.$refs.previewSliderOne.resumeAnimations()
                if(this.previewSliderTwoData) this.$refs.previewSliderTwo.resumeAnimations()
                
                if(this.previewOneData) this.$refs.previewOne.resumeAnimations()
                if(this.previewTwoData) this.$refs.previewTwo.resumeAnimations()
                if(this.previewThreeData) this.$refs.previewThree.resumeAnimations()

                if(this.videoOneData) this.$refs.videoOne.resumeAnimations()
                if(this.videoTwoData) this.$refs.videoTwo.resumeAnimations()
                if(this.videoThreeData) this.$refs.videoThree.resumeAnimations()

                if(this.bubbleOneData) this.$refs.bubbleOne.resumeAnimations()
                if(this.bubbleTwoData) this.$refs.bubbleTwo.resumeAnimations()
            },

            openNonAnimated(){
                
                gsap.set(this.$refs.container, {display: 'block'})
                
                if(this.videoOneData) this.$refs.videoOne.animate()
                if(this.videoTwoData) this.$refs.videoTwo.animate()
                if(this.videoThreeData) this.$refs.videoThree.animate()

                if(this.bubbleOneData) this.$refs.bubbleOne.resumeAnimations()
                if(this.bubbleTwoData) this.$refs.bubbleTwo.resumeAnimations()
                
                if(this.previewSliderOneData) this.$refs.previewSliderOne.animate()
                if(this.previewSliderTwoData) this.$refs.previewSliderTwo.animate()

                if(this.previewOneData) this.$refs.previewOne.animate()
                if(this.previewTwoData) this.$refs.previewTwo.animate()
                if(this.previewThreeData) this.$refs.previewThree.animate()
            },

            open(onOpened){
                let time = 0.85
                var tl = gsap.timeline().delay(0.35)
                
                tl.set(this.$refs.container, {display: 'block'})
                tl.fromTo(this.$refs.container, {autoAlpha: 0}, {autoAlpha: 1, duration:time, onComplete:()=>{
                    this.openNonAnimated()
                    if(onOpened!==undefined)
                        onOpened()
                }})
                
                if(this.previewOneData) this.$refs.previewOne.restart()
                if(this.previewTwoData) this.$refs.previewTwo.restart()
                if(this.previewThreeData) this.$refs.previewThree.restart()
                
                if(this.previewSliderOneData) tl.fromTo('.'+this.previewSliderOneData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                if(this.previewSliderTwoData) tl.fromTo('.'+this.previewSliderTwoData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)

                if(this.bubbleOneData) tl.fromTo('.'+this.bubbleOneData.class, {scale: 0.06}, {scale:0.4, duration: time}, '-='+time)
                if(this.bubbleTwoData) tl.fromTo('.'+this.bubbleTwoData.class, {scale: 0.02}, {scale:0.31, duration: time}, '-='+time)

                if(this.videoOneData) tl.fromTo('.'+this.videoOneData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                if(this.videoTwoData) tl.fromTo('.'+this.videoTwoData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                if(this.videoThreeData) tl.fromTo('.'+this.videoThreeData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                
                if(this.previewOneData) tl.fromTo('.'+this.previewOneData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                if(this.previewTwoData) tl.fromTo('.'+this.previewTwoData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
                if(this.previewThreeData) tl.fromTo('.'+this.previewThreeData.class, {scale: 0.5}, {scale: 1, duration: time}, '-='+time)
            },
            close(){
                this.pause()
                var tl = gsap.timeline()
                if(this.bubbleOneData) tl.to('.'+this.bubbleOneData.class, {scale: 0.25, duration: 0.5})
                if(this.bubbleTwoData) tl.to('.'+this.bubbleTwoData.class, {scale: 0.25, duration: 0.5}, '-=0.5')

                if(this.videoOneData) tl.fromTo('.'+this.videoOneData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                if(this.videoTwoData) tl.fromTo('.'+this.videoTwoData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                if(this.videoThreeData) tl.fromTo('.'+this.videoThreeData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                
                if(this.previewSliderOneData) tl.fromTo('.'+this.previewSliderOneData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                if(this.previewSliderTwoData) tl.fromTo('.'+this.previewSliderTwoData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')

                if(this.previewOneData) tl.fromTo('.'+this.previewOneData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                if(this.previewTwoData) tl.fromTo('.'+this.previewTwoData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')
                if(this.previewThreeData) tl.fromTo('.'+this.previewThreeData.class, {scale: 1}, {scale: 0.75, duration: 0.5}, '-=0.5')

                tl.to(this.$refs.container, {autoAlpha: 0.25, duration: 0.5, onComplete:()=>{
                    gsap.set(this.$refs.container,{display: 'none'})
                    tl.pause(0)
                    tl.kill()
                    
                }}, '-=0.5')
                
            },
            closeMainVideo(){
                this.$refs.videoOne.closeVideo()
            }
            
            
        },
        mounted(){         
            gsap.set(this.$refs.overlay, {autoAlpha: 0}) 
        }

    
    }
</script>

<style>

</style>