<template>
    <div class="dick-preview-container" @mouseover="hover()" @mouseleave="leave()" ref="container">
       
        <img v-for="(img, index) in this.images" :key="img" :ref="'img' + index" :src="require('@/'+img)" />
    </div>    
</template>

<script>
import gsap from 'gsap'

export default {

    
    
    props:{
        images: Array,
    },
    data: () => ({
        active: 0,
        animationH: undefined,
        imgShorcut: [],
        interval: undefined,
        hDirection: 1
    }),
    mounted(){
        gsap.set(this.$refs.img1, {autoAlpha: 0})
        gsap.set(this.$refs.img2, {autoAlpha: 0})
        this.imgShorcut = [this.$refs.img0, this.$refs.img1, this.$refs.img2]
        this.pulse()
        this.pauseAnimations()
    },
    methods:{
        hover(){
            gsap.set(this.$refs.container, {zIndex:1})
            this.pauseAnimations()
            this.$emit('videoHover')
            if(this.interval==undefined)
                this.interval = setInterval(()=>{ 
                    this.changeImage()
                }, 1500);

            
        }, 
        leave(){
            gsap.set(this.$refs.container, {zIndex:0})
            this.resumeAnimations()
            clearInterval(this.interval)
            this.interval = undefined
            this.$emit('videoLeave')
        },
        changeImage(){
            var next = this.active +1
            if(next >= this.imgShorcut.length) next = 0
            
            var tl = gsap.timeline()
            tl  .to(this.imgShorcut[this.active], {autoAlpha: 0, duration: 1})
                .to(this.imgShorcut[next], {autoAlpha: 1, duration: 1, onComplete:()=>{
                    this.active = next
                }}, '-=1')
        },
        animate(){
            if(this.animationH!==undefined)
                this.animationH.play()
                    
        },
        pauseAnimations(){
            if(this.animationH!==undefined)
                this.animationH.pause()
        },
        resumeAnimations(){
            if(this.animationH!==undefined)
                this.animationH.resume()
        },
        random(min, max) {
            var delta = max - min;
            var r = (min + delta * Math.random());
            
            return r
        },
        moveX( ) {
            this.hDirection *= -1
            var distance = this.random(1, 25) * this.hDirection
            var dur = this.random(3, 5)
            this.animationH = gsap.to(this.$refs.container, {x: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                this.moveX()
            }})
        },
        pulse(){
            this.moveX()
        },
        
    }

}
</script>

<style>
.dick-preview-container img{
    position: absolute;
    height: 100%;
    width: 100%;
}
.dick-preview-container{
    position: absolute;
    border: 5px solid white;
    border-radius: 7px;
}
</style>