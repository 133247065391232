<template>

    <div class="hello-container">
        <!--<img class="example-home" src="@/assets/bio/hello.jpg" />-->
        <div class="overlay-sayhello" />
        <div id="hello-circle-non-change" class="hello-circle" />
        
        <div id="hello-circle" class="hello-circle" ref="circleOne">
            <div class="close-btn close-btn-hello close-btn-hello-black" @click="close()" @mouseover="closeHover()" @mouseleave="closeLeave()"/>  
            <div class="hello-name"> <img :src="require('@/assets/chris-black.png')" /> </div>
            <div class="hello-text">
                
                <div class="quote-symbol position-hello" >“</div>
                <p class="hello-header">Let's make something<br/> amazing.<span>”</span></p> 
            </div>
            <div class="hello-details-container">
                <div class="hello-detail">
                    <div class="hello-number">1</div>
                    <div class="hello-p hello-p-1">
                        <div class="hello-title">Email me</div>
                        <div class="hello-textd"> cottinger2002@yahoo.com </div>
                    </div>
                </div>

                <div class="hello-detail">
                    <div class="hello-number">2</div>
                    <div class="hello-p hello-p-2">
                        <div class="hello-title">Check me out on LinkedIN</div>
                        <div class="hello-textd"> 
                            <a href="https://linkedin.com/in/chrisottinger" target="_blank" >
                                linkedin.com/in/chrisottinger 
                            </a>
                        </div>
                    </div>
                </div>

                <div class="hello-detail visible-landscape">
                    <div class="hello-number">3</div>
                    <div class="hello-p  hello-p-3">
                        <div class="hello-title">Call or text me</div>
                        <div class="hello-textd"> 267.625.5312</div>
                    </div>
                </div>
            </div>
            <a href="tel:2676255312" class="visible-portrait hello-call">
                <div class="hello-number">3</div> <div class="number-mobile">CALL ME: 267-625-5312</div>
            </a>
            <div class="say-hello-btn-container say-hello-btn-inside"> 
                <img :src="require('@/assets/hello-black.png')" />
            </div>
        </div>
        
        <div  class="hello-black-circle"> <img src="@/assets/bio/SAY-HELLO_1B.jpg"> </div>
        
        <div  class="hello-black-circle hello-black-circle-fade"> <img src="@/assets/bio/say-hello-chris.jpg"/> </div>
        <div class="hello-black-circle"  @mouseover="this.fadeOutImage()" @mouseleave="this.fadeInImage()"/>

        
        <div class="close-btn close-btn-hello" @click="close()" @mouseover="closeHover()" @mouseleave="closeLeave()"/>  
        <!--<img class="example" src="@/assets/bio/hello.jpg" />-->
        <!--<img class="example" src="@/assets/bio/sayhello-portrait.jpg" />-->
        <!-- <img class="example" src="@/assets/bio/hello-mobile.jpg" /> -->
        
    </div>
    
</template>

    
<script>

import gsap from "gsap";

export default {

    name: "helloOne",
    components: {
    },
    data: () => ({
        initialCircle: '',
        initialTop: '',
        initialHeight: '',
        openAnimation: undefined,
        direction: '',
        isAnimating : false,
        closing: false
    }),
    methods: {
        fadeInImage(){
            gsap.to('.hello-black-circle-fade', {autoAlpha: 1})
        },
        fadeOutImage(){
            gsap.to('.hello-black-circle-fade', {autoAlpha: 0})
        },
        createAnimation(){
            
        },
        closeHover(){
            if(!this.closing)
            gsap.to('.close-btn-hello', {opacity: 0.75})
        },
        closeLeave(){
            if(!this.closing)
            gsap.to('.close-btn-hello', {opacity: 0.5})
        },
        open(initCircle){
            this.isAnimating = true
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none')
                gsap.set('.close-btn-hello', {autoAlpha: 1})
            else gsap.set('.close-btn-hello', {autoAlpha: 0.5})

            var tl = gsap.timeline()
            tl.set('.bio-slider-container', {autoAlpha: 0})
                //.set('.hello-circle', {clipPath: initCircle, autoAlpha: 0})
                .set('.home-arrow', {display: 'none'})
                .set('.home-bullets', {display: 'none'})
                .set('.popup-container', {autoAlpha: 1})
                .set('.hello-black-circle', {scale: 0, zIndex: 3})
                .set('.hello-container', {autoAlpha: 1})
                .set('.top-circle-hover-zone', {autoAlpha: 0})
                .set('.bottom-circle-hover-zone', {autoAlpha: 0})
                .set('.say-hello-btn-inside', {autoAlpha:1})
                .set(this.$refs.circleOne, {autoAlpha: 1})
                .fromTo(this.$refs.circleOne,{clipPath: initCircle}, {clipPath: this.initialCircle, duration: 1.5} )
                .to('.overlay-sayhello', {autoAlpha: 1, duration: 1.5}, '-=1.5')
                
                .to('.hello-black-circle', {scale: 1, duration: 0.75, onComplete:()=>{
                    this.isAnimating = false
                }}, '-=1.15')
                .set('.say-hello-btn-inside', {autoAlpha:0}, '-=1.15')
                
            
            this.openAnimation = tl
            
        },
        close(){
            if(this.isAnimating) return
            this.closing= true
            var tl = gsap.timeline()
                tl.set('.say-hello-btn-inside', {autoAlpha:1})
                tl.to(this.$refs.circleOne, {clipPath: 'circle(62.5% at 150% 150%', duration: 1.5} )
                
                .to('.hello-black-circle', {scale: 0, duration: 0.5}, '-=1.5')
                .to('.overlay-sayhello', {autoAlpha: 0, duration: 1.5}, '-=1.5')
                .to('.close-btn-hello', {autoAlpha: 0, duration: 0.75}, '-=1.5')
                .set('.top-circle-hover-zone', {autoAlpha: 1})
                .set('.bottom-circle-hover-zone', {autoAlpha: 1})
                .set('.home-arrow', {display: 'block'})
                .set('.home-bullets', {display: 'block'})
                .set('.hello-container', {autoAlpha: 0})
                .set('.popup-container', {autoAlpha: 0, onComplete:()=>{
                    this.closing = false
                }})
            this.$emit('close')
        },
        updateScreen(){
            var tempDir =''
            var circle =document.getElementById('hello-circle-non-change')
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none'){
                tempDir = 'portrait'
                circle.classList.remove('hello-circle-landscape')
                circle.classList.add('hello-circle-portrait')
                gsap.set('.close-btn-hello', {autoAlpha: 1})
            }else{
                tempDir = 'landscape'
                circle.classList.add('hello-circle-landscape')
                circle.classList.remove('hello-circle-portrait')
                gsap.set('.close-btn-hello', {autoAlpha: 0.5})
            }

            
            if(circle.style.clipPath){
                circle = circle.style
            }else{
                circle = window.getComputedStyle(document.querySelector('#hello-circle-non-change'))
                
            }
                
            if(tempDir && tempDir!=this.direction){
                gsap.set(this.$refs.circleOne,{clipPath: circle.clipPath, top: circle.top, height: circle.height})
            }

            //var elem0 = window.getComputedStyle(document.querySelector('#bio-name-non-change'))
            

            
            
            this.initialCircle = circle.clipPath
            this.initialTop = circle.top
            this.initialHeight = circle.height
        }
            
           
    },
    computed: {
        

    },
    created(){
        window.addEventListener("resize", this.updateScreen);
    },
    mounted() {
        
        this.updateScreen()
        
    }
}
</script>

<style>
.hello-black-circle img{
    width: 104%;
    height: 104%;
    position: absolute;
    left: -2%;
    right:  -2%
}
.say-hello-btn-inside{
    bottom: 10.4% !important;
}

#hello-circle-non-change{
    visibility: hidden;
    background-color: blue;
}
.overlay-sayhello{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    visibility: hidden;
}

.position-hello{
    top: 12%;
    left: -9% !important;
}


.close-btn-hello{
    top: 7%;
    right: 6%;
    opacity: 0.5;
}

.close-btn-hello:hover{
    opacity: 0.5;
    
}
.close-btn-hello::after,
.close-btn-hello::before
{
    background-color: #ed1b24;
}

.hello-number{
    background-color: #000;
    color: #ed1b24;
    display: inline-block;
    width: 5.5vw;
    height: 5.5vw;
    text-indent: 0px;
    text-align: center;
    border-radius: 99%;
    line-height: 5.5vw;
    font-size: 3.8rem;
}

.number-mobile{
    font-family: 'chrisFont';
}

.hello-textd a{
    color: #fff;
    border-bottom: 1px solid #fff;
    text-decoration: none;
}
.hello-textd a:hover{
    border-bottom: 1px solid #000;
    color: #000;
}

.hello-textd{
    font-family: 'chrisFontLight';
    position: relative;
    top: 18%;
    font-size: 1.66rem;
    font-weight: lighter;
    padding-top: 0.5vh;
}
.hello-detail{
    padding-bottom: 1.6rem;
    height: 5.5vw;
    display: flex !important;
    align-items: center;
}

.hello-title{
    font-size: 1.35rem;
    font-weight: bolder;
    position: relative;
    padding-bottom: 0.5vh;
}
.hello-details-container{
    position: absolute;
    top: 61vh;
    left: 26%;
    color: white;
}

    
    .hello-footer{
        position: absolute;
        color: white;
        font-family: 'chrisFont';
        font-size: 0.7vw;
        left: 23%;
        top: 68%;
        text-transform: uppercase;

    }
    .hello-footer div{
        display: inline-block;
        margin-left: 4vw;
    }
    .hello-chris{
        position: absolute;
        left: 68%;
        top: 13%;
        width: 15vw;
    }
    .hello-header{
        line-height: 5.5rem;
        font-size: 4.55rem;
        
    }

    .hello-header span{
        font-weight: normal !important;
    }
    .hello-p{
        font-family: 'chrisFont';
        font-size: 1.5vw;
        display: inline-block;
        
        margin-left: 1.2rem;
        width: 60vw;
        

    }
    .hello-p-1{
        
    }

    .hello-p-2{
        
    }

    .hello-p-3{
        
    }

    .hello-text{
        font-family: 'quoteFont';
        color: white;
        position: absolute;
        top: 33vh;
        left: 26.5%;
    }

    .hello-name{
        position: absolute;
        font-family: 'chrisFont';
        top: 29.5vh;
        left: 23.2%;
        width: 16.2vw;
        height: 1.2vw;
    }
    .hello-name img{
        width: 100%;
        height: 100%;
    }

    .hello-container{
        position: absolute;
        width: 100%;
        height: 100%;
        

    }
    #hello-circle, .hello-circle, .hello-circle-landscape{
        position: absolute;
        background-color: #ed1b24;
        width: 100%;
        height: 115%;
        top: -10%;
        clip-path: circle(62.5% at 54% 82%);
    }    

    .hello-black-circle{
        width: 20vw;
        height: 20vw;
        position: absolute;
        top: 40%;
        left: 65%;
        border-radius: 99%;
        overflow: hidden;
    } 
    .visible-landscape{
        display: block;
    }

    .visible-portrait{
        display: none;
    }
@media (orientation: portrait) {
    #hello-circle, .hello-circle, .hello-circle-portrait{
        clip-path: circle(65.5% at 24% 60%);
    }

    .hello-black-circle {
        width: 40vw;
        height: 40vw;
    
        top: 32%;
        left: 61%;
    }

    .hello-name {
        top: 33.7vh;
        left: 6.5%;
        width: 43.5vw;
        height: 3.2vw;
        
    }

    .hello-text {
        top: 38vh;
        left: 6.5%;
        width: 55%;
    }

    .hello-header {
        line-height: 10rem;
        font-size: 9.5rem;
    
        padding-left: 7vw;
    }

    .position-hello {
        top: 9%;
        left: -1% !important;
        font-size: 17rem !important;
    }

    .hello-details-container {
    
        top: 63vh;
        left: 6.5%;
    }

    .hello-detail {
        height: 13.5vw;
        padding-bottom: 5.6rem;

    }
    .hello-p {
    
        margin-left: 4.2rem;
    }
    
    .hello-number {
        width: 13.5vw;
        height: 13.5vw;
        line-height: 13.5vw;
        font-size: 9.5rem;
        
        
    }
    .hello-title {
        font-size: 3.65rem;
    }
    .hello-textd {
        top: 18%;
        font-size: 4.45rem;
    }

    .visible-landscape{
        display: none !important;
    }
    .visible-portrait{
        display: block;
    }
    .hello-call{
        position: absolute;
        bottom: 10.5%;
        background-color: #000;
        height: 13.5vw;
        left: 7%;
        width: 86%;
        color: #fff;
        font-size: 6rem;
        
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .close-btn {
        width: 8vw !important;
        height: 8vw !important;
    }

    .close-btn::before, .close-btn::after {
        width: 115% !important;
    }
    .close-btn-hello{
        opacity: 1;
    }

    .close-btn-hello:hover{
        opacity: 1;
    }
    .say-hello-btn-inside {
      bottom: 7.8% !important;
    }
}

.close-btn-hello-black{
    top: 14% !important;
}
.close-btn-hello-black::before,
.close-btn-hello-black::after {
    background-color: #000;

}
</style>