<template>

    <div class="home-container" ref="homeContainer">
        
        <div class="detail-project" ref="popUp">
            
            <united-slider ref="projectOne" @sliderClose="this.showHomeElements"/>
            <vizio-slider ref="projectTwo" @sliderClose="this.showHomeElements"/>
            <brink-slider ref="projectThree" @sliderClose="this.showHomeElements"/>
            <!-- second round -->
            <financial-slider ref="projectFour"  @sliderClose="this.showHomeElements"/>
            <game-slider ref="projectFive" @sliderClose="this.showHomeElements"/>
            <wawa-slider ref="projectSix" @sliderClose="this.showHomeElements"/>

            <!-- third round -->
            <agency-slider ref="projectSeven" @sliderClose="this.showHomeElements" />
            <dick-slider ref="projectEight"  @sliderClose="this.showHomeElements"/>
            <replatform-slider ref="projectNine"  @sliderClose="this.showHomeElements"/>
            
            
            <div class="project-thumbnail project-thumbnail-left" ref="thumbnailLeft">
                <div class="project-thumbnail-img"> 
                    <img src="@/assets/brink/bubble.jpg" ref="thumbnailImgLeft"/>
                </div>
                
                <div class="thumbnail-text-left">
                    <span class="thumbnail-initial" ref="textBeginLeft">{{this.firstText}} </span> 
                    <span class="thumbnail-end" ref="textEndLeft">{{this.secondText}}</span>
                </div>
            </div>

            <div class="project-thumbnail" ref="thumbnail">
                <div class="project-thumbnail-img"> 
                    <img src="@/assets/brink/bubble.jpg" ref="thumbnailImg"/>
                </div>
                <div class="thumbnail-text">
                    <span class="thumbnail-initial" ref="textBegin">{{this.firstText}} </span> 
                    <span class="thumbnail-end" ref="textEnd">{{this.secondText}}</span>
                </div>
            </div>
            
            <div class="btn-project-container left" @click="prevProject()" ref="prevProjectBtn" 
                @mouseover="prevHover()" @mouseleave="prevLeave()">
                <div class="project-btn project-prev" ref="prevProjectBtnText"> <div>prev</div><div ref="textToHideLeft"><span></span></div></div>
            </div>
            <div class="btn-project-container right" @click="nextProject()" ref="nextProjectBtn" 
                @mouseover="nextHover()"  @mouseleave="nextLeave()">
                <div class="project-btn project-next" ref="nextProjectBtnText"> <div>next</div><div ref="textToHideRight">&zwnj; <span></span></div></div>
            </div>
        </div>

        <div class="home-circles-container" ref="circleContainer"  v-touch:swipe="changeTo">
            <div class="circles-container circles-container-1" ref="slideOne">
                <bubble-principal ref="bubbleOne" 
                    class="bubble-left-h1" color="#5c5b85" :direction="1"
                    text="Donation Video" 
                    img="assets/united/bubble.jpg" topViewText = "17%"
                    topView="20%" topProject="59%"  topTitle="33%" logoOneWidth="48%"
                    @bubbleHover="pauseHome(1, 2)" @bubbleLeave="playHome(1, 2)"
                    :afterClick="this.openProjectOne" :beforeClick="this.hideHomeElements"
                    :initialLogos="['assets/united/logo.svg']"/>

                <bubble-principal ref="bubbleThree"
                    class="bubble-right-h1" color="#1a8ca3" :direction="-1"
                    text="Data Viz"  :beforeClick="this.hideHomeElements"
                    @bubbleHover="pauseHome(0, 1)" @bubbleLeave="playHome(0, 1)"
                    img="assets/brink/bubble.jpg"  topViewText = "17%" 
                    topView="23%" topProject="64%"  topTitle="33%" logoOneWidth="58%"
                    :afterClick="this.openProjectThree" 
                    :initialLogos="['assets/brink/logo.png']"/>
                
                <bubble-principal  ref="bubbleTwo"
                    class="bubble-center-h1" color="#153f68" :delay="1"
                    text="Connected Devices"  :beforeClick="this.hideHomeElements"
                    @bubbleHover="pauseHome(0, 2)" @bubbleLeave="playHome(0, 2)"
                    img="assets/vizio/bubble.jpg"
                    topViewText = "24%" topView="26%"
                    topTitle="33%" topProject="56%" logoOneWidth="43%"
                    :afterClick="this.openProjectTwo"
                    :initialLogos="['assets/vizio/logo.svg']"/>
            </div>
            <div class="circles-container circles-container-2"  ref="slideTwo">
                <bubble-principal ref="bubbleFour"
                    class="bubble-left-h2" color="#415c6b" 
                    text="Financial"  :beforeClick="this.hideHomeElements" 
                    img="assets/hatford/bubble.jpg" topViewText = "17%"
                    topView="20%" topProject="70%"  topTitle="40%" 
                    logoOneWidth="46.8%" bottomSecondLine="52%"
                    logoTwoLeft="11%" logoTwoWidth="31.5%"
                    logoThreeLeft="58%" logoThreeWidth="29.7%"
                    @bubbleHover="pauseHome(4, 5)" @bubbleLeave="playHome(4, 5)"
                    :afterClick="this.openProjectFour" 
                    :initialLogos="['assets/hatford/logo2.png', 'assets/chase/logo.svg', 'assets/mtbank/logo.svg']"/>

                <bubble-principal ref="bubbleSix"
                    class="bubble-right-h2" color="#b0162e" 
                    text="Digital Retainer" :beforeClick="this.hideHomeElements"
                    @bubbleHover="pauseHome(3, 4)" @bubbleLeave="playHome(3, 4)"
                    img="assets/wawa/bubble.jpg" topViewText = "20%"
                    topView="25%" topProject="58%"  topTitle="33%" logoOneWidth= "42%"
                    :afterClick="this.openProjectSix"
                    :initialLogos="['assets/wawa/logo.svg']"/>
                
                <bubble-principal ref="bubbleFive"
                    class="bubble-center-h2" color="#020303" 
                    text="Video Game" :beforeClick="this.hideHomeElements" 
                    @bubbleHover="pauseHome(3, 5)" @bubbleLeave="playHome(3, 5)"
                    img="assets/wb/bubble.jpg" topViewText = "20%"
                    topView="14%" topProject="50%"  topTitle="30%"
                    :afterClick="this.openProjectFive" 
                    logoOneWidth="17%" bottomSecondLine="43%"
                    logoTwoWidth="23%" logoTwoLeft="19%"
                    logoThreeWidth="22%" logoThreeLeft="61%"
                    :initialLogos="['assets/wb/logo.svg', 'assets/thq/logo.svg', 'assets/sr3/logo.svg']"/>
            </div>

            <div class="circles-container circles-container-3" ref="slideThree">
                <bubble-principal ref="bubbleSeven"
                    class="bubble-left-h3" color="#824c72" 
                    text="Agency Branding" :beforeClick="this.hideHomeElements" 
                    img="assets/bedland/bubble.jpg" 
                    topView="15%" topProject="65%"  topTitle="38%"
                    logoOneWidth="53%" bottomSecondLine="45%" topViewText = "20%"
                    logoTwoLeft="11%"  logoTwoWidth="33%"
                    logoThreeWidth="40%" logoThreeLeft="52%"
                    @bubbleHover="pauseHome(7, 8)" @bubbleLeave="playHome(7, 8)"
                    :afterClick="this.openProjectSeven"
                    :initialLogos="['assets/bedland/logo2.png', 'assets/bonteous/logo.svg', 'assets/dustland/logo2.png']"/>

                <bubble-principal ref="bubbleNine"
                    class="bubble-right-h3" color="#4e5a5f" 
                    text="Replatform & Redesign" :beforeClick="this.hideHomeElements"
                    @bubbleHover="pauseHome(6, 7)" @bubbleLeave="playHome(6, 7)"
                    img="assets/vail/bubble.jpg" 
                    topViewText = "20%"
                    topView="22%" topProject="64%"  topTitle="39%" fontSize="171%" 
                    logoOneWidth="45.05%" bottomSecondLine="63%"
                    logoTwoWidth="28.05%" logoTwoLeft="13%"
                    logoThreeLeft="57%" logoThreeWidth="27.2%"
                    :afterClick="this.openProjectNine"
                    :initialLogos="['assets/vail/logo.png', 'assets/nfi/logo.svg', 'assets/tbn/logo.svg']"/>
                
                <bubble-principal ref="bubbleEight"
                    class="bubble-center-h3" color="#035c4e" 
                    text="Branding & Redesign" :beforeClick="this.hideHomeElements" 
                    @bubbleHover="pauseHome(6, 8)" @bubbleLeave="playHome(6, 8)"
                    img="assets/dick/bubble.jpg" 
                    bottomSecondLine="62%" logoOneWidth="41%"
                    topViewText = "23%"
                    topView="28%" topProject="47%"  topTitle="27%"
                    :afterClick="this.openProjectEight"
                    :initialLogos="['assets/dick/logo.svg',]"/>
            </div>
            <div class="home-overlay" />
            
            <div>

                <div id="home-chris-non-change">
                    <img :src="require('@/assets/chris.png')" />
                </div>
                <div class="home-chris">
                    <img :src="require('@/assets/chris.png')" />
                </div>

                    
                    <div class="home-top-red-circle-close" ref="topClose"/>
                    
                    <div class="home-top-red-circle" ref="topCircle">
                        <div class="home-chris home-chris2">
                            <img :src="require('@/assets/chris-black.png')" />
                        </div>
                    </div>

                    <div class="top-circle-hover-zone" @click="openBio()" @mouseover="hoverRedCircleTop()" @mouseleave="unhoverRedCircleTop()" />

                <div class="say-hello-btn-container"> 
                    <img :src="require('@/assets/hello.png')" />
                </div>

                <div class="home-bottom-red-circle-close" ref="bottomClose"/>
                <div class="home-bottom-red-circle" ref="bottomCircle">
                    <div class="say-hello-btn-container"> 
                        <img :src="require('@/assets/hello-black.png')" />
                    </div>
                </div>

                <div class="home-arrow arrow-safe-distance arrow-safe-distance-right home-arrow-right visible-landscape" ref="nextArrow" 
                        @click="changeTo('left')" @mouseover="showSliderOverlay(0)" @mouseleave="hideSliderOverlay(0)">
                    <div class="arrow-container next-arrow next-arrow-2 arrow-btn arrow-btn-slider" />
                </div>
                <div class="home-arrow arrow-safe-distance arrow-safe-distance-left home-arrow-left visible-landscape" ref="preArrow" 
                    @click="changeTo('right')" @mouseover="showSliderOverlay(1)" @mouseleave="hideSliderOverlay(1)">
                    <div class="arrow-container prev-arrow prev-arrow-2 arrow-btn " />
                </div>
                <div class="bullets home-bullets" ref="sliderBullets">
                    <div class="active" @click="changeCirclePageTo(0)" 
                                        @mouseover="showSliderOverlay()"
                                        @mouseleave="hideSliderOverlay()">&bull;</div>
                    <div @click="changeCirclePageTo(1)"   @mouseover="showSliderOverlay()"
                                            @mouseleave="hideSliderOverlay()">&bull;</div>
                    <div @click="changeCirclePageTo(2)"   @mouseover="showSliderOverlay()"
                                            @mouseleave="hideSliderOverlay()">&bull;</div>
                </div>




                <div class="bottom-circle-hover-zone" @mouseover="hoverRedCircleBottom()" @mouseleave="unhoverRedCircleBottom()" @click="openSayHello()" />
            </div>
        </div> 

        <div class="popup-container">
            <say-hello ref="sayHello" @close="this.restartIdleCount"/>
            <bio-slider ref="bioSlide" @close="this.restartIdleCount"/>
        </div>
        <div id="landscape" class="hack visible-landscape" />
        <!--<img class="example" src="@/assets/home1.jpg" />-->
        <!--<img class="example" src="@/assets/home1-portrait.jpg" />-->
    </div>

    
    
</template>

    
<script>

import gsap from 'gsap'
import VizioSlider from './projects/VizioSlider.vue'
import BrinkSlider from './projects/BrinkSlider.vue'
import UnitedSlider from './projects/UnitedSlider.vue'
import BubblePrincipal from './BubblePrincipal.vue'
import FinancialSlider from './projects/FinancialSlider.vue'
import GameSlider from './projects/GameSlider.vue'
import WawaSlider from './projects/WawaSlider.vue'
import ReplatformSlider from './projects/ReplatformSlider.vue'
import AgencySlider from './projects/AgencySlider.vue'
import DickSlider from './projects/DickSlider.vue'
import SayHello from './SayHello.vue'
import BioSlider from './bio/BioSlider.vue'


export default {


    name: "HomeOne",
    components: {
        VizioSlider,
        BrinkSlider,
        UnitedSlider,
        BubblePrincipal,
        FinancialSlider,
        GameSlider,
        WawaSlider,
        ReplatformSlider,
        AgencySlider,
        DickSlider,
        SayHello,
        BioSlider
    },
    data: () => ({
        isBubbleHover:false,
        isChangingPage: false,
        bubbles: [],
        projects: [],
        actualCirclePage: 0,
        projectsComponent:[],
        projectOpen: false,
        actualProject: '',
        changingProject: false,
        text: String,
        openingProject: false,
        transitionProject: false,
        transitionHoverProject: undefined,
        transitionInEffectGSAP: undefined,
        topHover: undefined,
        isHoverProject: false,
        showCircles: true,
        slides : ['.circles-container-1', '.circles-container-2', '.circles-container-3'],
        aesthetic: {
            0:{
                'text' : '', 
                'color' : '#514F75',
                'color2' : '#323249',
                'img' : require('@/assets/united/bubble.jpg'),
                'name' : 'united'
            },
            1:{
                'text' : '', 
                'color' : '#163F67',
                'color2' : '#616293',
                'img' : require('@/assets/vizio/bubble.jpg'),
                'name' : 'vizio'
            },
            2:{
                'text' : '', 
                'color' : '#1399B6',
                'color2' : '#16b6d9',
                'img' : require('@/assets/brink/bubble.jpg'),
                'name' : 'brink'
            },
            3:{
                'text' : '', 
                'color' : '#44555C',
                'color2' : '#adb0b2',
                'img' : require('@/assets/hatford/bubble.jpg'),
                'name' : 'hatford'
            },
            4:{
                'text' : '', 
                'color' : '#1A1A1A',
                'color2' : '#4e4e4e',
                'font' : '#484747',
                'img' : require('@/assets/wb/bubble.jpg'),
                'name' : 'game'
            },
            5:{
                'text' : '', 
                'color' : '#C51230',
                'color2' : '#dc1a3a',
                'img' : require('@/assets/wawa/bubble.jpg'),
                'name' : 'wawa'
            },
            6:{
                'text' : '', 
                'color' : '#6F3483',
                'color2' : '#6f3483',
                'img' : require('@/assets/bedland/bubble.jpg'),
                'name' : 'agency'
            },
            7:{
                'text' : '', 
                'color' : '#026A5A',
                'color2' : '#006a5a',
                'img' : require('@/assets/dick/bubble.jpg'),
                'name' : 'dick'
            },
            8:{
                'text' : '', 
                'color' : '#5E5746',
                'color2' : '#7a6e53',
                'img' : require('@/assets/vail/bubble.jpg'),
                'name' : 'replatform'
            },
            
            
        },
        intialBottomCircle:'',
        closeBottomCircle:''
    }),
    mounted:function(){
        this.projectsComponent =    [   this.$refs.projectOne,         this.$refs.projectTwo,      this.$refs.projectThree, 
                                        this.$refs.projectFour, this.$refs.projectFive, this.$refs.projectSix,
                                        this.$refs.projectSeven, this.$refs.projectEight, this.$refs.projectNine]
        this.bubbles =              [this.$refs.bubbleOne,         this.$refs.bubbleTwo,    this.$refs.bubbleThree,
                                     this.$refs.bubbleFour,         this.$refs.bubbleFive,    this.$refs.bubbleSix,
                                     this.$refs.bubbleSeven,         this.$refs.bubbleEight,    this.$refs.bubbleNine],
        this.nBubbles =              ['.bubble-left-h1',         '.bubble-center-h1',    '.bubble-right-h1',
                                     '.bubble-left-h2',         '.bubble-center-h2',    '.bubble-right-h2',
                                     '.bubble-left-h3',         '.bubble-center-h3',    '.bubble-right-h3']
        this.projects =             [".united-slider-container",    ".vizio-slider-container",  ".brink-slider-container", ".financial-slider-container", ".game-slider-container",   ".wawa-slider-container", ".agency-slider-container",    ".dick-slider-container",   ".replatform-slider-container"]
        gsap.to(this.$refs.thumbnail, {scale: 0, zIndex: 1})
        gsap.to(this.$refs.thumbnailLeft, {scale: 0, zIndex: 1})
        gsap.to(this.$refs.prevProjectBtn, {autoAlpha: 0, zIndex: 1})
        gsap.to(this.$refs.nextProjectBtn, {autoAlpha: 0, zIndex: 1})
        
        this.text = "  "
        this.initialHome()
        gsap.set(this.$refs.homeContainer, {autoAlpha: 1})
        for(var i = 0; i< this.projects.length; i++){
            gsap.set(this.projects[i], {autoAlpha: 0})
        }
        gsap.set(this.$refs.textToHideLeft, {width: 0})
        gsap.set(this.$refs.textToHideRight, {width: 0})
        gsap.set(this.$refs.slideTwo, {display: 'none'})
        gsap.set(this.$refs.slideThree, {display: 'none'})
        
        this.intialTopCircle = window.getComputedStyle(this.$refs.topCircle).clipPath
        this.closeTopCircle = window.getComputedStyle(this.$refs.topClose).clipPath

        this.intialBottomCircle = window.getComputedStyle(this.$refs.bottomCircle).clipPath
        this.closeBottomCircle = window.getComputedStyle(this.$refs.bottomClose).clipPath
        

        gsap.set(this.$refs.topCircle, {clipPath: this.closeTopCircle})
        gsap.set('.top-circle-hover-zone', {autoAlpha: 0});

        this.changeCirclePos()
        
        gsap.set('.home-bottom-red-circle', {clipPath: this.closeBottomCircle});
        gsap.set('.bottom-circle-hover-zone', {autoAlpha: 0});
        gsap.set('.home-chris', {autoAlpha: 0})
        //this.prevHover()
        //this.calcTextWidth()
        
    },
    computed:{
        
        firstText(){
            var t = this.text.toString().trim()
            if(t =="") return ""
            return t.slice(0, t.length/2)
        },
        secondText(){
            var t = this.text.toString().trim()
            if(t =="") return ""
            return t.slice(t.length/2)
        },
    },
    created(){
            window.addEventListener("resize", this.changeCirclePos);
        },
    methods: {
        
        isPortrait(){
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none')
                return true
            return false
        },
        changeCirclePos(){
            this.topHover = undefined
            var aspectRatio = document.body.clientWidth / document.body.clientHeight
            
            var calc = -69, calcBottom = 166.5, diff
            
            if(aspectRatio>=1){
                diff = ((1920/1080) - aspectRatio)
                if(diff > 0){
                    calc += diff * 23.6
                    
                    calcBottom -= diff * 23
                } 
                else {
                    calc += diff * 25
                    calcBottom -= diff * 22.22
                    
                }
                this.intialTopCircle = 'circle(70% at -26.5% '+ calc + '%)'    
                this.intialBottomCircle = 'circle(70% at 128% '+ calcBottom + '%)'  
            }else{
                calc = -34
                calcBottom = 134.2
            
                diff = ((1080/1920) - aspectRatio)
                if(diff < 0){
                    calc -= diff * 54.5
                    calcBottom += diff * 18.2
                } 
                else{
                    calc -= diff * 36
                    calcBottom += diff * 19
                }  
                this.intialTopCircle = 'circle(70% at -52.7% '+ calc + '%)'    
                this.intialBottomCircle = 'circle(62% at 135.5% '+ calcBottom + '%)'  
            }
            
            
            
            gsap.to(this.$refs.topCircle, {'--topCircle': calc+'%'})
            gsap.to(this.$refs.bottomCircle, {'--bottomCircle': calcBottom+'%'})
            
            //console.log(this.intialTopCircle)
            //0.4 0.45 0.5-> 135.6 136.5 137
            var elem0 = window.getComputedStyle(document.querySelector('#home-chris-non-change'))
            gsap.set('.home-chris',  {  
                top: elem0.top, 
                left: elem0.left,
                width: elem0.width,
                height: elem0.height
            })
        },
        
        changeProject(before, after, isPrev){
            if(this.transitionProject) return
            
            this.transitionProject = true
            
            var thumbnail = this.$refs.thumbnail
            var textToHide = this.$refs.textToHideRight
            //var btnToHide = (!isPrev?this.$refs.prevProjectBtnText:this.$refs.nextProjectBtnText)
            if(isPrev){
                textToHide = this.$refs.textToHideLeft
                thumbnail = this.$refs.thumbnailLeft
            } 
            
            
            if(this.isPortrait() || !this.projectsComponent[this.actualProject].$refs.chapter.getIsMasked()){
                this.projectsComponent[this.actualProject].pauseProject()
                this.projectsComponent[this.actualProject].mask(this.aesthetic[after].color)
                this.projectsComponent[this.actualProject].blur()
                this.projectsComponent[after].maskBackground()
            }
            var tl = gsap.timeline()
            tl  .to(thumbnail, {scale: 0, duration: 1})
                .to(this.$refs.nextProjectBtnText, {color : after==4? this.aesthetic[after].font: this.aesthetic[after].color, duration: 1}, '-=1')
                .to(this.$refs.prevProjectBtnText, {color : after==4? this.aesthetic[after].font: this.aesthetic[after].color, duration:1}, '-=1')
                .to(textToHide, {width: 0, duration:1.5, onComplete:()=>{
                    tl.set('.btn-project-container', {display: 'none'})
                    
                    //tl.to(this.$refs.nextProjectBtnText, {display})
                }}, '-=1')
            
            this.projectsComponent[after].display()
            this.projectsComponent[before].disapearTo(()=>{
                this.projectsComponent[after].open()
                this.projectsComponent[after].unBlur()
                this.projectsComponent[this.actualProject].maskLeave(true)
                this.projectsComponent[this.actualProject].unBlur()
                this.actualProject = after
                gsap.set('.btn-project-container', {display: 'block'})
                
                
                
                if(this.transitionHoverProject){
                    //this.transitionHoverProject.pause(0)
                    gsap.set(this.$refs.prevProjectBtnText, {autoAlpha: 1})
                    gsap.set(this.$refs.nextProjectBtnText, {autoAlpha: 1})
                    
                    this.transitionHoverProject.eventCallback("onComplete", null);
                    this.transitionHoverProject.kill()
                }
                
                this.transitionProject = false
                this.isHoverProject = false
                
                
            })
        },

        prevProject(){
            if(this.transitionProject) return
            var prev = this.actualProject - 1
            if(prev<0){
                prev = this.projectsComponent.length -1
            } 

            if((this.transitionHoverProject!== undefined && this.transitionHoverProject.progress()!=1)){
                this.transitionHoverProject.eventCallback("onComplete", ()=>{
                    this.prevProject()
                    
                });
                return
            } 
            this.changeProject(this.actualProject, prev, true)
        },
        nextProject(){
            
            if(this.transitionProject) return
            var next = this.actualProject + 1
            if(next>=this.projectsComponent.length){
                next = 0
            } 
            if((this.transitionHoverProject!== undefined && this.transitionHoverProject.progress()!=1)){
                this.transitionHoverProject.eventCallback("onComplete", ()=>{
                    this.nextProject()
                    
                });
                return
            } 
            this.changeProject(this.actualProject, next, false)

        },
        hoverChangeProject(after, isPrev){
            if(this.isPortrait()) return

            if(this.transitionProject) return
            if(this.isHoverProject) return
            this.isHoverProject = true
            var thumbnail = (isPrev?this.$refs.thumbnailLeft: this.$refs.thumbnail)
            if(isPrev)
                this.$refs.thumbnailImgLeft.src = this.aesthetic[after].img
            else this.$refs.thumbnailImg.src = this.aesthetic[after].img

            var btn = (isPrev?this.$refs.prevProjectBtnText:this.$refs.nextProjectBtnText)
            var btnToHide = (!isPrev?this.$refs.prevProjectBtnText:this.$refs.nextProjectBtnText)
            var textToHide = (isPrev?this.$refs.textToHideLeft:this.$refs.textToHideRight)
            
            var color2 = (after==4? this.aesthetic[after].font: '#000')
            var color1 = '#fff'
            var color4 = '#fff'
            var color3 = this.aesthetic[this.actualProject].color
            /*if(isPrev){
                color1 = (after==4? this.aesthetic[after].font: '#000')
                color2 = '#fff'
            }*/


            gsap.set(isPrev?this.$refs.textEndLeft:this.$refs.textBegin, {color: this.aesthetic[after].color})
            
            this.text = this.aesthetic[after].text
            this.projectsComponent[this.actualProject].pauseProject()
            this.projectsComponent[this.actualProject].mask(this.aesthetic[after].color)
            this.projectsComponent[this.actualProject].blur()
            this.projectsComponent[after].maskBackground()
            
            var tl = gsap.timeline()
            tl.set(thumbnail, {autoAlpha:0})
                .set(thumbnail, {autoAlpha:1, zIndex: 3})
                
                .fromTo(thumbnail, {scale: 0}, {scale: 1, duration: 1})
                .fromTo(textToHide,{width: 0}, {width: 'auto', duration:1}, '-=1')
                .fromTo(btnToHide, {autoAlpha: 1}, { autoAlpha: 0 , duration:1}, '-=1')
                .fromTo(btn, { color: color3, '--color2': color4}, { color: color1, '--color2': color2 , duration:1}, '-=1')
            this.transitionHoverProject = tl

        },
        prevHover(){
            
            var prev = this.actualProject - 1
            if(prev<0) prev = this.projectsComponent.length -1
            this.hoverChangeProject(prev, true)


        },
        prevLeave(){
            if(this.isPortrait()) return
            if(this.transitionProject) return
            var prev = this.actualProject - 1
            if(prev<0) prev = 2
            this.projectsComponent[this.actualProject].playProject()
            this.projectsComponent[this.actualProject].unBlur()
            this.projectsComponent[this.actualProject].maskLeave()
            this.projectsComponent[prev].maskBackgroundLeave()
            if(this.transitionHoverProject)
            this.transitionHoverProject.reverse()
            this.isHoverProject = false
        },
        nextHover(){
            var next = this.actualProject + 1
            if(next>=this.projectsComponent.length) next = 0
            this.hoverChangeProject(next, false)
        },
        nextLeave(){
            if(this.isPortrait()) return
            if(this.transitionProject) return
            
            var next = this.actualProject + 1
            if(next>2) next = 0
            this.projectsComponent[this.actualProject].playProject()
            this.projectsComponent[this.actualProject].unBlur()
            this.projectsComponent[this.actualProject].maskLeave()
            this.projectsComponent[next].maskBackgroundLeave()
            this.transitionHoverProject.reverse()
            this.isHoverProject = false
        },
        initialHome(){
            this.$refs.bubbleOne.pause()
            this.$refs.bubbleTwo.pause()
            this.$refs.bubbleThree.pause()
            
        },
        startAnimation(){
            this.$refs.bubbleOne.pulse()
            this.$refs.bubbleTwo.pulse()
            this.$refs.bubbleThree.pulse()
            this.$refs.bubbleFour.pulse()
            this.$refs.bubbleFive.pulse()
            this.$refs.bubbleSix.pulse()
            this.$refs.bubbleSeven.pulse()
            this.$refs.bubbleEight.pulse()
            this.$refs.bubbleNine.pulse()
        },
        playActualHome(){
            var index = this.actualCirclePage*3
            
            this.bubbles[index+0].play()
            this.bubbles[index+1].play()
            this.bubbles[index+2].play()
        },
        pauseActualHome(){
            var index = this.actualCirclePage*3
            this.bubbles[index+0].pause()
            this.bubbles[index+1].pause()
            this.bubbles[index+2].pause()
        },

        pauseHome(one, two){
            
            /*this.$refs.bubbleRight.pause()
            this.$refs.bubbleCenter.pause()
            this.$refs.bubbleLeft.pause()*/
            this.isBubbleHover = true
            var index = this.actualCirclePage*3
            this.bubbles[index+0].pause()
            this.bubbles[index+1].pause()
            this.bubbles[index+2].pause()
            
            
            this.bubbles[one].scaleDown()
            this.bubbles[two].scaleDown()
            //this.$emit('projectHover')
        },
        playHome(one, two){
            
            /*this.$refs.bubbleLeft.play()
            this.$refs.bubbleRight.play()
            this.$refs.bubbleCenter.play()*/
            this.isBubbleHover = false
            var index = this.actualCirclePage*3
            this.bubbles[index+0].pulse()
            this.bubbles[index+1].pulse()
            this.bubbles[index+2].pulse()
            this.bubbles[one].scaleUp()
            this.bubbles[two].scaleUp()
            this.$emit('projectLeave')
        },
        openProjectOne(){
            this.openProject(0)
        },
        openProjectTwo(){
            this.openProject(1)
        },
        openProjectThree(){
            this.openProject(2)
        },
        openProjectFour(){
            this.openProject(3)
        },
        openProjectFive(){
            this.openProject(4)
        },
        openProjectSix(){
            this.openProject(5)
        },
        openProjectSeven(){
            this.openProject(6)
        },
        openProjectEight(){
            this.openProject(7)
        },
        openProjectNine(){
            this.openProject(8)
        },

        hideHomeElements(){
            this.$emit('projectOpen')
            gsap.set('.home-arrow', {display: 'none'})
            gsap.set('.home-bullets', {display: 'none'})
            gsap.set('.home-chris', {autoAlpha: 0})
            gsap.set('.top-circle-hover-zone', {autoAlpha: 0})
            gsap.set('.say-hello-btn-container', {autoAlpha: 0})
            gsap.set('.bottom-circle-hover-zone', {autoAlpha: 0})
            gsap.set('.detail-arrow ', {display: 'block'})
            
        },

        showHomeElements(){
            this.$emit('close')
            gsap.set(this.$refs.circleContainer, {display: 'block'})
            gsap.set('.home-arrow', {display: 'block'})
            gsap.delayedCall(1.5, ()=>{
                gsap.set('.home-bullets', {display: 'block'})
            })
            
            gsap.set('.home-chris', {autoAlpha: 1})
            gsap.set('.top-circle-hover-zone', {autoAlpha: 1})
            gsap.set('.say-hello-btn-container', {autoAlpha: 1})
            gsap.set('.bottom-circle-hover-zone', {autoAlpha: 1})
            gsap.set('.detail-arrow ', {display: 'none'})
            //gsap.set('.detail-project ', {display: 'none'})
            var indexC = this.actualCirclePage*3
            gsap.set(this.nBubbles[indexC+0], {autoAlpha: 1, scale: 1})
            gsap.set(this.nBubbles[indexC+1], {autoAlpha: 1, scale: 1})
            gsap.set(this.nBubbles[indexC+2], {autoAlpha: 1, scale: 1})
            this.projectOpen = false
            this.isPopupOpen = false
            
        },
        
        closeProject(){

            this.projectOpen = false
        },
        opacityDown(index){
            gsap.to('.close-btn', {autoAlpha:0.15})
            this.projectsComponent[index].playProject()
        },
        opacityUp(index){
            gsap.to('.close-btn', {autoAlpha:0.5})
            this.projectsComponent[index].pauseProject()
        },
        openProject(index){
            //if(this.projectOpen || this.openingProject) return
            this.projectOpen = true
            this.openingProject = true
            this.isPopupOpen = true
            this.actualProject = index
            
            let time = 4
            var tl =  gsap.timeline()//.delay(3)
            var indexC = this.actualCirclePage*3
            
            tl.set(this.$refs.prevProjectBtn, {zIndex: 4})
                .set('.detail-project ', {display: 'block'})
                .set(this.$refs.nextProjectBtn, {zIndex: 4})
                .to(this.$refs.prevProjectBtn, {autoAlpha: 1, duration:time })
                .to(this.$refs.prevProjectBtnText, { color : this.aesthetic[this.actualProject].color, duration: time}, '-='+time)
                .to(this.$refs.nextProjectBtn, {autoAlpha: 1, duration: time}, '-='+time)
                .to(this.$refs.nextProjectBtnText, { color : this.aesthetic[this.actualProject].color, duration: 1}, '-='+time)
            


                if(index !== indexC)     tl.to(this.nBubbles[indexC+0], {autoAlpha: 0, scale: 0.25, duration: 1}, '-='+time)
                if(index !== (indexC+1))     tl.to(this.nBubbles[indexC+1], {autoAlpha: 0, scale: 0.25, duration: 1}, '-='+time)
                if(index !== (indexC+2))     tl.to(this.nBubbles[indexC+2], {autoAlpha: 0, scale: 0.25, duration: 1}, '-='+time)
                
                //
                .set(this.nBubbles[indexC+0], {autoAlpha: 1, scale: 1})
                .set(this.nBubbles[indexC+1], {autoAlpha: 1, scale: 1})
                .set(this.nBubbles[indexC+2], {autoAlpha: 1, scale: 1, onComplete:()=>{
                    this.openingProject = false
                }})
            gsap.delayedCall(4, ()=>{
                gsap.set(this.$refs.circleContainer, {display: 'none'})
                //console.log('BRO CIRCLES HIDE')
            })
            this.projectsComponent[index].open(true)    
        },

        changeTo(direction){
            
            var to, from = this.actualCirclePage
            if(direction=='right'){
                to = from - 1
                if(to<0) to = this.slides.length-1
            }else{
                to = from + 1
                if(to>=this.slides.length) to = 0
            }
            this.changeCirclePageTo(to)
        },
        changeCirclePageTo(to){
            if(to == this.actualCirclePage) return
            if(this.isChangingPage){
                //console.log('REBOTE: '+ to + ' actual: ' + this.actualCirclePage)
                return
            } 
            //console.log('ME CAMBIARIAN')
            this.isChangingPage = true
            this.$refs.sliderBullets.children[this.actualCirclePage].classList.remove('active');
            this.$refs.sliderBullets.children[to].classList.add('active');
            this.transitionOutEffect(this.actualCirclePage)
            this.transitionInEffect(to)
            this.actualCirclePage = to
        },
        transitionOutEffect(page){
            var index = page *3
            
            this.pauseActualHome()
            
            gsap.set(this.slides[page], {zIndex: 0})
            

            this.bubbles[index+0].transitionOut(()=>{
                    gsap.set(this.slides[page], {display: 'none', zIndex: 'unset'})
                }
            )
            this.bubbles[index+1].transitionOut()
            this.bubbles[index+2].transitionOut()
        },
        transitionInEffect(page){
            this.actualCirclePage = page
            var index = page*3
            

            var tl = gsap.timeline()
            tl  .set(this.slides[page], {display: 'block', autoAlpha: 0, zIndex: 1})
            
                .to(this.slides[page], {autoAlpha: 1, duration: 0.85, onComplete:()=>{
                    gsap.set(this.slides[page], {zIndex: 'unset'})
                    this.isChangingPage = false
                    
                    this.playActualHome()
                }})

            this.transitionInEffectGSAP = tl
            this.bubbles[index+0].resetBubble()
            this.bubbles[index+1].resetBubble()
            this.bubbles[index+2].resetBubble()
        },
        hoverRedCircleBottom  () {
            gsap.to('.home-bottom-red-circle', {clipPath: this.intialBottomCircle, duration:0.25});
            gsap.to('.home-overlay', {autoAlpha:0.3, display:'block', duration: 0.25})
            this.pauseActualHome()
        },
        unhoverRedCircleBottom () {
            if(this.openingSayHello) return
            gsap.to('.home-bottom-red-circle', {clipPath: this.closeBottomCircle, duration:0.25});
            gsap.to('.home-overlay', {autoAlpha:0, display:'none', duration: 0.25})
            this.playActualHome()
        },
        openSayHello(){
            if(this.isPopupOpen) return
            this.openingSayHello = true
            this.isPopupOpen = true
            
            //gsap.to('.say-hello-btn-container', {autoAlpha: 0, onComplete:()=>{
                this.$refs.sayHello.open(this.intialBottomCircle)
                gsap.delayedCall(2, ()=>{
                    this.openingSayHello = false
                    this.unhoverRedCircleBottom()
                    
                })
            //}})
            
        },
        restartIdleCount(){
        
            this.isPopupOpen = false
            this.spyUser()
        },
        spyUser(){
            /*clearTimeout(this.idleTimer);
            this.idleTimer = setTimeout(()=>{
                if(!this.isPopupOpen && !this.isBubbleHover){
                    this.changeTo('left')
                    this.spyUser()
                }
            }, 16000)*/
        },
        
        showSliderOverlay(index){

            if(index!==undefined){
                var btn = '.home-arrow-right'
                if(index==1) btn = '.home-arrow-left'
                gsap.to(btn, {opacity: 0.25}) 
            }
            
            gsap.set('.home-arrow', {zIndex: 1})
            gsap.set('.home-bullets', {zIndex: 1})
            
            //gsap.to('.home-overlay', {autoAlpha:0.3, display:'block', duration: 0.25})
            this.pauseActualHome()

        },
        hideSliderOverlay(index){
            var btn = '.home-arrow-right'
            if(index==1) btn = '.home-arrow-left'
            gsap.set('.home-arrow', {clearProps: 'z-index'})
            gsap.to(btn, {opacity: 0})
            gsap.to('.home-overlay', {autoAlpha:0, display:'none', duration: 0.25})
            this.playActualHome()
        },
        hoverRedCircleTop () {
            if(!this.topHover){
                var tl = gsap.timeline()
                tl.set('.home-top-red-circle', {autoAlpha: 1})
                .to('.home-top-red-circle', {clipPath: this.intialTopCircle, duration:0.25})
                .to('.home-overlay', {autoAlpha:0.3, display:'block', duration: 0.25}, '-=0.25')
                this.topHover = tl
            }
            this.topHover.play()
            this.pauseActualHome()
        },
        unhoverRedCircleTop  () {
            if(this.openingBio) return
            if(this.topHover){
                this.topHover.reverse()
            }
            /*gsap.to('.home-top-red-circle', {clipPath: this.closeTopCircle, duration: 0.25})
            gsap.to('.home-overlay', {autoAlpha:0, display:'none', duration: 0.25})*/
            this.playActualHome()
        },
        openBio(){
            if(this.isPopupOpen) return
            this.isPopupOpen = true
            this.$refs.bioSlide.restart()
            this.$refs.bioSlide.$refs.bioOne.openInitial(this.intialTopCircle, this.closeTopCircle)
            gsap.set('.top-circle-hover-zone', {autoAlpha: 0, delay: 3})
            gsap.set('.bottom-circle-hover-zone', {autoAlpha: 0, delay: 3})
        },
    },
    
    
    
    
}
</script>

<style>

.home-arrow{
    opacity: 0;
    
}
.next-arrow-2::after,
.next-arrow-2::before,
.prev-arrow-2::after,
.prev-arrow-2::before{
    background-color: #fff !important;
}

.circles-container{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}


.thumbnail-text-left{
    position: absolute;
    font-family: 'quoteFont';
    color: #fff;
    font-size: 2rem;
    top: 50%;
    left: 100%;
    transform: translateX(-50%);
    white-space:nowrap ;
}

.thumbnail-text{
    position: absolute;
    font-family: 'quoteFont';
    color: #fff;
    font-size: 2rem;
    top: 50%;
    transform: translateX(-50%);
    white-space:nowrap ;
}

.project-thumbnail-img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 99%;
    overflow: hidden;
}
    
.project-thumbnail div img{
    position: absolute;
    width: 102%;
    height: 102%;
    left: -1%;
    top: -1%;
}
.project-thumbnail-left{
    position: absolute;
    left: 6% !important;
    
    
}

.project-thumbnail{
    position: absolute;
    top: 9%;
    right: 6%;
    width: 12vw;
    height: 12vw;
    
}

.text-to-hide{
    width: 0;
    
}


.project-btn span{
    color: var(--color2)
}
.project-btn {
    
    position: absolute;
    font-family: 'chrisFont';
    font-size: 1.3rem;
    color: white;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bolder;
    bottom: 5vh;
    word-spacing: 15px;
}

.project-btn div{
    display: inline-block;
    overflow: hidden;
}

.btn-project-container{
    position: absolute;
    bottom: 0;
    width: 24rem;
    height: 10vh;
    
}

.right{
    right: 0;
}
.left{
    left: 0;
}
.project-prev{
    left: 6vw;
}

.project-next{
    right: 6vw;
}



.h1-close-btn-1{
    background-color: red;
}
.h1-close-btn-0{
    --color: #fff;
}

.h1-close-btn-0 .close-btn::after,
.h1-close-btn-0 .close-btn::before{
    background-color: #fff;
}

.h1-close-btn-0, .h1-close-btn-1, .h1-close-btn-2{
    
    width: 3.5vw;
    height: 3.5vw;
    position: absolute;
    right: 6%;
    top: 7%;
    opacity: 0.15;
}

.h1-close-btn-2:hover{
    opacity: 0.5
}
    .close-btn-vizio::after,
    .close-btn-vizio::before{
        background-color: #fff;
    }
    .close-btn-brink::after,
    .close-btn-brink::before{
        background-color: #fff;
    }
    .close-btn-united::after,
    .close-btn-united::before{
        background-color: #fff;
    }

    .bubble-left-h1{
        top: 32%;
        left: 13%;
        height: 23.5vw;
        width: 23.5vw;
    }

    .bubble-center-h1{
        top: 17%;
        left: 34.5%;
        width: 32vw;
        height: 32vw;
    }

    .bubble-right-h1{
        top: 22%;
        left: 65%;
        height: 21.5vw;
        width: 21.5vw;
    }

    .h1-close-btn-0, .h1-close-btn-1{
        opacity: 0;
        visibility: hidden;
    }
    .vizio-slider-container, .brink-slider-container, .united-slider-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .detail-project{
        visibility: hidden;
        opacity: 0;
    }    

    .hack{
        position: absolute;
        left: -1000%;
    }

@media (orientation: portrait) {
    .bubble-center-h1 {
        top: 30%;
        left: 22.5%;
        width: 55vw;
        height: 55vw;
    }

    .bubble-right-h1 {
        top: 32%;
        left: 70%;
        height: 35.5vw;
        width: 35.5vw;
    }

    .bubble-left-h1 {
        top: 40%;
        left: -5%;
        height: 34.5vw;
        width: 34.5vw;
    }

    .home-bullets {
        bottom: 18.7% !important;
    }

    .project-btn {
        font-size: 3.3rem;
        height: auto;
        bottom: 4vh;
    }

    .btn-project-container {
        width: 40rem;
    }
    

    .h1-close-btn-0, .h1-close-btn-2, .h1-close-btn-3{
        width: 8vw;
        height: 8vw;
        top: 4.5%;
        opacity: 1;
        min-width: 30px;
        min-height: 30px;
    }
}

.close-btn-financial::after,
    .close-btn-financial::before{
        background-color: #fff;
    }
    .close-btn-game::after,
    .close-btn-game::before{
        background-color: #fff;
    }
    .close-btn-wawa::after,
    .close-btn-wawa::before{
        background-color: #fff;
    }

    .bubble-left-h2{
        top: 27%;
        left: 14.5%;
        width: 21vw;
        height: 21vw;
    }

    .bubble-center-h2{
        top: 20%;
        left: 33.5%;
        height: 31.5vw;
        width: 31.5vw;
    }

    .bubble-right-h2{
        top: 33%;
        left: 60.5%;
        width: 24vw;
        height: 24vw;
    }

    .wawa-slider-container, .game-slider-container, .financial-slider-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

@media (orientation: portrait) {
    .bubble-center-h2 {
        top: 30%;
        left: 22.5%;
        width: 55vw;
        height: 55vw;
    }

    .bubble-right-h2 {
        top: 32%;
        left: 70%;
        height: 35.5vw;
        width: 35.5vw;
    }

    .bubble-left-h2 {
        top: 40%;
        left: -5%;
        height: 34.5vw;
        width: 34.5vw;
    }
}

.close-btn-replatform::after,
    .close-btn-replatform::before{
        background-color: #fff;
    }
    .close-btn-dick::after,
    .close-btn-dick::before{
        background-color: #fff;
    }
    .close-btn-agency::after,
    .close-btn-agency::before{
        background-color: #fff;
    }
    .bubble-left-h3{
        top: 29%;
        left: 15%;
        height: 23.5vw;
        width: 23.5vw;
    }

    .bubble-center-h3{
        top: 19%;
        left: 36.5%;
        height: 31.5vw;
        width: 31.5vw;
    }

    .bubble-right-h3{
        top: 28%;
        left: 66%;
        height: 21.5vw;
        width: 21.5vw;
    }
  
    .h3-close-btn-0, .h3-close-btn-1,.h3-close-btn-2{
        visibility: hidden;
        opacity: 0;
    }
    .agency-slider-container, .dick-slider-container, .replatform-slider-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        visibility: hidden;
    }

@media (orientation: portrait) {
    .bubble-center-h3 {
        top: 30%;
        left: 22.5%;
        width: 55vw;
        height: 55vw;
    }

    .bubble-right-h3 {
        top: 32%;
        left: 70%;
        height: 35.5vw;
        width: 35.5vw;
    }

    .bubble-left-h3 {
        top: 40%;
        left: -5%;
        height: 34.5vw;
        width: 34.5vw;
    }
}
</style>