<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import BrinkOneVue from './brink/BrinkOne.vue'
import BrinkTwoVue from './brink/BrinkTwo.vue'
import BrinkThreeVue from './brink/BrinkThree.vue'

export default { 
    name: "brinkSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'brink-slider-container',
        classOverlay: 'brink-crop-overlay',
        classSlider: 'brink-background',
        classCloseBtn: 'close-btn-brink',
        logos:[
          {
            path:require('@/assets/brink/logo.png'),
            class: 'brink-logo'
          }
        ],
        slides:[<BrinkOneVue />, <BrinkTwoVue/>, <BrinkThreeVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .brink-background{
    background-image: url('~@/assets/brink/background.jpg');

  }
  


  .brink-logo{
    width: 15.8vw !important;
    top: 7%
  }
  .brink-arrow{
    position: absolute;
    height: 90px;
    top: calc(50% - 45px);
    cursor: pointer;
    opacity: 0.3;
  }
  .brink-arrow:hover{
    opacity: 1;

  }

  

  
  .brink-close-btn{
    position: absolute;
    min-width: 30px;
    min-height: 30px;
    width: 3%;
    left: 91%;
    top: 7%;
    cursor: pointer;
  }

@media (orientation: portrait) {
  .brink-logo {
    height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
}
  

</style>