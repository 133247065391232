<template>
    <project ref="project"
        classContainer="united-container united-3" 
        classOverlay="overlay-united"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"
        :vimeoContainer="this.vimeoContainer"/>
    <!--<img class="example" src="@/assets/united/home1.jpg" /> -->
    <!--<img class="example" src="@/assets/vizio/vizio1-portrait.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    export default { 
        name: "unitedOne",
        components:{Project},
        data: () => ({
            ghostVideo: true,
            vimeoContainer:{
                name: 'united1'
            },
            bubbleOneData: {
                class: 'bubble-one-united-1',
                text: '<p>'+
                            'Every year the United Way of Greater<br/>'+ 
                            'Philadelphia and Southern New<br/>'+
                            'Jersey creates a Donation video they<br/>' + 
                            'play for corporate audiences,<br/>'+
                            'primarily to new hires, encouraging<br/>'+
                            'the employee to donate a small<br/>'+
                            'portion of their salary to the United<br/>'+
                            'Way. A more inspiring project you<br/>'+
                            'could not find.'+
                        '</p>',
                title:'Donation Video', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop:'5%', color:'#373960', colorOverlay:'#fff', colorText: '#0f3456'
            },
            bubbleTwoData:{
                class:  'bubble-two-united-1',
                text:   '<p>CONCEPTING & WRITING<p/><br/>'+ 
                        '<p>CASTING & CREATIVE <br/>DIRECTION<p/><br/>'+ 
                        '<p>SCORING WITH <br/>ORIGINAL MUSIC<p/>',
                color:'#373960',
                colorOverlay:'#5d60a2',
                colorText:'#fff'

            },
            videoOneData:{
                class: 'united-1-video-1',
                videoUrl: 'assets/united/full-video.mp4',
                poster: 'assets/united/h1-video.jpg',
                isUnitedCenter: true,
                img: 'assets/united/h1-video.jpg',
                onAxisY:false
            },
            videoTwoData:{
                class: 'united-1-video-2',
                videoUrl: 'assets/united/home1-left.mp4',
                poster:'assets/united/united1-cover-1.jpg',
                onAxisY:false
            },
            videoThreeData:{
                class: 'united-1-video-3',
                videoUrl: 'assets/united/home1-right.mp4',
                poster:'assets/united/united1-cover-3.jpg',
                onAxisY:false
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            },
            closeMainVideo(){
                return this.$refs.project.closeMainVideo()
            }
        },
    }
</script>

<style>

    .overlay-project{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.7;
    }
    .overlay-united{
        background-color: #514F75;
    }

    .united-1-video-3{
        top: 58% !important;
        left: 66.25% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .united-1-video-2{
        top: 32% !important;
        left: 14.5% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .united-1-video-1{
        top: 23% !important;
        left: 26.5% !important;
        width: 48.5vw !important;
        height: 27.5vw !important;
    }
    
    .bubble-two-united-1{
        left: 21.75%;
        top: 69.75%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-united-1{
        left: 72.75%;
        top: 32%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .united-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media (orientation: portrait) {
        .bubble-one-united-1{
            left: 38.75%;
            top: 9%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-united-1{
            height: 42.5vw;
            width: 42.5vw;
            top: 54%;
        }

        .united-1-video-3{
            top: 58% !important;
            left: 52.25% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-1-video-2{
            top: 24% !important;
            left: 10.5% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-1-video-1{
            top: 33% !important;
            left: 6.5% !important;
            width: 84.5vw !important;
            height: 48vw !important;
        }
    }
</style>