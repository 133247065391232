<template>
    
    <div class="loader-container">
        
    
        <div class="loader-overlay" />
        
        <div class="chris-logo-container chris-logo-container-position loader-chris">
            <img :src="require('@/assets/chris.png')" />
        </div>

        <div class="circle">
            <div class="chris-logo-container chris-logo-container-position">
                <img :src="require('@/assets/chris-black.png')" rel="preload" />
            </div>
            <div class="text-container"> 
                
                
                <div class="quote">
                    <div class="quote-symbol loading-quote-symbol" >“</div>
                    {{this.quotes[this.index].text}} <br/> 
                    {{this.quotes[this.index].text1}}<span>”</span>
                
                </div>
                
                <div class="author">{{this.quotes[this.index].author}}</div>
            
            </div>

            <div class ="data-container">
                <div class="loading-bar"> 
                    <div :class="{activeBullet: this.ghostCounter > 0}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 10}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 20}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 30}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 40}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 50}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 60}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 70}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter > 80}">&bull;</div> 
                    <div :class="{activeBullet: this.ghostCounter >= 100}">&bull;</div> 
                    
                </div>
                
            </div>
        </div>
        
        <div class="img-quote-container">
            <img class="img-quote"  :src="require('@/'+this.quotes[this.index].png)" /> 
        </div>
        
        <!--<img class="example" src="@/assets/bio/load.jpg" /> -->
        <!-- <img class="example" src="@/assets/bio/load-mobile.jpg" /> -->
    </div>
    
</template>

<style>
.loading-quote-symbol{
    
    left: 0 !important;
    font-size: 8rem !important;
    top: 36% !important;
}

.example{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
}

    .chris-logo-container, .chris-logo-container img, .home-chris img {
        width: 100%;
        height: 100%;
        will-change: all;
        
    }

    .chris-logo{
        width: 100%;
        height: 100%;
        clip-path: url("/assets/chris.png");
    }



    .chris-logo-container{
        position: absolute;
        width: 15.5vw;
        height: 1.1vw;
    }

    .chris-logo-container-position{
        left: 23.3%;
        top: 22.9vh;
    }

    .quote-symbol{
        position: absolute;
        left: 5px;
        font-size: 9rem;
        font-weight: normal !important;
    }
    
    html{
        font-size: 0.85vw;
    }
    .activeBullet{
        color: #fff !important;
    }

    .loader-overlay{
        width: 100%;
        height: 100%;
        background: #000;
    }

    .loader-container{
        position:absolute; 
        width: 100%; 
        height:100%; 
        top:0; 
        left:0; 
        overflow: hidden;
    }

    .circle{
        position:absolute; 
        background-color: #ed1b24; 
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(62.5% at 36% 5%);
        will-change: clip-path;

    }

    .text-container{
        top: 21%;
        left: 23%;
        width: 45%;
        position: absolute;
    }

    .quote{
        color: #fff;
        font-size: 4.35rem;
        font-family: 'quoteFont';
        text-align: left;
        padding-top: 15.2%;
        padding-left: 3.5vw;
        line-height: 5.55rem;
        
    }

    .quote span{
        font-weight: normal !important;
    }

    .author{
        color: #fff;
        font-family: 'quoteFont';
        font-size: 1.95rem;
        padding-top: 4%;
        padding-left: 3.5vw;
    }

    .data-container{
        position: absolute;
        display: flex;
        top: 67%;
        left: 23%;
        width: 38%;
        justify-content: center;
    }

    .loading-bar{
        width: 100%;
        display: flex;
        padding-left: 3.5vw;
    }

    .loading-bar div{
        color: #000;
        font-size: 2rem;
        width: 10%;
        display: inline-block;
    }


    .img-quote-container{
        position: absolute;
        width: 17vw;
        height: 17vw;
        top: 53%;
        left: 61%;
        border-radius: 99%;
        overflow: hidden;
    }

    .img-quote{
        position: absolute;
        display: inline-block;
        width: 104%;
        height: 104%;
        left: -2%;
        top: -2%;
        
        
    }

@media (orientation: portrait) {
  .img-quote-container{
    width: 34vw;
    height: 34vw;
    top: 55%;
    left: 51%;
  }

  .circle{
      clip-path: circle(70.5% at 9% 23%);
  }

    .chris-logo-container {
        width: 41vw;
        height: 3vw;
        
    }

    .chris-logo-container-position{
        left: 7.2vw;
        top: 21.7vh;
    }

    .text-container {
        top: 23.5% !important;
        left: 7.2vw !important;
        width: 90% !important;
    }

    .quote {
        padding-left: 6.59vw;
        font-size: 9.45em;
        line-height: 10.55rem;
    }

    .loading-quote-symbol {
        font-size: 18rem !important;
        top: 33% !important;
    }
    .author {
        font-size: 5.3rem;
        padding-top: 7%;
        padding-left: 6.59vw;
    }
    .data-container{
        left: 7.2vw;
        width: 43.5%;
        top: 63.5%;
    }

    .loading-bar {
        padding-left: 6.59vw;
    }
    .loading-bar div {
        font-size: 4.5rem;
    }

    .loading-bar div:nth-child(2n) {
        visibility: hidden;
    }
}






    

   

</style>

<script>
    import gsap from 'gsap'
    //import anime from 'animejs/lib/anime.es.js';
    export default {
        name: "Loader",
        data: () => ({
            quotes:[
                {text: 'Everyone needs', text1:'a fantasy.', author :'Andy Warhol', png: "assets/loader/andy.jpg"},
                {text: 'Take time', text1: 'to look.', author : 'Georgia O\'Keeffe', png: "assets/loader/georgia.jpg"},
                {text: 'I always saw what', text1: 'others could not see.', author : 'Salvador Dali', png: "assets/loader/salvador.jpg"},
                {text: 'Art is for', text1: ' everyone.', author : 'Keith Haring', png: "assets/loader/keith.jpg"},
                {text: 'Everything you can', text1: 'imagine is real.', author : 'Pablo Picasso', png: "assets/loader/pablo.jpg"}
            ],
            index: 0,
            counter: 0,
            ghostCounter: 0,
            progressInterval: '',
            imgLoaded: false,
            timer: undefined,
            homeFontSize: '',
            imagesToLoad: [
                require('@/assets/chris-red.png'),
                require('@/assets/chris-black.png'),
                require('@/assets/united/bubble.jpg'),
                require('@/assets/vizio/bubble.jpg'),
                require('@/assets/brink/bubble.jpg'),
                require('@/assets/hatford/bubble.jpg'),
                require('@/assets/wb/bubble.jpg'),
                require('@/assets/wawa/bubble.jpg'),
                require('@/assets/bedland/bubble.jpg'),
                require('@/assets/dick/bubble.jpg'),
                require('@/assets/vail/bubble.jpg'),
                require('@/assets/united/logo.svg'),
                require('@/assets/brink/logo.png'),
                require('@/assets/vizio/logo.svg'),
                require('@/assets/hatford/logo2.png'),
                require('@/assets/chase/logo.svg'),
                require('@/assets/mtbank/logo.svg'),
                require('@/assets/wawa/logo.svg'),
                require('@/assets/wb/logo.svg'),
                require('@/assets/thq/logo.svg'),
                require('@/assets/sr3/logo.svg'),
                require('@/assets/bedland/logo2.png'),
                require('@/assets/bonteous/logo.svg'),
                require('@/assets/dustland/logo2.png'),
                require('@/assets/vail/logo.png'), 
                require('@/assets/nfi/logo.svg'), 
                require('@/assets/tbn/logo.svg'), 
                require('@/assets/dick/logo.svg'),
                require('@/assets/brink/brink_slide_1.jpg'),
                require('@/assets/brink/brink_slide_2.jpg'),
                require('@/assets/brink/brink_slide_3.jpg'),
                require('@/assets/hatford/hatford_slide_1.jpg'),
                require('@/assets/hatford/hatford_slide_2.jpg'),
                require('@/assets/hatford/hatford_slide_3.jpg'),
                require('@/assets/chase/chase_slide_1.jpg'),
                require('@/assets/chase/chase_slide_2.jpg'),
                require('@/assets/chase/chase_slide_3.jpg'),
                require('@/assets/mtbank/mtbank_slide_1.jpg'),
                require('@/assets/mtbank/mtbank_slide_2.jpg'),
                require('@/assets/mtbank/mtbank_slide_3.jpg'),
                require('@/assets/wb/wb_slide_1.jpg'),
                require('@/assets/wb/wb_slide_2.jpg'),
                require('@/assets/wb/wb_slide_1.jpg'),
                require('@/assets/sr3/sr3_slide_1.jpg'),
                require('@/assets/sr3/sr3_slide_2.jpg'),
                
                require('@/assets/thq/thq_slide_1.jpg'),
                require('@/assets/thq/thq_slide_2.jpg'),
                

                require('@/assets/wawa/wawa_slide_1.jpg'),
                require('@/assets/wawa/wawa_slide_2.jpg'),
                require('@/assets/wawa/wawa_slide_3.jpg'),
                require('@/assets/wawa/wawa2_slide_1.jpg'),
                require('@/assets/wawa/wawa2_slide_2.jpg'),

                require('@/assets/wawa/wawa3_slide_1.jpg'),
                require('@/assets/wawa/wawa3_slide_2.jpg'),
                require('@/assets/wawa/wawa3_slide_3.jpg'),

                require('@/assets/bedland/bedland_slide_1.jpg'),
                require('@/assets/bedland/bedland_slide_2.jpg'),
                require('@/assets/bedland/bedland_slide_3.jpg'),

                require('@/assets/bonteous/bonteous_slide_1.jpg'),
                require('@/assets/bonteous/bonteous_slide_2.jpg'),
                require('@/assets/bonteous/bonteous_slide_3.jpg'),

                require('@/assets/dustland/dustland_slide_1.jpg'),
                require('@/assets/dustland/dustland_slide_2.jpg'),
                require('@/assets/dustland/dustland_slide_3.jpg'),

                require('@/assets/dick/dick_slide_1.jpg'),
                require('@/assets/dick/dick2_slide_1.jpg'),
                require('@/assets/dick/dick3_slide_1.jpg'),

                require('@/assets/vail/vail_slide_1.jpg'),
                require('@/assets/vail/vail_slide_2.jpg'),
                require('@/assets/vail/vail_slide_3.jpg'),

                require('@/assets/nfi/nfi_slide_1.jpg'),
                require('@/assets/nfi/nfi_slide_2.jpg'),
                require('@/assets/nfi/nfi_slide_3.jpg'),
                require('@/assets/tbn/tbn_slide_1.jpg'),
                require('@/assets/tbn/tbn_slide_2.jpg'),
                require('@/assets/agency/background.jpg'),
                require('@/assets/brink/background.jpg'),
                require('@/assets/dick/background.jpg'),
                require('@/assets/hatford/background.jpg'),
                require('@/assets/wb/background.jpg'),
                require('@/assets/replatform/background.jpg'),
                require('@/assets/united/background.jpg'),
                require('@/assets/vizio/background.jpg'),
                require('@/assets/wawa/background.jpg'),
                
                
                
            
            ],
            imageLoaded: 0

            
            
       }),
        created() {
            this.index = Math.floor(Math.random() * this.quotes.length);

            


            gsap.delayedCall(2, ()=>{
                this.timer = window.setInterval(()=>{
                    var int = (this.counter/10)
                    var gInt = (this.ghostCounter/10)
                    if(int > gInt) this.ghostCounter += 10
                    
                    if(this.ghostCounter==100){
                        this.goToHome()
                        window.clearInterval(this.timer)
                    }
                }, 240);

                var I = new Image()
                I.onload = ()=>{
                    this.imgLoaded = true
                }
                I.src = require('@/'+this.quotes[this.index].png)
                
                this.counter = 0

                
                for (const imageSrc of this.imagesToLoad) {    
                    const img = new Image();
                    img.src = imageSrc;

                    img.onload = () => {
                        
                        this.imageLoaded++;
                        this.counter = (this.imageLoaded/this.imagesToLoad.length)*100
                        
                        
                            
                    }
                }

            })
            

            
            
            
            
  
        },
        methods: {
            goToHome(){

                var elem = window.getComputedStyle(document.querySelector("#home-chris-non-change"))
                //gsap.config({force3D: false});
                //gsap.ticker.lagSmoothing(0);

                
//                gsap.ticker.fps(-1)
                
                    
                    
                    gsap.to('.chris-logo-container', {
                        ease: "power2.out",
                        left: Math.round(elem.left.replace('px', '')), 
                        top: Math.round(elem.top.replace('px', '')), 
                        width: Math.round(elem.width.replace('px', '')), 
                        height: Math.round(elem.height.replace('px', '')), 
                        duration:1.5, delay:0.95})

                    var tl = gsap.timeline()
                
                    tl.set('.slider-content', {filter: 'blur(3px)'})
                    tl.to('.circle', {clipPath:'circle(62.5% at -55% -55%)', ease: "slow(0.7, 0.7, false)", duration:3, onComplete:()=>{
                        tl.set('.loader-container', {autoAlpha: 0})
                        tl.set('.home-chris', {autoAlpha: 1})
                        this.$emit('loadComplete')
                    }})
                    .to('.img-quote-container', {scale:0, ease: "slow(0.7, 0.7, false)", duration: 2.25}, "-=3")
                    .to('.loader-overlay', {autoAlpha: 0, duration: 3}, "-=3")
                    
                    
                    .to('.slider-content', {filter: 'blur(0px)', duration: 3}, "-=2")
                    
                
                
                
            }
        },
        mounted() {
            
            
            

            
            
            
        }

    }
</script>