<template>
    <div class ="container">
        <div class ="slider-content" ref="sliderData">
            <slot > </slot>
        </div>
        <!--
        <div class="arrow-safe-distance arrow-safe-distance-right arrow-bio-safe-distance-right arrow-bio visible-landscape" 
            ref="nextArrow" @click="next()" @mouseover="hover(1)" @mouseleave="leave(1)">
            <div class="arrow-container next-arrow arrow-btn" >
                
            </div>
        </div>

        <div class="arrow-safe-distance arrow-safe-distance-left arrow-bio-safe-distance-left arrow-bio visible-landscape" 
            ref="preArrow" @mouseover="hover(0)" @mouseleave="leave(0)" @click="prev()">
            <div class="arrow-container prev-arrow arrow-btn" >
                
            </div>
        </div>-->
        
        <div class="bullets bio-bullets" ref="sliderBullets">
            <div class="active" @click="this.goto(0)">&bull;</div>
            <div  @click="this.goto(1)">&bull;</div>
            <div  @click="this.goto(2)">&bull;</div>
        </div>
    </div>
</template>

<script>
    import gsap from 'gsap'
    import CSSRulePlugin from 'gsap/CSSRulePlugin'
    gsap.registerPlugin(CSSRulePlugin);

    export default {
        name: "slider",
        props: ['color'],
        data: () => ({
            items: [],
            bullets: [],
            actual: 1,
            isInProgress: false
        }),
        computed: {
            arrowColor(){
                return this.color
            }
            
        },
        mounted() {
            
            this.items = this.$refs.sliderData.children;
            
            this.bullets = this.$refs.sliderBullets.children
            
            
            
            this.actual = 0
            //gsap.to(this.$refs.nextArrow, {"--color": this.arrowColor})
            //gsap.to(this.$refs.preArrow, {"--color": this.arrowColor})
        },
        methods: {
            hover(){
                /*var arrow = '.arrow-bio-safe-distance-left'
                if(index == 1) arrow = '.arrow-bio-safe-distance-right'
                gsap.to(arrow, {autoAlpha: 0.75})*/
            }, 
            leave(){
                /*var arrow = '.arrow-bio-safe-distance-left'
                if(index == 1) arrow = '.arrow-bio-safe-distance-right'
                gsap.to(arrow, {autoAlpha: 0.5})*/
            },
            goto: function (val) {
                
                if(this.isInProgress) return
                
                this.isInProgress = true
                var before = this.actual;
                var after = val;

                this.$emit('changeTo', before, after)

                
            },
            updateStatus(before, after){
                this.bullets[before].classList.remove('active')
                this.bullets[after].classList.add('active')

                this.actual = after
            },
            prev: function () {
                var temp = this.actual - 1
                if(temp<0) temp = this.items.length-1
                this.goto(temp)
            },
            next: function () {
                
                var temp = this.actual + 1
                if(temp>=this.items.length) temp = 0
                this.goto(temp)
            },


 


            restart(){
                this.bullets[0].classList.add('active')
                this.bullets[1].classList.remove('active')
                this.bullets[2].classList.remove('active')
                this.actual = 0
            },

        }
    }
</script>

<style>

.visible{
    visibility: visible;
  }
  .hide{
    visibility: hidden;
    opacity: 0;
  }


.slide-visible{
    visibility: visible;
}
.slider-content{
    width: 100%;
    height: 100%;
    position: absolute;
    /*background-color: blue;*/
}

.bio-bullets div{
    color: #fff !important;
    opacity: 0.5;
}

.bio-bullets div:hover{
    color: #fff !important;
    opacity: 1;
}

.bio-bullets div.active{
    color: #fff !important;
    opacity: 1;
}


  
  .arrow-bio{
      opacity: 0.5;
  }

  .arrow-bio:hover{
      opacity: 0.75;
  }

</style>