<template>
    <project ref="project"
        classContainer="nfi-container" 
        classOverlay="overlay-replatform"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>

    <!--<img class="example" src="@/assets/nfi/home1.jpg" />-->
</template>

<script>
import Project from '../../Project.vue'
    
    export default {
        components: {
                Project 
        }, 
        name: "nfi",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-nfi",
                text: "<p>"+
                            "Many times, like in this case with NFI,<br/>"+ 
                            "companies want to evolve their brand<br/>"+
                            "as part of the replatform and redesign<br/>" + 
                            "effort. NFI is an innovative logistics<br/>"+
                            "company fueled by technology and<br/>"+
                            "analytics with robots patrolling their<br/>"+
                            "ultra-modern warehouses, but also<br/>"+
                            "are an independent, family-owned<br/>"+
                            "company with a great, warm culture<br/>"+
                            "so the new site experience was<br/>"+
                            "designed to find that balance.<br/>"+
                        "</p>",
                title:"Replatform & Redesign",
                textSlice:8,
                fontSizeInnerText:"0.79rem",
                marginLeft:"-5rem",
                fontSize:"2rem", 
                marginTop:"5%",
                color:"#463e2d",
                colorOverlay:"#fff",
                colorText:"#463e2d"
            },
            bubbleTwoData:{
                class:"bubble-two-nfi",
                text:   "<p>BRANDING & CONCEPTING</p><br/>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL, UI DESIGN & <br/>MODULAR DESIGN</p><br/>"+
                        "<p>ANIMATION & MOTION</p>",
                color:"#463e2d",
                colorOverlay:"#867656",
                colorText:"#fff"
            },
            previewOneData:{
                class:"nfi-preview-1",
                img:"assets/nfi/nfi_slide_2.jpg",
                upDown:true,
                forward:true,
                isBrik: false
            },
            previewTwoData:{
                class:"nfi-preview-2",
                img:"assets/nfi/nfi_slide_3.jpg",
                upDown:true,
                forward:true,
                isBrik: false,
            },
            previewThreeData:{
                class:"nfi-preview-3",
                img:"assets/nfi/nfi_slide_1.jpg",
                upDown:true,
                forward:false,
                isBrik: false
            },
            nBubbles: ['.bubble-one-nfi', '.bubble-two-nfi'],
            previews: ['.nfi-preview-1', '.nfi-preview-2', '.nfi-preview-3'],
            replatformDetailOne: "<p>"+
                                "Many times, like in this case with NFI,<br/>"+ 
                                "companies want to evolve their brand<br/>"+
                                "as part of the replatform and redesign<br/>" + 
                                "effort. NFI is an innovative logistics<br/>"+
                                "company fueled by technology and<br/>"+
                                "analytics with robots patrolling their<br/>"+
                                "ultra-modern warehouses, but also<br/>"+
                                "are an independent, family-owned<br/>"+
                                "company with a great, warm culture<br/>"+
                                "so the new site experience was<br/>"+
                                "designed to find that balance.<br/>"+
                            "</p>",
            replatformDetailTwo: "<p>BRANDING & CONCEPTING</p><br/>"+
                                "<p>CREATIVE DIRECTION</p><br/>"+
                                "<p>DIGITAL, UI DESIGN & <br/>MODULAR DESIGN</p><br/>"+
                                "<p>ANIMATION & MOTION</p>"
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .nfi-preview-1{
        width: 22vw;
        height: 26vw;
        top: 32%;
        left: 51%;
    }

    .nfi-preview-2{
        width: 6.5vw;
        height: 13.5vw;
        top: 33%;
        left: 77%;
    }

    .nfi-preview-3{
        width: 32vw;
        height: 28vw;
        top: 20%;
        left: 20%;
    }

    .bubble-two-nfi{
        left: 17%;
        top: 68.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-nfi{
        left: 72.5%;
        top: 58.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .nfi-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-nfi {
            top: 60.5%;
            left: 42.5%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-nfi{
            top: 20.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .nfi-preview-1{
            width: 42vw;
            height: 47vw;
            top: 32%;
            left: 48%;
        }

        .nfi-preview-2{
            width: 13vw;
            height: 27vw;
            top: 45%;
            left: 82%;
        }

        .nfi-preview-3{
            width: 54vw;
            height: 49vw;
            top: 33%;
            left: 3%;
        }
    }
</style>