<template>
    
    <div class="bubble-one-container" ref="bubble" @mouseenter="hover()" @mouseleave="leave()">
        <div class="bubble-one-background" ref="backgroundColor" /> 
        <div class="bubble-one-text-container" ref="textBackground"> 
            <div v-html="this.text" class="text-bubble-one" ref="textContainer" />
        </div>
    </div>
    
</template>

<style>


    .bubble-one-container{
        position: absolute;
        
    }
    
    .bubble-one-background, .bubble-one-text-container{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 99%;
        left: 0;
        top: 0;
    }
    
    .bubble-one-background{
        opacity: 0.8;
    }

    .bubble-one-text-container{
        position: absolute;
        background-color: var(--colorBackgroundText);
        font-family: 'chrisFont';
        visibility: hidden;
        opacity: 0;
    }

    .text-bubble-one{
        position: absolute;
        top: 50%;
        font-size: var(--fontSize);
        display: block;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        text-align: center;
        line-height: 1.2rem;
    }

.text-bubble-one p{
    margin: 0;
    line-height: 1.2rem;
}


    .title-bubble-one{
        color: var(--color);
        font-family: 'quotefont';
        position: absolute;
        font-size: var(--fontSize);
        margin-left: var(--marginLeft);
        top:10%;
        

    }
    
    .title-bubble-one span{
        text-shadow: black 1px 1px 1px;
    }
        
    @media (orientation: portrait) {
        .text-bubble-one p{
            line-height: 4.2rem;
        }
    }

  

</style>

<script>
    import gsap from 'gsap'
    export default {
        name: "bubbleOne",
        props:{
            color : String,
            colorOverlay: String,
            colorText: String,
            text: String,
            title: {type:String, default :'Connected Devices'},
            onHover: Object,
            onLeave: Object,
            direction: { type: Number, default: -1 },
            fontSize: {type: String, default: '2rem'},
            textSlice:{type:Number, default: 4},
            marginLeft: {type: String , default: '-1.8rem'},
            marginTop: {type: String , default: '0'},

            fontSizeInnerText: {type: String, default: '1rem'},
            

        },
        data: () => ({
            pulseAnimation: '',
            isLeaving: false,
            isHover: false,
            hDirection: 1,
            vDirection: 1,
            animationH: undefined,
            animationV:undefined,
            animationR:undefined,
            hoverAnimation: undefined
       }),
        created() {
            window.addEventListener("resize", this.fontSizeUpdate);
        },
        computed: {
  
           
            
        },
        methods: {
            pauseAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.pause()
                if(this.animationV!==undefined)
                    this.animationV.pause()
                if(this.animationR!==undefined)
                    this.animationR.pause()
            },
            resumeAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.resume()
                if(this.animationV!==undefined)
                    this.animationV.resume()
                if(this.animationR!==undefined)
                    this.animationR.resume()
            },
            createHoverAnimation(){
                gsap.ticker.lagSmoothing(0);
                var tl = gsap.timeline().paused(true)
                tl  .set(this.$refs.bubble, {zIndex: 1})
                    //.to(this.$refs.bubble, {rotate: 0, ease: "sine.inOut", overwrite: 'auto', duration:1})
                    .fromTo(this.$refs.bubble, {scale: 0.4}, {scale: 1, duration:0.5})
                    .to(this.$refs.textBackground, {autoAlpha: 1, duration:0.25})
                return tl
            },
            hover(){
                this.isHover = true
                this.pauseAnimations()
                this.hoverAnimation.play()
                this.$emit('bubbleHover')
            },
            leave(){
                this.hoverAnimation.reverse()
                this.resumeAnimations()
                this.$emit('bubbleLeave')
            },
            random(min, max) {
                var delta = max - min;
                var r = (min + delta * Math.random());
                
                return r
            },
            moveX( ) {
                this.hDirection *= -1
                var distance = this.random(1, 18) * this.hDirection
                var dur = this.random(3, 5)
                gsap.ticker.lagSmoothing(0);
                this.animationH = gsap.to(this.$refs.bubble, {x: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveX()
                }})
            },
            moveY( ) {
                this.vDirection *= -1
                var distance = this.random(1, 18) * this.vDirection
                var dur = this.random(3, 5)
                gsap.ticker.lagSmoothing(0);
                this.animationV = gsap.to(this.$refs.bubble, {y: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveY()
                }})
            },
            rotate( ) {
                
                /*var angle = this.random(-3, 3)
                var dur = this.random(5, 10)
                this.animationR = gsap.to(this.$refs.bubble, {rotate: angle, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.rotate()
                }})*/
            },
            pulse(){
                this.hDirection = this.actualDirection
                this.VDirection = this.actualDirection
                this.moveX()
                this.moveY()
                this.rotate()
            },
            pause(){
                if(this.isLeaving) return
                //this.pulseAnimation.pause()
            },
            play(){
                if(this.isLeaving) return
                //this.pulseAnimation.play()
            },
            calcLandscapeFont(val){
                
                val = val.replace('rem', '')
                var elem = document.getElementById("landscape")
                
                if(window.getComputedStyle(elem).display=='none')
                    val *= 3.3

                return val+'rem';
                
            },
            fontSizeUpdate(){
                gsap.set(this.$refs.textContainer, {color: this.colorText, '--fontSize': this.calcLandscapeFont(this.fontSizeInnerText)/*, '--marginTop': this.marginTop*/})
                //gsap.set(this.$refs.tittle, {'--fontSize': this.calcLandscapeFont(this.fontSize), '--marginLeft': this.calcLandscapeFont(this.marginLeft)})
            }
        },
        mounted() {
            //this.pulseAnimation = this.pulse()
            this.fontSizeUpdate()
            //gsap.set(this.$refs.titleBegin, {color: this.colorOverlay})
            gsap.set(this.$refs.textBackground, {backgroundColor: this.colorOverlay})
            gsap.set(this.$refs.backgroundColor, {backgroundColor: this.color})
            
            gsap.set(this.$refs.bubble, {scale: 0.4})
            this.hoverAnimation = this.createHoverAnimation() 
            this.pulse()
            this.pauseAnimations()
            //this.hover()
        }

    }
</script>