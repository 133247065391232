<template>
    <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import WBGameVue from './game/WB.vue'
import SR3Vue from './game/SR3.vue'
import THQVue from './game/THQ.vue'

export default { 
    name: "gameSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'game-slider-container',
        classOverlay: 'game-crop-overlay',
        classSlider: 'game-background',
        classCloseBtn: 'close-btn-game',
        logos:[
          {
            path:require('@/assets/wb/logo.png'),
            class: 'wb-logo'
          },
          {
            path:require('@/assets/sr3/logo.svg'),
            class: 'sr3-logo'
          },
          {
            path:require('@/assets/thq/logo.svg'),
            class: 'thq-logo'
          }
        ],
        slides:[<WBGameVue/>, <SR3Vue/>, <THQVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>

  .wb-logo{
    top: 7% !important;
    left: 6vw !important;
    height: 14vh !important;
    width: auto;
  }

  .sr3-logo{
    top: 7% !important;
    left: 6vw !important;
    height: 14vh !important;
    width: auto;
  }

  .thq-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 9% !important;
  }
  .game-background{
    background-image: url('~@/assets/wb/background.jpg');

  }
  
  @media (orientation: portrait) {
  .thq-logo{
    height: 10vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
  .wb-logo{
    height: 17vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;

  }
  .sr3-logo{
    height: 17vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
}
</style>