<template>
    <project ref="project"
        classContainer="wawa-container wawa-3" 
        classOverlay="overlay-wawa"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    <!--<img class="example" src="@/assets/wawa/home3.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'

    export default {
        components: {
                Project 
        }, 
        name: "wawaThree",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-wawa-3',
                text: "<p>"+
                            "Wawa in general and Wawa Coffee in<br/>"+ 
                            "particular is a religion for some folks<br/>"+
                            "so introducing a new coffee cup<br/>" + 
                            "design is nothing short of a big deal.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "We presented the new cups but also<br/>"+
                            "provided some deeper content about<br/>"+
                            "the different coffees and how they’re<br/>"+
                            "sourced.<br/>"+
                        " </p>",
                title:"Digital Retainer",
                fontSizeInnerText:"0.79rem",
                marginLeft:"-1.5rem",
                marginTop:"4%",
                color:"#751420",
                colorOverlay:"#fff",
                colorText:"#751420"
            },
            bubbleTwoData:{
                class:  'bubble-two-wawa-3',
                text:   "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL DESIGN</p>",
                color:"#751420",
                colorOverlay:"#cd2337",
                colorText:"#fff"
            },
            previewOneData:{
                class:"wawa-3-preview-1",
                img:"assets/wawa/wawa3_slide_2.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"wawa-3-preview-2",
                img:"assets/wawa/wawa3_slide_3.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            previewThreeData:{
                class:"wawa-3-preview-3",
                img:"assets/wawa/wawa3_slide_1.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .wawa-3-preview-1{
        width: 21vw;
        height: 27vw;
        top: 20%;
        left: 30%;
    }

    .wawa-3-preview-2{
        width: 7.5vw;
        height: 16vw;
        top: 31%;
        left: 20%;
    }

    .wawa-3-preview-3{
        width: 32vw;
        height: 23vw;
        top: 21%;
        left: 49%;
    }

    .bubble-two-wawa-3{
        left: 26%;
        top: 68.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-wawa-3{
        left: 66.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
    
    .wawa3-overlay{
        background-color: #751420;
        opacity: 0.7;
        position: absolute;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity:0;
    }

    .wawa3-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-wawa-3 {
            left: 38.5%;
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-wawa-3{
            left: 55%;
            top: 17.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .wawa-3-preview-1{
            width: 50vw;
            height: 42vw;
            left: 12%;
            top: 36%;
        }

        .wawa-3-preview-2{
            width: 16vw;
            height: 34vw;
            top: 43%;
            left: 7%;
        }

        .wawa-3-preview-3{
            width: 46vw;
            height: 46vw;
            top: 31%;
            left: 51%;
        }
    }

    
</style>