<template>
    <project  ref="project"
        classContainer="brink-container brink-3" 
        classOverlay="overlay-brink"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :videoOneData="this.videoOneData"
        :previewOneData="this.previewOneData"/>
    
    <!--<img class="example" src="@/assets/brink/home3.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'

    export default {
        components: { Project }, 
        name: "brinkThree",
        data: () => ({
            ghostVideo: false,
            bubbleOneData: {
                class: 'bubble-one-brink-3',
                text: "<p>We created a clean UI with a robust<br/>data visualization system that made it<br/>"+
                            "easy for players to do everything from<br/> switch teams and dig into their stats. </p><br/>"+
                            "<p>Bethesda was thrilled with the work,<br/> saying it was the best they’d seen in<br/>"+
                            "their collective experience. The site<br/> also won a Pixel Award.</p>",
                title: "Data Viz", fontSizeInnerText:"0.85rem", marginLeft:"-2rem",
                marginTop:"2%", color:"#14677b", colorOverlay:"#fff", colorText:"#14677b"
            },
            videoOneData:{
                class: 'brink-3-video',
                videoUrl: 'assets/brink/home3-left.mp4',
                poster: 'assets/brink/brink3-cover.jpg',
                isUnitedCenter: false,
                onAxisY:false
            },
            previewOneData:{
                class: 'brink-3-preview',
                img:"assets/brink/brink_slide_3.jpg",
                isBrik: true,
                forward:true,
                upDown:true
            },
        }),
         methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .brink-3-preview{
        width: 41vw;
        height: 25vw;
        top: 22%;
        left: 37%;
    }
    
    .brink-3-video{
        top: 28.75% !important;
        left: 16.75% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .bubble-one-brink-3{
        left: 26.5%;
        top: 60.5%;
        height: 17.5vw;
        width: 17.5vw;
    }

    @media (orientation: portrait) {
        .bubble-one-brink-3 {
            left: 10.5%;
            top: 60.5%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-brink-3{
            left: 64.75%;
            top: 19.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .brink-3-video{
            top: 35% !important;
            left: 1% !important;
            width: 39vw !important;
            height: 22vw !important;
        }

        .brink-3-preview{
            width: 80vw;
            height: 64vw;
            top: 31%;
            left: 15.5%;
        }
    }

</style>