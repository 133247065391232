<template>
    <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import VizioOneVue from './vizio/VizioOne.vue'
import VizioThreeVue from './vizio/VizioThree.vue'
import VizioTwoVue from './vizio/VizioTwo.vue'

export default { 
    name: "VizioSlider",
    components: {
        Chapter
    },
    data: () => ({
        sliderData:{
          classContainer: 'vizio-slider-container',
          classOverlay: 'vizio-crop-overlay',
          classSlider: 'vizio-background',
          classCloseBtn: 'close-btn-vizio',
          logos:[
            {
              path:require('@/assets/vizio/logo.svg'),
              class: ''
            }
          ],
          slides:[<VizioOneVue/>, <VizioTwoVue/>, <VizioThreeVue/>]
        }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .vizio-background{
    background-image: url('~@/assets/vizio/background.jpg');

  }
  

  
</style>