<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import BedlandVue from './agency/Bedland.vue'
import BonteousVue from './agency/Bonteous.vue'
import DustlandVue from './agency/Dustland.vue'

export default { 
    name: "agencySlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'agency-slider-container',
        classOverlay: 'agency-crop-overlay',
        classSlider: 'agency-background',
        classCloseBtn: 'close-btn-agency',
        logos:[
          {
            path:require('@/assets/bedland/logo.png'),
            class: 'bedland-logo'
          },
          {
            path:require('@/assets/bonteous/logo.svg'),
            class: 'bonteous-logo'
          },
          {
            path:require('@/assets/dustland/logo.png'),
            class: 'dustland-logo'
          }
        ],
        slides:[<BedlandVue/>, <BonteousVue/>, <DustlandVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .agency-background{
    background-image: url('~@/assets/agency/background.jpg');

  }
  .bedland-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 10.5% !important;
  }
  .bonteous-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 15.5% !important;
  }
  .dustland-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 17.5% !important;

  }
  
  @media (orientation: portrait) {
  .bedland-logo {
      height: 14vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
  .bonteous-logo {
      height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }

  .dustland-logo {
    height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important; 
    width: auto !important;
  }
}
  
</style>