<template>
    <project ref="project"
        classContainer="chase-container" 
        classOverlay="overlay-financial"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>

    <!--<img class="example" src="@/assets/chase/home1.jpg" />-->
    <!--<img class="example" src="@/assets/hatford/hatford-portrait.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    export default {
        components: {Project  }, 
        name: "chase",
        data: () => ({
             bubbleOneData: {
                class: 'bubble-one-chase',
                text: "<p>"+
                            "Chase has a number of different<br/>"+ 
                            "brands I’ve had the opportunity to<br/>"+
                            "work with. Across the board though,<br/>" + 
                            "working with Chase I was able to<br/>"+
                            "leverage my e-commerce experience<br/>"+
                            "(from working at eToys.com,<br/>"+
                            "walmart.com and gsi commerce and<br/>"+
                            "beyond) which is always a huge plus<br/>for me."+
                        " </p>",
                title:'Financial', 
                fontSizeInnerText:"0.85rem",
                marginLeft:"-1.45rem",
                marginTop:"4%",
                color:"#465054",
                colorOverlay:"#fff",
                colorText:"#465054"
            },
            bubbleTwoData:{
                class:  'bubble-two-chase-1',
                text:   "<p>BRANDING</p><br/>"+ 
                        "<p>CREATIVE DIRECTION & DIGITAL DESIGN & UI DESIGN</p><br/>" +
                        "<p>OMNI-CHANNEL DESIGN: DM,<br/> EMAIL & SITE</p><br/>"+
                        "<p>E-COMMERCE DESIGN</p>",
                color:"#465054",
                colorOverlay:"#74848b",
                colorText:"#fff"
            },
            previewOneData:{
                class: 'chase-1-preview',
                img:"assets/chase/chase_slide_2.jpg",
                isBrik: false,
                forward:false,
                upDown:true
            },
            previewTwoData:{
                class:"chase-2-preview",
                img:"assets/chase/chase_slide_3.jpg",
                isBrik: false,
                forward:false,
                upDown:true
            },
            previewThreeData:{
                class:"chase-3-preview",
                img:"assets/chase/chase_slide_1.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-financial{
        background-color: #44555C;
    }

    .chase-3-preview{
        width: 37vw;
        height: 22vw;
        top: 24%;
        left: 44%;
    }

    .chase-2-preview{
        width: 8vw;
        height: 16vw;
        top: 37%;
        left: 19%;
    }

    .chase-1-preview{
        width: 17vw;
        height: 29vw;
        top: 26%;
        left: 28%;
    }

    .bubble-two-chase-1{
        left: 14.5%;
        top: 63.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-chase{
        left: 64.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .chase-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-chase {
            left: 40.5%;
            top: 64.75%;
            height: 57vw;
            width: 57vw;
        }
        .bubble-two-chase-1{
            left: 35.5%;
            top: 21.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .chase-3-preview{
            width: 61vw;
            height: 44vw;
            top: 37%;
            left: 39%;
        }

        .chase-2-preview{
            width: 16vw;
            height: 32vw;
            top: 43%;
            left: 5%;
        }

        .chase-1-preview{
            width: 34vw;
            height: 58vw;
            top: 34%;
            left: 18%;
        }
    }

</style>