<template>
    <project ref="project"
        classContainer="wawa-container wawa-3" 
        classOverlay="overlay-wawa"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewSliderOneData="this.previewSliderOneData"/>
    <!--<img class="example" src="@/assets/wawa/home2.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'

    export default {
        components: {
            Project 
        }, 
        name: "wawaTwo",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-wawa-1',
                text: "<p>"+
                            "From promoting a Wawa Beer Tour to<br/>"+ 
                            "selling gift cards, there was always a<br/>"+
                            "diverse list of projects to sink your<br/>" + 
                            "teeth into, and a large team to partner<br/>" + 
                            "with to get them done.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "One of my favorites was working with<br/>"+
                            "illustrators to design and promote<br/>"+
                            "Wawa’s new Gift Cards."+
                        " </p>",
                title:"Digital Retainer",
                fontSizeInnerText:"0.79rem",
                marginLeft:"-1.5rem",
                marginTop:"4%",
                color:"#751420",
                colorOverlay:"#fff",
                colorText:"#751420"
            },
            bubbleTwoData:{
                class:  'bubble-two-wawa-1',
                text:   "<p>BRANDING</p><br/>"+
                        "<p>CONCEPTING & CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL DESIGN & CARD <br/>DESIGN</p><br/>"+
                        "<p>E-COMMERCE DESIGN</p>",
                color:"#751420",
                colorOverlay:"#cd2337",
                colorText:"#fff"
            },
            previewOneData:{
                class:"wawa-2-preview-1",
                img:"assets/wawa/wawa2_slide_2.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"wawa-2-preview-2",
                img:"assets/wawa/wawa2_slide_1.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            previewSliderOneData:{
                class:"wawa-2-video",
                images:['assets/wawa/2_Wawa_Screen_1.jpg', 'assets/wawa/2_Wawa_Screen_2.jpg', 'assets/wawa/2_Wawa_Screen_3.jpg']
            },
            nBubbles: ['.bubble-one-wawa-2', '.bubble-two-wawa-2'],
            previews: ['.wawa-2-preview-1', '.wawa-2-preview-2', '.wawa-2-video'],
            wawaDetailOne: "<p>"+
                                "From promoting a Wawa Beer Tour to<br/>"+ 
                                "selling gift cards, there was always a<br/>"+
                                "diverse list of projects to sink your<br/>" + 
                                "teeth into, and a large team to partner<br/>" + 
                                "with to get them done.<br/>"+
                            "</p><br/>"+
                            "<p>"+
                                "One of my favorites was working with<br/>"+
                                "illustrators to design and promote<br/>"+
                                "Wawa’s new Gift Cards."+
                            " </p>",
            wawaDetailTwo: "<p>BRANDING</p><br/>"+
                            "<p>CONCEPTING & CREATIVE DIRECTION</p><br/>"+
                            "<p>DIGITAL DESIGN & CARD DESIGN</p><br/>"+
                            "<p>E-COMMERCE DESIGN</p>"
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .wawa-2-preview-1{
        width: 26vw;
        height: 22vw;
        top: 30%;
        left: 52%;
    }

    .wawa-2-preview-2{
        width: 33vw;
        height: 25vw;
        top: 21%;
        left: 20%;
    }

    .wawa-2-video{
        top: 59% !important;
        left: 66% !important;
        width: 14.9vw !important;
        height: 9vw !important;
    }
    .bubble-two-wawa-2{
        left: 56%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-wawa-2{
        left: 27%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
    


    .wawa2-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-wawa-2 {
            height: 57vw;
            width: 57vw;
            top: 63.75%;
        }

        .bubble-two-wawa-2{
            top: 20.5%;
            height: 42.5vw;
            width: 42.5vw;
        }
        .wawa-2-preview-1{
            width: 39vw;
            height: 33vw;
            top: 34%;
            left: 52%;
        }

        .wawa-2-preview-2{
            width: 49.5vw;
            height: 37.5vw;
            top: 33%;
            left: 7%;
        }

        .wawa-2-video{
            top: 59% !important;
            left: 66% !important;
            width: 29.8vw !important;
            height: 18vw !important;
        }
    }

</style>