<template>
    <div :class="'slider-container ' + this.sliderData.classContainer" ref="slideContainer" >
        
      <div :class="'crop-overlay '+ this.sliderData.classOverlay" ref="cropOverlay" />

      <div class="slider-container-crop" ref="slideCrop">
        
        <slider :class="'slider-background '+ this.sliderData.classSlider" color="#fff" ref="slider"
            @arrowHover="this.pauseProject()" @changedTo="this.animateSlide" @arrowLeave="this.playProject()">
            <component :is="this.sliderData.slides[0]" ref="slideOne" class="slider-content to-blur0" v-touch:swipe="changeProjectTo"/>
            <component :is="this.sliderData.slides[1]" ref="slideTwo" class="slider-content to-blur1" v-touch:swipe="changeProjectTo"/>
            <component :is="this.sliderData.slides[2]" ref="slideThree" class="slider-content to-blur2" v-touch:swipe="changeProjectTo"/>
        </slider>

        <img v-for="(logo, index) in this.sliderData.logos" :key="logo" :class="logo.class +' logo ' + this.showLogo(index)" :src="logo.path" />

        
        <div class="h1-close-btn-0 visible-portrait" @click="pretendToClose()">
          <div class="close-btn"/>
        </div>
        <div class="h1-close-btn-2" ref="closeBtn" @mouseover="this.pauseProject()" @mouseleave="this.playProject()">
          <div :class="'close-btn '+this.sliderData.classCloseBtn " @click="close()" />
        </div>
      </div>
      
    </div>
    
</template>

<script>
import Slider from './Slider.vue'
import gsap from 'gsap'


export default { 
    name: "VizioSlider",
    components: {
        Slider
    },
    props:{
        sliderData: {type: Object, default: undefined}
    },
    data: () => ({
        slides : [],
        tlCrop: undefined,
        isAnimating: false,
        isMaked: false,
        slideActive: 0,
    }),
    computed:{
       
    },

    methods:{
        getIsMasked(){
            return this.isMaked
        },
    showLogo(index){
        
        if(this.sliderData.logos.length==1) return 'showLogo'
        
        if(this.slideActive==index)
            return 'showLogo'
        
        return ''
    },
      changeProjectTo(direction){
        
        if(direction=='left')
          this.$refs.slider.next()
        else if(direction=='right')
          this.$refs.slider.prev()
      },
      pauseProject(){
        this.$refs.slider.showSliderOverlay()
        this.slides[this.$refs.slider.actual].pause()
        
      },
      playProject(){
        this.$refs.slider.hideSliderOverlay()
        
        this.slides[this.$refs.slider.actual].play(true)
        
      },
      open(openFromHome){
        
          this.isAnimating = true
          gsap.delayedCall(openFromHome?4:1, ()=>{
            //console.log('IM OVER IN CHAPTER')
            this.isAnimating = false
          })
        
        
        this.slides[this.$refs.slider.actual] .openNonAnimated()
        gsap.set('.project-btn', {autoAlpha: 1})
        gsap.set('.'+this.sliderData.classContainer, {display: 'block'})
        gsap.set(this.$refs.closeBtn, {zIndex: 2})
        
        gsap.set(this.$refs.slideContainer.parentElement, {autoAlpha: 1})
        gsap.set(this.$refs.slideContainer, {autoAlpha: 1, zIndex: 2, onComplete:()=>{
          this.$refs.slider.hideSliderOverlay()
          this.unBlur()
          gsap.delayedCall(3, ()=>{
            this.slides[this.$refs.slider.actual] .play()
          })
          
        }})
        
          
      },
      
      close(){
        if(this.isAnimating) return

        
        
        if(this.slides[this.$refs.slider.actual].isHovering()) return
        
        this.$emit('sliderClose')    

        var tl = gsap.timeline()
        tl.set('.project-btn', {autoAlpha: 0})
        tl.set(this.$refs.slideContainer, {zIndex: 1})
        gsap.set('.'+this.sliderData.classOverlay, {'--color': 'unset', autoAlpha: 0})
        tl.fromTo(this.$refs.slideCrop, {clipPath: 'circle(100%)'}, {clipPath: 'circle(0%)', duration: 1.5, onComplete:()=>{
          gsap.set('.detail-project ', {display: 'none'})
          gsap.set(this.$refs.slideCrop, {clipPath: 'unset'})
          gsap.set(this.$refs.slideContainer, {zIndex: 'unset', display: 'none'})
        }})
        
      },
      animateSlide(before, after){
        
        this.slides[before].close()
        this.slides[after].open(()=>{
          this.$refs.slider.isAnimating = false
        }, true)
        this.slideActive = after
      },
      mask(color){
        this.isMaked = true
        gsap.set('.'+this.sliderData.classOverlay, {'--color': color, autoAlpha: 1})
        var tl = gsap.timeline()
        tl.fromTo(this.$refs.slideCrop, {clipPath:'circle(100% at center)'}, {clipPath:'circle(55% at center)', duration: 1})
        this.tlCrop = tl
      },

      maskLeave(kill){
        if(kill){
            this.tlCrop.kill()

        } else if(this.tlCrop) this.tlCrop.reverse()
        this.isMaked = false
      },
      maskBackground(){
        gsap.set(this.$refs.slideContainer, {autoAlpha: 1, zIndex: 1})
      },
      maskBackgroundLeave(){
        gsap.set(this.$refs.slideContainer, {autoAlpha: 0, zIndex: 0})
      },
      unBlur(){
        var page = '.to-blur'+this.$refs.slider.actual
        //console.log('unblur: ' + page)
        gsap.set(page, {filter: 'blur(0px)'})
        gsap.set('.showLogo', {filter: 'blur(0px)'})
      },
      blur(){
        var page = '.to-blur'+this.$refs.slider.actual
        //console.log('blur: ' + page)
        gsap.set(page, {filter: 'blur(3px)'})
        gsap.set('.showLogo', {filter: 'blur(3px)'})
        
      },
      display(){
        gsap.set('.'+this.sliderData.classContainer, {display: 'block', zIndex:1})
      },
      disapearTo(onComp){
        
        var tl = gsap.timeline()
        
        tl.to('.'+this.sliderData.classOverlay, {autoAlpha: 0.9, duration: 0.5})
        tl.to(this.$refs.slideCrop, { clipPath:'circle(0% at center)', duration: 1.5})
        tl.to('.'+this.sliderData.classOverlay, {autoAlpha: 0.2, duration: 1.5}, '-=1.5')
        tl.to('.'+this.sliderData.classOverlay, {autoAlpha: 0, duration: 0.5 , onComplete:()=>{
          gsap.set(this.$refs.slideContainer, {autoAlpha: 0, zIndex: 0})
          if(onComp !==undefined)
            onComp()

          gsap.set('.'+this.sliderData.classContainer, {display: 'none'})
          gsap.set(this.$refs.slideCrop, {clipPath:'circle(100% at center)'})
          gsap.set('.'+this.sliderData.classOverlay, {autoAlpha: 1})
        }})
        
      },
      pretendToClose(){
        if(this.slides[this.$refs.slider.actual].closeMainVideo)
          this.slides[this.$refs.slider.actual].closeMainVideo()
      }
    },
    mounted(){
      this.slides = [this.$refs.slideOne, this.$refs.slideTwo, this.$refs.slideThree]

      gsap.set('.'+this.sliderData.classContainer, {display: 'none'})
      this.pauseProject()
    }
}
</script>

<style>
    .showLogo{
        display: block;
    }
</style>