<template>
    
    <div class="bubble-principal-container" ref="bubble">
        
        <div class="bubble-principal-data-container" ref="innerBubble"
            @mouseover="hover()" @mouseleave="leave()" @click="onClick()">
            <div class="bubble-img-container" ref="img">
                <img class="bubble-principal-img" :src="require('@/'+this.img)" />
            </div>
            
            <div class="bubble-dim-overlay" ref="dimOverlay"> </div>
            <div class="bubble-principal-overlay" :style="layerOneCss" ref="overlay"> </div>
            <div class="bubble-principal-info-container" ref="info">
                 <div class="bubble-logo-container"> 
                        <div class="bubble-logo-imgs" ref="imgContainers"> 
                            <img v-for="(logo, index) in this.logos" :key="logo" :ref="'img' + index" :src="require('@/'+logo)" />
                        </div>
                        
                    </div>
                    <div class="bubble-principal-whiteline"/>
                    <div class="bubble-principal-text-container" ref="textContainer"> 
                        <div class="bubble-principal-text">
                            <div class="bubble-principal-text-second text-view" ref="textView">{{this.viewText}}</div>
                            <div class="bubble-principal-title" ref="textTitle">{{this.text}}</div>
                            <div class="bubble-principal-text-second text-project" ref="textProject">{{this.projectText}}</div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    
</template>

<style>

.bubble-principal-container{
    will-change: all;
}

    .bubble-dim-overlay{
        background-color: #000;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 99%;
        visibility: hidden;
    }
    
    .bubble-principal-whiteline{
        position: absolute;
        left: 10%;
        top: 50%;
        width: 80%;
        
        height: 3px;
        background-color: #fff;
    }

@media (max-width: 740px) {
    .bubble-principal-whiteline{
        height: 1px;
    }
}

    .bubble-logo-imgs{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .bubble-logo-imgs img:nth-child(1){
        width: var(--width);
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: var(--bottom);
        will-change: all;
    }

    .bubble-logo-imgs img:nth-child(2){
        width: var(--width);
        left: var(--left);
        position: absolute;
        bottom: var(--bottom);
        will-change: all;
    }

    .bubble-logo-imgs img:nth-child(3){
        width: var(--width);
        left: var(--left);
        position: absolute;
        bottom: var(--bottom);
        will-change: all;
    }
    
    .bubble-logo-imgs img:only-child{
        width: var(--width);
        left: 50%;
        position: absolute;
        bottom: var(--bottom);
        transform: translateX(-50%);
        will-change: all;
    }

    .bubble-principal-text-second{
        font-family: 'chrisFont';
        font-size: var(--fontSize);
        text-transform: uppercase;
        
    }

    .text-view{
        /*top: var(--topView);*/
    }

    .text-project{
        /*top: var(--topProject);*/
    }

    .bubble-principal-title{
        font-family: 'quoteFont';

        font-size: var(--fontSize);
        width: 100%;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    
    .bubble-principal-text{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        color: #fff;
        align-content: center;
        /*top: var(--topView)*/
        
    }

    .bubble-principal-text div{
        /*padding-bottom: 1%;*/
    }
    
    .bubble-principal-text-container{
        top: 50%;
    }
    .bubble-logo-container, .bubble-principal-text-container{
        position: absolute;
        width: 100%;
        height: 50%;
        display: flex;
        align-content: center;
        align-items: center;
        text-align: center;
    }

    .bubble-principal-container, 
    .bubble-principal-img, 
    .bubble-principal-overlay,
    .bubble-principal-data-container,
    .bubble-principal-info-container{
        position: absolute;
    }

    .bubble-principal-data-container{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
    }

    .bubble-principal-img, 
    .bubble-principal-data-container,
    .bubble-principal-overlay,
    .bubble-principal-info-container{
        
        border-radius: 99%;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .bubble-principal-info-container,
    .bubble-principal-overlay{
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
    }

    
    .bubble-principal-overlay{
        background-color: var(--color);
        opacity: 0.9;
        clip-path: circle(50% at center);
    }
    .bubble-img-container{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 99%;
        left: 0;
        top: 0;
        clip-path: circle(50% at center);
        overflow: hidden;
    }

    .bubble-principal-img{
        width: 104%;
        height: 104%;
        top: -2%;
        left: -2%;
        
    }

    

</style>

<script>
    import gsap from 'gsap'
    export default {
        name: "bubblePrincipal",
        props:{
            color : String,
            img: String,
            text: String,
            initialLogos: Array,
            onHover: Function,
            onLeave: Function,
            afterClick: Function,
            beforeClick: Function,
            topTitle: String,
            topView: String,
            topProject: String,
            topViewText: {type: String, default: undefined},
            direction: { type: Number, default: 1 },
            delay: { type: Number, default: 0 },
            scale: { type: Number, default: 1.05 },
            logoOneWidth: {type: String, default: '60%'},
            logoTwoWidth: {type: String, default: '30%'},
            logoThreeWidth: {type: String, default: '30%'},
            fontSize : {type: String , default: '237%'},
            bottomSecondLine : {type: String , default: '47%'},
            logoTwoLeft: {type: String, default: '15%'},
            logoThreeLeft: {type: String, default: '30%'},
        },
        data: () => ({
            pulseAnimation: undefined,
            clickActive: false,
            hoverAnimation : undefined,
            hDirection: 1,
            vDirection: 1,
            animationH: undefined,
            animationV:undefined,
            animationR:undefined,
            animationInit: false,
            isHover: false

            
       }),
        created() {
            window.addEventListener("resize", this.fontSizeUpdate);
        },
        computed: {
            layerOneCss () {
                    return{
                    /* variables you want to pass to css */
                    '--color': this.color,
                }
            },
            logos(){
                return this.initialLogos
            },
            viewText(){
                return (this.logos.length>1)? "View These" : "View This"
            },
            projectText(){
                return (this.logos.length>1)? "Projects" : "Project"
            }
        },
        methods: {
            toInitialState(){
                gsap.set(this.$refs.bubble, {scale: 1, x: 0, y:0})
                gsap.set(this.$refs.info, {autoAlpha: 0})
                gsap.set(this.$refs.img, {filter: 'blur(0px)', scale: 1})
                gsap.set(this.$refs.dimOverlay, {autoAlpha: 0})

            },
            createHoverAnimation(){
                gsap.ticker.lagSmoothing(0);
                var tl = gsap.timeline({onReverseComplete: this.resumeAnimations}).paused(true)
                tl.set(this.$refs.bubble,   {zIndex: 1, scale: 1})
                
                //tl.to(this.$refs.bubble, {rotate: 0, ease: "sine.inOut", duration: 0.5})
                tl.fromTo(this.$refs.innerBubble,{scale: 1}, {scale: this.scale, duration: 0.25, onComplete:()=>{
                    
                    if(this.onHover !== undefined )
                        this.onHover()
                        
                }})
                tl.fromTo(this.$refs.overlay, {autoAlpha: 0}, {autoAlpha: 0.9, duration: 0.25}, '-=0.25')
                tl.fromTo(this.$refs.info, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.25}, '-=0.25')
                tl.to(this.$refs.img, {filter: 'blur(3px)', duration: 0.25},'-=0.25')
                
                return tl
            },
            pauseAnimations(checkHover){
                if(checkHover && this.hoverAnimation!== undefined && this.hoverAnimation.isActive())
                    this.hoverAnimation.pause()
                if(this.animationH!==undefined)
                    this.animationH.pause()
                if(this.animationV!==undefined)
                    this.animationV.pause()
                if(this.animationR!==undefined)
                    this.animationR.pause()
            },
            resumeAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.resume()
                if(this.animationV!==undefined)
                    this.animationV.resume()
                if(this.animationR!==undefined)
                    this.animationR.resume()
            },
            hover(){
                
                if(this.clickActive) return
                if(this.isHover) return
                this.isHover = true 
                this.pauseAnimations(true)
                this.hoverAnimation.timeScale(1).play()
                this.$emit('bubbleHover')
                
            },
            leave(){
                if(this.clickActive) return
                
                /*if(this.hoverAnimation.isActive())
                    this.hoverAnimation.pause()*/

                if(!this.hoverAnimation.reversed()){
                    this.hoverAnimation.reverse()
                    //this.resumeAnimations()
                }
                
                this.isHover = false 
                
                this.$emit('bubbleLeave')
                
            },
            onClickAnimation(){
                
                
                if(this.hoverAnimation.isActive()) {
                    this.hoverAnimation.eventCallback("onComplete", this.onClickAnimation);
                    return
                }
                
                
                
                
                if(this.afterClick !== undefined )
                    this.afterClick()
                
                let time = 4
                //gsap.ticker.lagSmoothing(0);
                var tl = gsap.timeline()
                tl  .set(this.$refs.bubble, {zIndex: 2})
                    
                    .set(this.$refs.dimOverlay, {autoAlpha:1, backgroundColor:'unset', top: '50%', left: '50%', translateX: '-50%', translateY: '-50%', width:'99.5%', height: '99.5%', border: 'solid 100vw black'})
                    .to(this.$refs.dimOverlay, {scale: 15, duration: time})
                    .to(this.$refs.overlay, {scale: 15, duration: time}, '-='+time)
                    .to(this.$refs.overlay, {autoAlpha:0.4, duration: (time/2)}, '-='+time)
                    .to(this.$refs.img, {autoAlpha: 0, duration:0.25}, '-='+time)
                    .to(this.$refs.info, {autoAlpha: 0, duration: 1}, '-='+time)
                    .to(this.$refs.img, {scale: 15, duration:time, onComplete:()=>{
                        
                        this.clickActive = false
                        this.isHover = false 
                        this.hoverAnimation.pause(0)
                        this.hoverAnimation.eventCallback("onComplete", null);
                        
                        //gsap.to(this.$refs.overlay, {autoAlpha: 0})
                        //console.log('IM OVER IN BUBBLE')
                        this.$emit('bubbleLeave')
                    }}, '-='+time)
                    .to(this.$refs.overlay, {autoAlpha:0, duration: (2)}, '-='+1.5)
                    
                    tl.set(this.$refs.bubble, {autoAlpha: 0, duration: 0.5})
                    
                    tl.set(this.$refs.overlay, {scale: 1})
                    tl.set(this.$refs.dimOverlay, {scale: 1, autoAlpha: 0, border: 'none', backgroundColor: '#000', width:'100%', height: '100%'})
                    tl.set(this.$refs.img, {scale: 1, autoAlpha: 1, filter: 'blur(0px)'})
                    tl.set(this.$refs.bubble, {zIndex:0, autoAlpha: 1})
                    
                
            },

            onClick(){
                if(this.clickActive) return
                this.clickActive = true
                if(this.beforeClick !== undefined )
                    this.beforeClick()
                this.onClickAnimation()
     

            },
            random(min, max) {
                var delta = max - min;
                var r = (min + delta * Math.random());
                
                return r
            },
            moveX( ) {
                this.hDirection *= -1
                var distance = this.random(1, 18) * this.hDirection
                var dur = this.random(3, 5)
                gsap.ticker.lagSmoothing(0);
                this.animationH = gsap.to(this.$refs.bubble, {x: distance, duration: dur, force3D: true, ease: "sine.inOut", onComplete:()=>{
                    this.moveX()
                }})
            },
            moveY( ) {
                this.vDirection *= -1
                var distance = this.random(1, 18) * this.vDirection
                var dur = this.random(3, 5)
                gsap.ticker.lagSmoothing(0);
                this.animationV = gsap.to(this.$refs.bubble, {y: distance, duration: dur,  force3D: true, ease: "sine.inOut", onComplete:()=>{
                    this.moveY()
                }})
            },
            rotate( ) {
                
                var angle = this.random(-1, 1)
                var dur = this.random(5, 10)
                this.animationR = gsap.to(this.$refs.bubble, {rotate: angle, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.rotate()
                }})
            },
            pulse(){
                
                if(this.animationInit){
                    this.resumeAnimations()
                    return
                } 
                this.hDirection = this.actualDirection
                this.VDirection = this.actualDirection
                
                
                this.moveX()
                this.moveY()
                //this.rotate()
                this.animationInit = true
            },
            pause(){
                this.pauseAnimations(false)
            },
            play(){
                this.resumeAnimations()
            },
            transitionOut(onComp){
                this.pauseAnimations(false)
                gsap.to(this.$refs.bubble, {scale: 0.4, autoAlpha: 0, duration: 0.85, onComplete:()=>{
                    if(onComp!==undefined)
                        onComp()
                }})
            },
            resetBubble(){
                gsap.set(this.$refs.bubble, {scale: 1})
                gsap.to(this.$refs.bubble, {autoAlpha: 1, duration: 0.85, onComplete:()=>{
                    this.resumeAnimations()
                }})
            },

            transitionIn(){
                //this.pauseAnimations(false)
                //gsap.to(this.$refs.bubble, {scale: 1, autoAlpha: 1, duration: 3})
            },
            scaleDown(){
                

                this.pauseAnimations(false)
                
                gsap.to(this.$refs.dimOverlay, {autoAlpha: 0.7})
                gsap.to(this.$refs.bubble, {scale: 0.9})

            },
            scaleUp(animated){
                gsap.to(this.$refs.bubble, {scale: 1})
                gsap.to(this.$refs.dimOverlay, {autoAlpha: 0})
                if(animated==undefined || animated)
                    this.resumeAnimations()
                
                
            },
            calcLandscapeFont(val){
                
                
                val = val.replace('%', '')
                var elem = document.getElementById("landscape")
                
                if(window.getComputedStyle(elem).display=='none')
                    val *= 1.65

                
                return val+'%';
                
            },
            fontSizeUpdate(){
                gsap.set(this.$refs.textTitle, {'--fontSize': this.calcLandscapeFont(this.fontSize)})
                gsap.set('.bubble-principal-text-second', {'--fontSize': this.calcLandscapeFont('102%')})
            }
        },

        
        mounted() {
            
            var tempTopViewtext = this.topViewText
            if(!this.topViewText)
                tempTopViewtext = this.topView
            gsap.set(this.$refs.textContainer, {'--topView' : tempTopViewtext})
            this.fontSizeUpdate()
            
            //gsap.set(this.$refs.textProject, {'--topProject' : this.topProject})

            
            gsap.set( this.$refs.img0, {'--width': this.logoOneWidth, bottom : this.topView})
            if(this.logos.length>1){
                gsap.set( this.$refs.img0, {bottom : this.bottomSecondLine})
                gsap.set( this.$refs.img1, {'--width': this.logoTwoWidth, bottom : this.topView, '--left': this.logoTwoLeft})
                gsap.set( this.$refs.img2, {'--width': this.logoThreeWidth, bottom : this.topView, '--left': this.logoThreeLeft})
            }


            if(this.logos.length == 1)
                gsap.set(this.$refs.img0, {bottom: this.topView})
            this.actualDirection = this.direction
            this.hoverAnimation = this.createHoverAnimation()
            //this.hoverAnimation.pause()
            //this.hoverAnimation.play()
            //this.hover()

        }

    }
</script>