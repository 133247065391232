<template>
    <div class ="container">
        <div class ="slider-content" ref="sliderData">
            <slot> </slot>
        </div>
        
        <div class="home-arrow arrow-safe-distance arrow-safe-distance-right visible-landscape" ref="nextArrow" 
                @click="next()" 
                @mouseover="showSliderOverlay(0)" 
                @mouseleave="hideSliderOverlay(0)">
            <div class="arrow-container next-arrow next-arrow-2 arrow-btn arrow-btn-slider" />
        </div>
        <div class="home-arrow arrow-safe-distance arrow-safe-distance-left visible-landscape" ref="preArrow" 
            @click="prev()" 
            @mouseover="showSliderOverlay(1)"
            @mouseleave="hideSliderOverlay(1)">
            <div class="arrow-container prev-arrow prev-arrow-2 arrow-btn " />
        </div>
            
        
        <div class="bullets home-bullets" ref="sliderBullets">
            <div class="active" @click="goto(0)" 
                                @mouseover="showSliderOverlay()"
                                @mouseleave="hideSliderOverlay()">&bull;</div>
            <div @click="goto(1)"   @mouseover="showSliderOverlay()"
                                    @mouseleave="hideSliderOverlay()">&bull;</div>
            <div @click="goto(2)"   @mouseover="showSliderOverlay()"
                                    @mouseleave="hideSliderOverlay()">&bull;</div>
        </div>
    </div>
</template>

<script>
    import gsap from 'gsap'
    export default {
        name: "slider",
        props: ['color'],
        data: () => ({
            items: [],
            bullets: [],
            actual: 1,
            isSlideChanging: false
        }),
        computed: {
            arrowColor(){
                return this.color
            }
        },
        mounted() {
            
            this.items = this.$refs.sliderData.children;
            
            this.bullets = this.$refs.sliderBullets.children
            
            this.actual = 0
            gsap.to(this.$refs.nextArrow, {"--color": '#fff'})
            gsap.to(this.$refs.preArrow, {"--color": '#fff'})
            gsap.to('.next-arrow-2', {opacity: 0})
            gsap.to('.prev-arrow-2', {opacity: 0})
        },
        methods: {
            onSwipeItem() {
                return function (direction) {
                    alert("Swiped item in direction ", direction);
                };
            },
            goto: function (val) {
                if(this.isSlideChanging) return
                var before = this.actual;
                var after = val;
                this.bullets[before].classList.remove('active')
                this.$emit('changeSlide', before, after)
                this.actual = val
                this.bullets[after].classList.add('active')
            },
            prev: function () {
                if(this.isSlideChanging) return
                var temp = this.actual - 1
                if(temp<0) temp = this.items.length-1
                this.goto(temp)
            },
            next: function () {
                if(this.isSlideChanging) return
                var temp = this.actual + 1
                if(temp>=this.items.length) temp = 0
                this.goto(temp)
            },
            showSliderOverlay(index){
                
                var btn = '.next-arrow-2'
                if(index==1) btn = '.prev-arrow-2'
                    
                
                gsap.set('.home-arrow', {zIndex: 1})
                gsap.set('.home-bullets', {zIndex: 1})
                gsap.to(btn, {opacity: 0.15}) 
                this.$emit('arrowHover')
                
            },
            hideSliderOverlay(index){
                var btn = '.next-arrow-2'
                if(index==1) btn = '.prev-arrow-2'
                gsap.set('.home-arrow', {clearProps: 'z-index'})
                gsap.to(btn, {opacity: 0})
                this.$emit('arrowLeave')
            }
        }
    }
</script>

<style>

.home-bullets div{
    color: #fff;
}
.visible{
    visibility: visible;
  }
  .hide{
    visibility: hidden;
    opacity: 0;
  }


.slide-visible{
    visibility: visible;
}
.slider-content{
    width: 100%;
    height: 100%;
    position: absolute;
    /*background-color: blue;*/
}





  .arrow{
    height: 100%;
    opacity: 0.3;
  }
  .arrow:hover{
    opacity: 1;

  }

</style>