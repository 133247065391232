<template>

    <project ref="project"
        classContainer="mtbank-container" 
        classOverlay="overlay-financial"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    <!--<img class="example" src="@/assets/mtbank/home1.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue';

    export default {
        components: { Project }, 
        name: "mtbank",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-mtbank',
                text: "<p>"+
                            "I really enjoyed the range of work<br/>"+ 
                            "while working with M&T – everything<br/>"+
                            "from Snapchat filters to takeover<br/>" + 
                            "experiences of the Baltimore Sun site,<br/>"+
                            "to working on projects connected to<br/>"+
                            "the NFL teams they sponsor: the New<br/>"+
                            "York Jets, the Buffalo Bills and the<br/>"+
                            "Baltimore Ravens."+
                        " </p>",
                title:'Financial', 
                fontSizeInnerText:"0.85rem",
                marginLeft:"-1.45rem",
                marginTop:"4%",
                color:"#465054",
                colorOverlay:"#fff",
                colorText:"#465054"
            },
            bubbleTwoData:{
                class:  'bubble-two-mtbank-1',
                text:   "<p>CREATIVE DIRECTION</p><br/>"+ 
                        "<p>DIGITAL DESIGN & UI DESIGN</p><br/>" +
                        "<p>OMNI-CHANNEL DESIGN: DM,<br/> EMAIL & SITE</p><br/>"+
                        "<p>E-COMMERCE DESIGN</p>",
                color:"#465054",
                colorOverlay:"#74848b",
                colorText:"#fff"
            },
            previewOneData:{
                class:"mtbank-1-preview", 
                img:"assets/mtbank/mtbank_slide_2.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"mtbank-2-preview",
                img:"assets/mtbank/mtbank_slide_3.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewThreeData:{
                class:"mtbank-3-preview", 
                img:"assets/mtbank/mtbank_slide_1.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            nBubbles: ['.bubble-one-mtbank', '.bubble-two-mtbank-1'],
            previews: ['.mtbank-1-preview', '.mtbank-2-preview', '.mtbank-3-preview'],
            financialDetailOne: "<p>"+
                                "Chase has a number of different<br/>"+ 
                                "brands I’ve had the opportunity to<br/>"+
                                "work with. Across the board though,<br/>" + 
                                "working with Chase I was able to<br/>"+
                                "leverage my e-commerce experience<br/>"+
                                "(from working at eToys.com,<br/>"+
                                "walmart.com and gsi commerce and<br/>"+
                                "beyond) which is always a huge plus<br/>for me."+
                                
                            " </p>",
            financialDetailTwo: "<p>CREATIVE DIRECTION</p><br/>"+ 
                                "<p>DIGITAL DESIGN & UI DESIGN</p><br/>" +
                                "<p>OMNI-CHANNEL DESIGN: DM,<br/> EMAIL & SITE</p><br/>"+
                                "<p>E-COMMERCE DESIGN</p>"
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .mtbank-3-preview{
        width: 28vw;
        height: 32vw;
        top: 18%;
        left: 23%;
    }

    .mtbank-2-preview{
        width: 8vw;
        height: 16vw;
        top: 26%;
        left: 73%;
    }

    .mtbank-1-preview{
        width: 19vw;
        height: 34vw;
        top: 19%;
        left: 52%;
    }

    .bubble-two-mtbank-1{
        left: 19.5%;
        top: 62.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-mtbank{
        left: 73%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
    
    .mtbank-preview-img{
        width: 100%;
        position: absolute;
    }
    
    .mtbank-preview-container-1{
        position: absolute;
        width: 26%;
        height: 60%;
        top: 16%;
        left: 22%;
        transform: rotate(-20deg);
        overflow: hidden;
    }

    .mtbank-preview-img-1{
        width: 100%;
        position: absolute;
    }

    .mtbank-preview-img-2{
        width: 100%;
        position: absolute;
    }

    .mtbank-preview-container-1-1{
        position: absolute;
        width: 20%;
        height: 65%;
        top: 19%;
        left: 50%;
        transform: rotate(20deg);
        overflow: hidden;
    }

    .mtbank-preview-container-1-2{
        position: absolute;
        width: 8%;
        height: 30%;
        top: 27%;
        left: 72%;
        transform: rotate(20deg);
        overflow: hidden;
    }
    .mtbank-preview-img-2{
        width: 100%;
        position: absolute;
        
    }

    .mtbank-preview-container-1-2:hover{
        transform: scale(1.05) rotate(20deg);
    }
    .mtbank-preview-container-1-1:hover{
        transform: scale(1.05) rotate(20deg);
    }

    .mtbank-preview-container-1:hover{
        transform: scale(1.05) rotate(-20deg);
    }

    .mtbank-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .mtbank-video:hover{
        transform: scale(1.05);
    }

    .mtbank-video{
        position: absolute;
        border: 5px solid white;
        border-radius: 7px;
        background-color: black;
    }
    .mtbank-mid-video{
        top: 22%;
        left: 25.5%;
        width: 48.5%;
        height: auto;
    }
    .mtbank-left-video{
        top: 29%;
        left: 17%;
        width: 19.5%;
    }
    .mtbank-rigth-video{
        top: 28%;
        left: 69.5%;
        width: 12%;
    }


    .mtbank-bubble{
        border-radius: 99%;
        color: #2a3134;
        background-color: #465054;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mtbank-bubble-1{
        left: 19.5%;
        top: 62.5%;
        height: 4vw;
        width: 4vw;
    }
    
    .mtbank-bubble-1:hover{
        background-color: #fff;
        transform: scale(3.25);
    }

    .mtbank-bubble-2{
        left: 73%;
        top: 63.75%;
        height: 7vw;
        width: 7vw;
    }

    .mtbank-bubble-2:hover{
        background-color: #fff;
        transform: scale(2.5);
    }
    

    .mtbank-bubble-1:hover .mtbank-bubble-text-1{
        visibility: visible;
    }



    .mtbank-bubble-text-1{
        position: absolute;
        font-size: 0.25rem;
        text-transform: uppercase;
        font-family: 'chrisFont';
        text-align: center;
        visibility: hidden;
    }

    .mtbank-bubble-text-2{
        position: absolute;
        font-size: 0.25rem;
        font-family: 'chrisFont';
        text-align: center;
        visibility: visible;
        color: #2a3134;
        font-size: 0.35rem;
        visibility: hidden;
    }
    
    .mtbank-bubble-2:hover .mtbank-bubble-text-2{
        visibility: visible;
    }

    .mtbank-bubble-header{
        font-family: 'quoteFont';
        font-size: 1rem;
        position: relative;
        float: left;
        width: 200%;
        margin-left: -4.75rem;
        margin-top: -0rem;
    }

    .mtbank-bubble-header span{
        color: #fff;
    }

    @media (orientation: portrait) {
        .bubble-one-mtbank {
            height: 57vw;
            width: 57vw;
            left: 23%;
            top: 63.75%;
        }
        .bubble-two-mtbank-1{
            left: 44.5%;
            top: 21.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .mtbank-3-preview{
            width: 50vw;
            height: 59vw;
            top: 33%;
            left: 7%;
        }

        .mtbank-2-preview{
            width: 16vw;
            height: 32vw;
            top: 45%;
            left: 78%;
        }

        .mtbank-1-preview{
            width: 38vw;
            height: 62vw;
            top: 32%;
            left: 50%;
        }
    }
</style>