<template>
    <project  ref="project"
        classContainer="wb-container game-1" 
        classOverlay="overlay-game"
        
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewSliderOneData="this.previewSliderOneData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"/>
    
    <!--<img class="example" src="@/assets/wb/home1.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {Project  }, 
        name: "wb",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-wb',
                text: "<p>"+
                            "We needed to create a teaser site to<br/>"+ 
                            "promote the next incarnation of the<br/>"+
                            "Mortal Kombat franchise. Required to<br/>" + 
                            "make key characters the focus, we<br/>"+
                            "created an experience that leaked<br/>"+
                            "juicy character content based on<br/>"+
                            "conversation generated per character<br/>"+
                            "Warner Bros Games loved it, calling it<br/>"+
                            "the most innovative teaser concept<br/>"+
                            "they’d ever seen."+
                        " </p>",
                title: "Video Games", 
                fontSizeInnerText:"0.79rem", 
                marginLeft:"-1.6rem",
                marginTop:"4%",
                color:"#2a3134", 
                colorOverlay:"#fff", 
                colorText:"#2a3134"
            },
            bubbleTwoData:{
                class:  'bubble-two-wb',
                text:   "<p>CONCEPTING</p><br/>"+
                            "<p>CREATIVE DIRECTION</p><br/> <p>DIGITAL DESIGN & UI DESIGN</p><br/> <p>ENTERTAINMENT DESIGN</p>",
                color:"#2a3134", 
                colorOverlay:"#56656c", 
                colorText:"#fff"
            },
            previewSliderOneData:{
                class: "game1-video",
                images:['assets/wb/MK_Screen_1.jpg', 'assets/wb/MK_Screen_2.jpg', 'assets/wb/MK_Screen_3.jpg']
            },
            previewTwoData:{
                class:"wb-1-preview", 
                img:"assets/wb/wb_slide_2.jpg",
                forward:true,
                isBrik: true,
                upDown:false
                
            },
            previewOneData:{
                class:"wb-2-preview", 
                img:"assets/wb/wb_slide_1.jpg",
                forward:false,
                isBrik: true,
                upDown:false
                
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .wb-2-preview{
        width: 37vw;
        height: 29.7vw;
        top: 21%;
        left: 17%;
    }

    .wb-1-preview{
        width: 31vw;
        height: 20.36vw;
        top: 29%;
        left: 52%;
    }

    .game1-video{
        top: 57.25% !important;
        left: 68.5% !important;
        width: 16.5vw !important;
        height: 9vw !important;
    }

    .bubble-two-wb{
        left: 53%;
        top: 66.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-wb{
        left: 10%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }



    .wb-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-wb {
            height: 57vw;
            width: 57vw;
            top: 62.75%;
        }

        .bubble-two-wb{
            top: 20.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .wb-2-preview{
            width: 55.5vw;
            height: 44.55vw;
            top: 35%;
            left: 5%;
        }

        .wb-1-preview{
            width: 46.5vw;
            height: 30.54vw;
            top: 37%;
            left: 48%;
        }

        .game1-video{
            top: 57.25% !important;
            left: 65.5% !important;
            width: 33vw !important;
            height: 18vw !important;
        }
    }
</style>