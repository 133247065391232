<template>
    <project ref="project"
        classContainer="bedland-container" 
        classOverlay="overlay-agency"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    <!--
    <div class="bedland-container" ref="container">
        

        <preview class="agency-1-preview-1" img="assets/bedland/bedland_slide_2.jpg" :forward="true"
        ref="previewOne" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()" />

        <preview class="agency-1-preview-2" img="assets/bedland/bedland_slide_3.jpg" :upDown="false"   :forward="true"
        ref="previewTwo" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()"/>

        <preview class="agency-1-preview-3" img="assets/bedland/bedland_slide_1.jpg" :forward="false"
        ref="previewThree" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()"/>

        <bubble-one :text="this.agencyDetailOne" ref="bubbleOne"
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"
            class="bubble-one-bedland" :title="'Agency Branding'" fontSizeInnerText="0.75rem" marginLeft="-2.2rem"
            marginTop="4%" color="#411c4b" colorOverlay="#fff" colorText="#411c4b"/>

        <bubble-two :text="this.agencyDetailTwo" ref="bubbleTwo"
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"
            class="bubble-two-bedland" color="#411c4b" colorOverlay="#813795" colorText="#fff"/>

        <div class="overlay-project overlay-agency overlay-agency-1" />
    </div>    -->
    <!--<img class="example" src="@/assets/bedland/home1.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {Project }, 
        name: "bedland",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-bedland",
                text: "<p>"+
                            "I worked with Bedlam to develop their<br/>"+ 
                            "brand and design their site. The<br/>"+
                            "brand’s bold, revolutionary spirit, and<br/>" + 
                            "quirky edge was infused into the new<br/>"+
                            "brand and site experience, which is<br/>"+
                            "filled with fun animations that<br/>"+
                            "juxtapose icons of the past with<br/>"+
                            "technology of the present.<br/>"+
                        "</p>",
                title:"Agency Branding",
                fontSizeInnerText:"0.75rem",
                marginLeft:"-2.2rem",
                marginTop:"4%",
                color:"#411c4b",
                colorOverlay:"#fff",
                colorText:"#411c4b"
            },
            bubbleTwoData:{
                class:"bubble-two-bedland",
                text:   "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>ANIMATION & MOTION</p>",
                color:"#411c4b",
                colorOverlay:"#813795",
                colorText:"#fff"
            },
            previewOneData:{
                class:"agency-1-preview-1",
                img:"assets/bedland/bedland_slide_2.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"agency-1-preview-2",
                img:"assets/bedland/bedland_slide_3.jpg",
                upDown:false,
                forward:true,
                isBrik: false,
            },
            previewThreeData:{
                class:"agency-1-preview-3",
                img:"assets/bedland/bedland_slide_1.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .agency-1-preview-1{
        width: 29vw;
        height: 17vw;
        top: 38%;
        left: 49%;
    }

    .agency-1-preview-2{
        width: 8vw;
        height: 15.9vw;
        top: 38%;
        left: 78%;
    }

    .agency-1-preview-3{
        width: 38vw;
        height: 23vw;
        top: 26%;
        left: 17%;
    }

    .bubble-two-bedland{
        left: 64%;
        top: 71.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-bedland{
        left: 32.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }    

    .bedland-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-bedland {
            top: 62.75%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-bedland{
            top: 20.5%;
            height: 42.5vw;
            width: 42.5vw;
            left: 55%;
        }

        .agency-1-preview-1{
            width: 43.5vw;
            height: 25.5vw;
            top: 38%;
            left: 49%;
        }

        .agency-1-preview-2{
            width: 12vw;
            height: 23.85vw;
            top: 46%;
            left: 82%;
        }

        .agency-1-preview-3{
            width: 57vw;
            height: 34.5vw;
            top: 36%;
            left: 4%;
        }
    }

</style>