<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import NFIVue from './replatform/NFI.vue'
import TBNVue from './replatform/TBN.vue'
import VailVue from './replatform/Vail.vue'

export default { 
    name: "replatformSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'replatform-slider-container',
        classOverlay: 'replatform-crop-overlay',
        classSlider: 'replatform-background',
        classCloseBtn: 'close-btn-replatform',
        logos:[
          {
            path:require('@/assets/vail/logo.png'),
            class: 'vail-logo'
          },
          {
            path:require('@/assets/nfi/logo.svg'),
            class: 'nfi-logo'
          },
          {
            path:require('@/assets/tbn/logo.svg'),
            class: 'tbn-logo'
          }
        ],
        slides:[<VailVue/>, <NFIVue/>, <TBNVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .replatform-background{
    background-image: url('~@/assets/replatform/background.jpg');

  }
  
  .vail-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 17.5% !important;
  }
  .nfi-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 13.5% !important;
  }
  .tbn-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 12.5% !important;
  }


  @media (orientation: portrait) {
  .vail-logo {
      height: 5vw !important;
      min-height: unset !important;
      top: 5.5% !important;
      left: 6vw !important;
      width: auto !important;
  }
  .nfi-logo {
      height: 11vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }

  .tbn-logo {
      height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
}
  
</style>