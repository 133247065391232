<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import DickOneVue from './dick/DickOne.vue'
import DickThreeVue from './dick/DickThree.vue'
import DickTwoVue from './dick/DickTwo.vue'

export default { 
    name: "dickSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'dick-slider-container',
        classOverlay: 'dick-crop-overlay',
        classSlider: 'dick-background',
        classCloseBtn: 'close-btn-dick',
        logos:[
          {
            path:require('@/assets/dick/logo.png'),
            class: 'dick-logo'
          }
        ],
        slides:[<DickOneVue />, <DickTwoVue/>, <DickThreeVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .dick-background{
    background-image: url('~@/assets/dick/background.jpg');

  }
@media (orientation: portrait) {
  .dick-logo{
    height: 12vw;
  }
}
  
  
</style>