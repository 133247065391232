<template>

    <project ref="project"
        classContainer="bonteous-container" 
        classOverlay="overlay-agency"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    <!--
    <div class="bonteous-container" ref="container">
        

        <preview class="agency-2-preview-1" img="assets/bonteous/bonteous_slide_2.jpg" :upDown="true" :forward="false"
        ref="previewOne" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()"/>

        <preview class="agency-2-preview-2" img="assets/bonteous/bonteous_slide_3.jpg" :upDown="true" :forward="false"
        ref="previewTwo" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()"/>
        
        <preview class="agency-2-preview-3" img="assets/bonteous/bonteous_slide_1.jpg" :upDown="true" :forward="true"
        ref="previewThree" @previewHover="this.showOverlay()" @previewLeave="this.hideOverlay()"/>

        <bubble-one class="bubble-one-bonteous" :text="this.agencyDetailOne" ref="bubbleOne"
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"
            :title="'Agency Branding'" fontSizeInnerText="0.75rem" marginLeft="-2.2rem"
            marginTop="4%"
            color="#411c4b" colorOverlay="#fff" colorText="#411c4b"/>

         <bubble-two class="bubble-two-bonteous" :text="this.agencyDetailTwo" ref="bubbleTwo"
            @bubbleHover="this.showOverlay()" @bubbleLeave="this.hideOverlay()"
            color="#411c4b" colorOverlay="#813795" colorText="#fff"/>     
        
        <div class="overlay-project overlay-agency overlay-agency-2" />
        
    </div>    -->
    <!--<img class="example" src="@/assets/bonteous/home1.jpg" />-->
</template>

<script>
import Project from '../../Project.vue'
    
    export default {
        components: { 
            Project
        }, 
        name: "bonteous",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-bonteous",
                text: "<p>"+
                            "I helped Bounteous reimagine their<br/>"+ 
                            "brand and their site. Some aspects of<br/>"+
                            "the agency’s brand language were<br/>" + 
                            "evolved into the new aesthetic to<br/>"+
                            "make the evolution feel natural."+
                        "</p><br/>"+
                        "<p>"+
                            "The new experience is defined by<br/>"+
                            "elegant animations and data<br/>"+
                            "visualizations, bright poppy photos<br/>"+
                            "and a colorful but clean overall<br/>"+
                            "aesthetic."+
                        "</p>",
                title:"Agency Branding",
                fontSizeInnerText:"0.75rem",
                marginLeft:"-2.2rem",
                marginTop:"4%",
                color:"#411c4b",
                colorOverlay:"#fff",
                colorText:"#411c4b"
            },
            bubbleTwoData:{
                class:"bubble-two-bonteous",
                text:   "<p>BRANDING</p><br/>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>ANIMATION & MOTION</p>",
                color:"#411c4b",
                colorOverlay:"#813795",
                colorText:"#fff"
            },
            previewOneData:{
                class:"agency-2-preview-1",
                img:"assets/bonteous/bonteous_slide_2.jpg",
                upDown:true,
                forward:false,
                isBrik: false
            },
            previewTwoData:{
                class:"agency-2-preview-2",
                img:"assets/bonteous/bonteous_slide_3.jpg",
                upDown:true,
                forward:false,
                isBrik: false,
            },
            previewThreeData:{
                class:"agency-2-preview-3",
                img:"assets/bonteous/bonteous_slide_1.jpg",
                upDown:true,
                forward:true,
                isBrik: false
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .agency-2-preview-1{
        width: 20vw;
        height: 29vw;
        top: 28%;
        left: 27%;
    }

    .agency-2-preview-2{
        width: 7vw;
        height: 15vw;
        top: 30%;
        left: 17%;
    }

    .agency-2-preview-3{
        width: 30vw;
        height: 30vw;
        top: 21%;
        left: 50%;
    }

    .bubble-two-bonteous{
        left: 77%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-bonteous{
        left: 20.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .bonteous-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-bonteous {
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }
        .bubble-two-bonteous{
            top: 20.5%;
            left: 55%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .agency-2-preview-1{
            width: 30vw;
            height: 59vw;
            top: 28%;
            left: 18%;
        }

        .agency-2-preview-2{
            width: 14vw;
            height: 30vw;
            top: 38%;
            left: 9%;
        }

        .agency-2-preview-3{
            width: 55vw;
            height: 55vw;
            top: 31%;
            left: 42%;
        }
    }
</style>