<template>
    <project ref="project"
        classContainer="vail-container" 
        classOverlay="overlay-replatform"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    
    <!--<img class="example" src="@/assets/vail/home1.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {Project  }, 
        name: "vail",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-vail",
                text: "<p>"+
                            "Definitely the most prevalent project<br/>"+ 
                            "type I’ve had in the past 10 years –<br/>"+
                            "usually the brand/company has<br/>" + 
                            "already committed to a platform, AEM<br/>"+
                            "in this case, and wants to redesign<br/>"+
                            "their site to get the most out of it. In<br/>"+
                            "this case, the new Vail site also<br/>"+
                            "serves as a template for a number of<br/>"+
                            "other Vail-owned destinations like<br/>"+
                            "Breckenridge.<br/>"+
                        "</p>",
                title:"Replatform & Redesign",
                textSlice:8,
                fontSizeInnerText:"0.79rem",
                marginLeft:"-5rem",
                fontSize:"2rem", 
                marginTop:"5%",
                color:"#463e2d",
                colorOverlay:"#fff",
                colorText:"#463e2d"
            },
            bubbleTwoData:{
                class:"bubble-two-vail",
                text:   "<p>CONCEPTING</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>MODULAR DESIGN</p><br/>"+
                        "<p>ANIMATION & MOTION</p>",
                color:"#463e2d",
                colorOverlay:"#867656",
                colorText:"#fff"
            },
            previewOneData:{
                class:"vail-preview-1",
                img:"assets/vail/vail_slide_2.jpg",
                upDown:true,
                forward:true,
                isBrik: false
            },
            previewTwoData:{
                class:"vail-preview-2",
                img:"assets/vail/vail_slide_3.jpg",
                upDown:true,
                forward:true,
                isBrik: false,
            },
            previewThreeData:{
                class:"vail-preview-3",
                img:"assets/vail/vail_slide_1.jpg",
                upDown:true,
                forward:false,
                isBrik: false
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .overlay-replatform{
        background-color: #5E5746;
    }
    .vail-preview-1{
        width: 24vw;
        height: 21vw;
        top: 36%;
        left: 51.5%;
    }

    .vail-preview-2{
        width: 6vw;
        height: 13vw;
        top: 39%;
        left: 78%;
    }

    .vail-preview-3{
        width: 32vw;
        height: 27vw;
        top: 23%;
        left: 20%;
    }

    .bubble-two-vail{
        left: 75%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-vail{
        left: 14.5%;
        top: 58.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .vail-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-vail {
            top: 60.5%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-vail{
            top: 20.5%;
            left: 55%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .vail-preview-1{
            width: 48vw;
            height: 42vw;
            top: 36%;
            left: 44.5%;
        }

        .vail-preview-2{
            width: 12vw;
            height: 26vw;
            top: 48%;
            left: 83%;
        }

        .vail-preview-3{
            width: 50vw;
            height: 44vw;
            top: 34%;
            left: 6%;
        }
    }
</style>