import { createApp } from 'vue'
import App from './App.vue'
import Vue3TouchEvents from "vue3-touch-events";
//import VueMeta from 'vue-meta'
//import { createMetaManager } from 'vue-meta'





const app = createApp(App)
app.use(Vue3TouchEvents);
//app.use(createMetaManager())


app.mount('#app')
