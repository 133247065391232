<template>

    <div class="bio-container bio-container-3"  ref="bioContainer">
        <div class="bio3-background"/>
        <div class="bio3-circle-hide" ref="circleBottomHide" />
        <div class="bio-name bio-name-3 bio-name-3-white">
            <img :src="require('@/assets/chris.png')" />    
        </div>
        <div class="bio3-circle" id="bio3-circle-non-change" />
        <div id="circleBottom" class="bio3-circle" ref="circleBottom">
            <div class="bio-name bio-name-3 bio-name-3-black">
                <img :src="require('@/assets/chris-black.png')" />    
            </div>
            <div class="bio3-text">
                <div class="quote-symbol position-bio1" >“</div>
                <p class="bio3-header">Let's go.</p> 
                <p class="bio3-p visible-landscape">
                    From channel to channel, medium to medium, industry to industry,<br/>
                    I've got it covered. And if I don't, I do my homework and have<br/>
                    won awards for clients on those first-time projects.<span>”</span>
                </p>
                <p class="bio3-p visible-portrait">
                    From channel to channel, medium to medium,<br/> industry to industry,
                    I've got it covered. And if I<br/> don't, I do my homework and have
                    won awards<br/> for clients on those first-time projects.<span>”</span>
                </p>
            </div>
            
            <div class="bio3-black-line" />

            <div class="bio3-footer visible-landscape">
                <div>
                    digital<br/>
                    print<br/>
                    broadcast<br/>
                    identity<br/>
                    direct<br/>
                    in-store<br/>
                    outdoor<br/>
                    xd team builder and mentor<br/>
                    x-functional team leader<br/>
                    agency & in-house experience
                </div>
                <div>
                    design<br/>
                    branding<br/>
                    ux & ui<br/>
                    e-commerce<br/>
                    product design<br/>
                    connected devices<br/>
                    prototyping<br/>
                    agile methodologies<br/>
                    emerging technologies<br/>
                    workshops & new biz
                </div>
                <div>
                    retail<br/>
                    cpg<br/>
                    fashion<br/>
                    financial<br/>
                    health care<br/>
                    sports<br/>
                    insurance<br/>
                    high-tech<br/>
                    gaming<br/>
                    destination
                </div>
            </div>
            
            <div class="bio3-footer visible-portrait">
                <div>
                    digital<br/>
                    print<br/>
                    broadcast<br/>
                    identity<br/>
                    direct<br/>
                    in-store<br/>
                    outdoor<br/>
                    xd team builder and mentor<br/>
                    x-functional team leader<br/>
                    agency & in-house experience
                </div>
                <div>
                    design<br/>
                    branding<br/>
                    ux & ui<br/>
                    e-commerce<br/>
                    product design<br/>
                    connected devices<br/>
                    prototyping<br/>
                    agile methodologies<br/>
                    emerging technologies<br/>
                    workshops & new biz
                </div>
                <div>
                    retail<br/>
                    cpg<br/>
                    fashion<br/>
                    financial<br/>
                    health care<br/>
                </div>
                <div>
                    sports<br/>
                    insurance<br/>
                    high-tech<br/>
                    gaming<br/>
                    destination
                </div>
            </div>
            <div class="close-btn close-bio-btn-3" @click="close()" @mouseover="closeHover()"  @mouseleave="closeLeave()"/> 
            <div class="arrow-safe-distance arrow-safe-distance-right arrow-bio-3-right arrow-bio visible-landscape " 
            @mouseover="hoverArrow('.arrow-bio-3-right')"  @mouseleave="leaveArrow('.arrow-bio-3-right')"
            @click="next()" >
                <div class="arrow-container next-arrow arrow-btn" />
            </div>
            
        </div>
        <div class="arrow-safe-distance arrow-safe-distance-left arrow-bio-3-left arrow-bio visible-landscape"  
        @mouseover="hoverArrow('.arrow-bio-3-left')"  @mouseleave="leaveArrow('.arrow-bio-3-left')"
        @click="prev()">
            <div class="arrow-container prev-arrow arrow-btn" />
        </div>
        <img class="bio3-chris" src="@/assets/bio/chris3-b.jpg">
        <img class="bio3-chris  bio3-img-fade" src="@/assets/bio/chris3.jpg">
        <div class="bio3-chris"  @mouseover="this.fadeOutImage()" @mouseleave="this.fadeInImage()"/>

        
        <!--<img class="example" src="@/assets/bio/bio3.jpg" />-->
        <!--<img class="example" src="@/assets/bio/bio3-portrait.jpg" />-->
    </div>
    
</template>

    
<script>

import gsap from 'gsap'
var elem0 = [];
export default {


    name: "BioOne",
    components: {
    },
    data: () => ({
        initialCircleBottom: '',
        hideCircleBottom : '',
        isAnimating: false
    }),
    methods: {
        hoverArrow(arrow){
            gsap.to(arrow, {autoAlpha: 0.75})
        },
        leaveArrow(arrow){
            gsap.to(arrow, {autoAlpha: 0.5})
        },
        closeLeave(){
            gsap.to('.close-bio-btn-3', {autoAlpha: 0.5})
        },
        closeHover(){
            gsap.to('.close-bio-btn-3', {autoAlpha: 0.75})
        },
        fadeInImage(){
            gsap.to('.bio3-img-fade', {autoAlpha: 1})
        },
        fadeOutImage(){
            gsap.to('.bio3-img-fade', {autoAlpha: 0})
        },
        close(){
            if(this.isAnimating) return
            var elem =window.getComputedStyle(document.querySelector("#home-chris-non-change"))
            var tl = gsap.timeline()
            tl.set('.home-chris', {autoAlpha: 0})
                .set('.bio-name-3-white', {autoAlpha: 1})
                .to('#circleBottom', {clipPath: this.hideCircleBottom, duration: 1.5})
                .to('.bio3-chris', {scale: 0, duration: 0.5}, '-=1.5') 
                .to('.bio3-background', { autoAlpha: 0, duration: 1.5}, '-=1.5')
                .to('.close-bio-btn-3', {autoAlpha:0, duration: 1.5}, '-=1.5')
                .fromTo('.bio-name-3', 
                    
                     {
                        top: Math.round(elem0.top.replace('px', '')), 
                        left: Math.round(elem0.left.replace('px', '')),
                        width: Math.round(elem0.width.replace('px', '')),
                        height: Math.round(elem0.height.replace('px', '')),
                      
                    },
                     {  
                        top: Math.round(elem.top.replace('px', '')), 
                        left: Math.round(elem.left.replace('px', '')),
                        width: Math.round(elem.width.replace('px', '')),
                        height: Math.round(elem.height.replace('px', '')),
                        duration: 1.5
                    },
                    
                   
                    '-=1.5')
                .to('.arrow-bio-3-left', {autoAlpha: 0, duration: .75}, '-=1.5')
                .to('.arrow-bio-3-right', {autoAlpha: 0, duration: .75}, '-=1.5')
                .set('.home-chris', {autoAlpha: 1})
                .set('.bio-container-3', {autoAlpha: 0})
                .set('.popup-container', {autoAlpha: 0})
                .set('.bio-slider-container', {autoAlpha:0, display:'none'})
                
                .set('.top-circle-hover-zone', {autoAlpha: 1})
                .set('.bottom-circle-hover-zone', {autoAlpha: 1})
                .set('.home-arrow', {display: 'block'})
                .set('.home-bullets', {display: 'block', onComplete:()=>{
                    gsap.set('.arrow-bio-3-left', {autoAlpha: 0.5})
                    gsap.set('.arrow-bio-3-right', {autoAlpha: 0.5})
                    this.$emit('close')
                }})
        },
        toBio(index, circle, onComplete){
            
            if(index == 0)
                this.toBioOne(onComplete)
            if(index == 1)
                this.toBioTwo(circle, onComplete)
        },
        toBioTwo(circle, onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
                tl.set('.bio3-background', {autoAlpha: 0}) 
                .to('#circleBottom', {clipPath: circle, duration: time})
                .to('.bio3-chris', {scale: 0, duration: time}, '-='+time) 
                
                .to('.bio-container-3', {autoAlpha: 0, onComplete:()=>{
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
        },
        toBioOne(onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
                tl.to('#circleBottom', {clipPath: this.hideCircleBottom, duration: time})
                .to('.bio3-chris', {scale: 0, duration: time}, '-='+time) 
                .to('.bio-container-3', {autoAlpha: 0, onComplete:()=>{
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
                

        },
        fromBio(index, circle, onFinish){
            
            if(index==1)
                this.fromBioTwo(circle, onFinish)
            if(index == 0)
                this.fromBioOne(onFinish)
        },
        fromBioOne(onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var tl = gsap.timeline()
            var time = 0.85
            tl.set('.bio-container-3', {autoAlpha: 1})
            .set('.bio3-chris', {scale: 0})
            .set('.close-bio-btn-3', { autoAlpha: 0.5})
            .set('.bio-name-3-white', {autoAlpha: 0})
            .set('.bio3-background', { autoAlpha: 1})
            .set('.bio-name-3', { 
                top: Math.round(elem0.top.replace('px', '')), 
                left: Math.round(elem0.left.replace('px', '')),
                width: Math.round(elem0.width.replace('px', '')),
                height: Math.round(elem0.height.replace('px', ''))
            })


            .set('.bio-background', { backgroundColor: 'unset'})
            
            .fromTo('#circleBottom', {clipPath: this.hideCircleBottom}, {clipPath: this.initialCircleBottom , duration: time})
            .to('.bio3-chris', {scale: 1, duration: time, onComplete:()=>{
                this.isAnimating = false
                onFinish()
                
            }}, '-='+time)
        },
        fromBioTwo(circle, onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            tl.set('.bio-container-3', {autoAlpha: 1})
                .set('.close-bio-btn-3', { autoAlpha: 0.5})
                .set('.bio3-chris', {scale: 0})
                .set('.bio-name-3-white', {autoAlpha: 0})
                .set('.bio3-background', { autoAlpha: 0})
                .set('.bio-background', { backgroundColor: 'unset'})
                .set('.bio-name-3', { 
                    top: Math.round(elem0.top.replace('px', '')), 
                    left: Math.round(elem0.left.replace('px', '')),
                    width: Math.round(elem0.width.replace('px', '')),
                    height: Math.round(elem0.height.replace('px', ''))
                })
                .fromTo('#circleBottom', {clipPath: circle}, {clipPath: this.initialCircleBottom, duration: time})
                .to('.bio3-chris', {scale: 1, duration: time, onComplete:()=>{
                    gsap.set('.bio3-background', { autoAlpha: 1})
                    this.isAnimating = false
                    onFinish()
                    
                }}, '-='+time)
                
        },
        updateScreen(){
            var circle =document.getElementById('bio3-circle-non-change')
            
            var tempDir =''
            
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none'){
                tempDir = 'portrait'
                circle.classList.remove('bio3-circle-landscape')
                circle.classList.add('bio3-circle-portrait')
                gsap.set('.close-bio-btn-3', {autoAlpha: 1})
                gsap.set('.arrow-bio-3-left', {autoAlpha: 1})
                gsap.set('.arrow-bio-3-right', {autoAlpha: 1})
            }else{
                tempDir = 'landscape'
                circle.classList.add('bio3-circle-landscape')
                circle.classList.remove('bio3-circle-portrait')
                gsap.set('.close-bio-btn-3', {autoAlpha: 0.5})
                gsap.set('.arrow-bio-3-left', {autoAlpha: 0.5})
                gsap.set('.arrow-bio-3-right', {autoAlpha: 0.5})
            }

            if(circle.style.clipPath){
                circle = circle.style
            }else{
                circle = window.getComputedStyle(document.querySelector('#bio3-circle-non-change'))
            }

            if(tempDir && tempDir!=this.direction){
               gsap.set('#circleBottom',{clipPath: circle.clipPath})
            }

            this.initialCircleBottom = circle.clipPath

            elem0 = window.getComputedStyle(document.querySelector('#bio-name-non-change'))
            
            gsap.set('.bio-name-3',  {  
                top: Math.round(elem0.top.replace('px', '')), 
                left: Math.round(elem0.left.replace('px', '')),
                width: Math.round(elem0.width.replace('px', '')),
                height: Math.round(elem0.height.replace('px', ''))
            })
        },
        next(){
            this.$emit('next')
        },
        prev(){
            this.$emit('prev')
        },
    },
    computed: {
        

    },
    mounted() {
       gsap.set('.bio-container-3', {autoAlpha: 0}) 
       this.initialCircleBottom = window.getComputedStyle(this.$refs.circleBottom).clipPath
       this.hideCircleBottom = window.getComputedStyle(this.$refs.circleBottomHide).clipPath
       this.updateScreen()
        gsap.set('.arrow-bio-3-left', {'--color' : '#ed1b24', overwrite: 'auto'})
        gsap.set('.arrow-bio-3-right', {'--color' : '#000', overwrite: 'auto'})
    },
    created(){
        window.addEventListener("resize", this.updateScreen);
    },
}
</script>

<style>
#bio3-circle-non-change{
    background-color: blue;
    visibility: hidden;
}

.bio3-background{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}
    .bio3-black-line{
        position: absolute;
        background-color: #000;
        height: 2px;
        width: 42.5%;
        top: 62.5%;
        left: 26.5%;

    }
    .bio3-footer{
        position: absolute;
        color: white;
        font-family: 'chrisFont';
        font-size: 0.78rem;
        left: 22.8%;
        top: 67%;
        text-transform: uppercase;

    }
    .bio3-footer div{
        display: inline-block;
        margin-left: 4.55rem;
    }

    .bio3-footer div:last-child{
        margin-left: 6vw;
    }


    .bio3-chris{
        position: absolute;
        left: 68%;
        top: 13%;
        width: 15vw;
        height: 15vw;
        border-radius: 99%;
    }
    .bio3-header{
        font-size: 4.55rem;
    }
    
    .bio3-text{
        font-family: 'quoteFont';
        color: white;
        position: absolute;
        top: 27%;
        left: 26.5%;
    }
    .bio3-p{
        font-family: 'chrisFont';
        font-size: 1.42rem;
        margin-top: -4rem;
        line-height: 5.5vh;
    }


    
    .bio3-circle, .bio3-circle-landscape{
        position: absolute;
        background-color: #ed1b24;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(61% at 60% 60%);
    }    

    .bio3-circle-hide{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        clip-path: circle(61% at 160% 160%);
        visibility: hidden;
    }
    .close-bio-btn-3::after,
    .close-bio-btn-3::before{
        background-color: #000 !important;
        
    }

@media (orientation: portrait) {
    .bio3-circle, .bio3-circle-portrait{
        clip-path: circle(76% at 82% 61%);
    }

    .bio3-text {
        left: 6.5%;
        top: 52rem;
        height: 56rem;
    }

    .bio3-header {
        font-size: 9.9rem;
        padding-left: 9rem;
        
        
    }
    .bio3-p {
        font-size: 3.57rem;

        margin-top: -6rem;
        margin-bottom: -6rem;
        line-height: 3.75vh;
        padding-left: 9rem;
    }
    .position-bio3 {
        top: 9% !important;
        left: 0 !important;
        font-size: 17rem !important;
    }

    .bio3-chris {
        left: 60%;
        top: 14%;
        width: 29vw;
        height: 29vw;
    }

    .bio3-black-line {
        height: 2px;
        width: 72%;
        top: 117.5rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .bio3-footer {
        font-size: 0.78rem;
        left: 50%;
        transform: translateX(-50%);
        top: 127rem;
        width: 72%;
        font-size: 2.2rem;
        height: 48rem;
    }

    .bio3-footer div{
        width: 50%;
        margin-left: 0;
        padding-bottom: 7rem;
    }
    .bio3-footer div:last-child{
        margin-left: 0;
    }

    .bio3-footer div:nth-child(2n) {

        padding-left: 4rem;
        box-sizing: border-box;
    }

    .bullets div {
        font-size: 5rem;
        width: 5vw;
    }
    .bullets {
        bottom: 5.5%;
    }
}
</style>