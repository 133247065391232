<template>
    
    <div class="bubble-two-container" ref="bubble" @mouseenter="hover()" @mouseleave="leave()">
        <div class="bubble-two-background" ref="backgroundColor"> 
            
        </div>
            <div class="bubble-two-text-container" ref="textBackground"> 
                <div v-html="this.text" class="text-bubble-two" ref="textContainer">
                </div>
            </div>
    </div>
    
</template>

<style>

    .bubble-two-container{
        position: absolute;
        /*transition: ease .3s transform;*/

    }
    
    .bubble-two-background, .bubble-two-text-container{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 99%;
        left: 0;
        top: 0;
    }
    
    .bubble-two-background{
        opacity: 0.8;
    }

    .bubble-two-text-container{
        position: absolute;
        background-color: var(--colorBackgroundText);
        font-family: 'chrisFont';
        visibility: hidden;
        opacity: 0;
    }

    .text-bubble-two{
        position: absolute;
        font-size: 0.8rem;
        display: block;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }

.text-bubble-two p{
    margin: 0;
}

@media (orientation: portrait) {

    .text-bubble-two{
        font-size: 2.3rem;
    }
}

</style>

<script>
    import gsap from 'gsap'
    export default {
        name: "bubbleTwo",
        props:{
            color : String,
            colorOverlay: String,
            colorText: String,
            text: String,
            onHover: Object,
            onLeave: Object,
            direction: { type: Number, default: 1 },
            

        },
        data: () => ({
            
            isLeaving: false,
            hDirection: 1,
            vDirection: 1,
            animationH: undefined,
            animationV:undefined,
            animationR:undefined,
            hoverAnimation: undefined
            
       }),
        created() {
            
        },
        computed: {
  
           
            
        },
        methods: {
            pauseAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.pause()
                if(this.animationV!==undefined)
                    this.animationV.pause()
                if(this.animationR!==undefined)
                    this.animationR.pause()
            },
            resumeAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.resume()
                if(this.animationV!==undefined)
                    this.animationV.resume()
                if(this.animationR!==undefined)
                    this.animationR.resume()
            },
            createHoverAnimation(){
                gsap.ticker.lagSmoothing(0);
                var tl = gsap.timeline().paused(true)
                tl  .set(this.$refs.bubble, {zIndex: 1})
                    //.to(this.$refs.bubble, {rotate: 0, ease: "sine.inOut", overwrite: 'auto'})
                    .fromTo(this.$refs.bubble, {scale:0.31}, {scale: 1, duration:0.5})
                    .to(this.$refs.textBackground, {autoAlpha: 1, duration:0.25})
                return tl
            },
            hover(){
                this.isHover = true
                this.pauseAnimations()
                this.hoverAnimation.play()
                this.$emit('bubbleHover')
            },
            leave(){

                this.hoverAnimation.reverse()
                this.resumeAnimations()
                this.$emit('bubbleLeave')
            },
            random(min, max) {
                var delta = max - min;
                var r = (min + delta * Math.random());
                
                return r
            },
            moveX( ) {
                this.hDirection *= -1
                gsap.ticker.lagSmoothing(0);
                var distance = this.random(1, 18) * this.hDirection
                var dur = this.random(3, 5)
                this.animationH = gsap.to(this.$refs.bubble, {x: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveX()
                }})
            },
            moveY( ) {
                this.vDirection *= -1
                gsap.ticker.lagSmoothing(0);
                var distance = this.random(1, 18) * this.vDirection
                var dur = this.random(3, 5)
                
                this.animationV = gsap.to(this.$refs.bubble, {y: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveY()
                }})
            },
            rotate( ) {
                /*
                var angle = this.random(-3, 3)
                var dur = this.random(5, 10)
                this.animationR = gsap.to(this.$refs.bubble, {rotate: angle, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.rotate()
                }})*/
            },
            pulse(){
                this.hDirection = this.actualDirection
                this.VDirection = this.actualDirection
                this.moveX()
                this.moveY()
                this.rotate()
            },
            pause(){
                if(this.isLeaving) return
                
            },
            play(){
                if(this.isLeaving) return
                
            },
        },
        mounted() {
            
            gsap.set(this.$refs.textContainer, {color: this.colorText})
            gsap.set(this.$refs.textBackground, {backgroundColor: this.colorOverlay})
            gsap.set(this.$refs.backgroundColor, {backgroundColor: this.color})
            gsap.set(this.$refs.bubble, {scale:0.31})
            this.hoverAnimation = this.createHoverAnimation() 
            this.pulse()
            this.pauseAnimations()
            //this.hover()
        }

    }
</script>