<template>

    <project  ref="project"
        classContainer="sr3-container game-2" 
        classOverlay="overlay-game"
        
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewSliderOneData="this.previewSliderOneData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"/>
    <!--<img class="example" src="@/assets/sr3/home1.jpg" />-->
</template>

<script>
import Project from '../../Project.vue'
    
    export default {
        components: {Project  }, 
        name: "sr3",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-sr3',
                text: "<p>"+
                            "This game-to-web integration site for<br/>"+ 
                            "Saints Row was very similar to the<br/>"+
                            "Brink project done prior which was<br/>" + 
                            "very successful, but I was bigger.<br/>"+
                            "There was also a companion app that<br/>"+
                            "showed live gameplay via an<br/>"+
                            "interactive map as well as a teaser<br/>"+
                            "site to promote the latest release of<br/>"+
                            "the game.<br/>"+
                        " </p>",
                title: "Video Games", 
                fontSizeInnerText:"0.79rem", 
                marginLeft:"-1.6rem",
                marginTop:"4%",
                color:"#2a3134", 
                colorOverlay:"#fff", 
                colorText:"#2a3134"
            },
            bubbleTwoData:{
                class:  'bubble-two-sr3',
                text:   "<p>CONCEPTING</p><br/>"+
                            "<p>CREATIVE DIRECTION</p><br/>"+
                            "<p>DIGITAL DESIGN & UI DESIGN</p><br/>"+
                            "<p>ENTERTAINMENT DESIGN</p>",
                color:"#2a3134", 
                colorOverlay:"#56656c", 
                colorText:"#fff"
            },
            previewSliderOneData:{
                class: "game2-video",
                images:['assets/wb/SR3_Screen_1.jpg', 'assets/wb/SR3_Screen_2.jpg', 'assets/wb/SR3_Screen_3.jpg']
            },
            previewTwoData:{
                class:"sr3-1-preview", 
                img:"assets/sr3/sr3_slide_2.jpg",
                forward:true,
                isBrik: true,
                upDown:false
                
            },
            previewOneData:{
                class:"sr3-2-preview", 
                img:"assets/sr3/sr3_slide_1.jpg",
                forward:false,
                isBrik: true,
                upDown:false
                
            },
            nBubbles: ['.bubble-one-sr3', '.bubble-two-sr3'],
            previews: ['.sr3-1-preview', '.sr3-2-preview', '.game2-video'],
            gameDetailOne: "<p>"+
                                "This game-to-web integration site for<br/>"+ 
                                "Saints Row was very similar to the<br/>"+
                                "Brink project done prior which was<br/>" + 
                                "very successful, but I was bigger.<br/>"+
                                "There was also a companion app that<br/>"+
                                "showed live gameplay via an<br/>"+
                                "interactive map as well as a teaser<br/>"+
                                "site to promote the latest release of<br/>"+
                                "the game.<br/>"+
                                
                            " </p>",
            gameDetailTwo:  "<p>CONCEPTING</p><br/>"+
                            "<p>CREATIVE DIRECTION</p><br/>"+
                            "<p>DIGITAL DESIGN & UI DESIGN</p><br/>"+
                            "<p>ENTERTAINMENT DESIGN</p>"
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-game{
        background-color: #1A1A1A;
    }

    .sr3-2-preview{
        width: 37vw;
        height: 29.89vw;
        top: 21%;
        left: 17%;
    }

    .sr3-1-preview{
        width: 31vw;
        height: 16.4vw;
        top: 23%;
        left: 52%;
    }

    .game2-video{
        top: 53% !important;
        left: 63% !important;
        width: 15vw !important;
        height: 11.5vw !important;
    }

    .bubble-two-sr3{
        left: 57%;
        top: 71.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-sr3{
        left: 10%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
    
    .sr3-preview-img{
        width: 100%;
        position: absolute;
    }
    
    .sr3-preview-container-1{
        position: absolute;
        width: 37%;
        height: 53%;
        top: 21%;
        left: 17%;
        transform: rotate(-20deg);
        overflow: hidden;
    }

    .sr3-preview-img-1{
        width: 100%;
        position: absolute;        
    }

    .sr3-preview-container-1-1{
        position: absolute;
        width: 31%;
        height: 29%;
        top: 23%;
        left: 52%;
        transform: rotate(20deg);
        overflow: hidden;
    }

    .sr3-preview-container-1-2{
        position: absolute;
        width: 8%;
        height: 30%;
        top: 27%;
        left: 72%;
        transform: rotate(20deg);
        overflow: hidden;
    }
    .sr3-preview-img-2{
        width: 100%;
        position: absolute;
        
    }

    .sr3-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .sr3-video:hover{
        transform: scale(1.05);
    }

    .sr3-video{
        position: absolute;
        border: 5px solid white;
        border-radius: 7px;
        background-color: black;
    }
    .sr3-mid-video{
        top: 22%;
        left: 25.5%;
        width: 48.5%;
        height: auto;
    }
    .sr3-left-video{
        top: 53%;
        left: 63%;
        width: 15%;
        height: 20%;
    }
    
    .sr3-rigth-video{
        top: 28%;
        left: 69.5%;
        width: 12%;
    }


    .sr3-bubble{
        border-radius: 99%;
        color: #2a3134;
        background-color: #232323;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sr3-bubble-1{
        left: 54%;
        top: 71.5%;
        height: 4vw;
        width: 4vw;
    }
    
    .sr3-bubble-1:hover{
        background-color: #fff;
    }

    .sr3-bubble-2{
        left: 18%;
        top: 66.75%;
        height: 7vw;
        width: 7vw;
    }

    .sr3-bubble-2:hover{
        background-color: #fff;
    }
    

    .sr3-bubble-1:hover .sr3-bubble-text-1{
        visibility: visible;
    }



    .sr3-bubble-text-1{
        position: absolute;
        font-size: 0.25rem;
        text-transform: uppercase;
        font-family: 'chrisFont';
        text-align: center;
        visibility: hidden;
    }

    .sr3-bubble-text-2{
        position: absolute;
        font-size: 0.25rem;
        font-family: 'chrisFont';
        text-align: center;
        visibility: visible;
        color: #2a3134;
        font-size: 0.35rem;
        visibility: hidden;
    }
    
    .sr3-bubble-2:hover .sr3-bubble-text-2{
        visibility: visible;
    }

    .sr3-bubble-header{
        font-family: 'quoteFont';
        font-size: 1rem;
        position: relative;
        float: left;
        width: 200%;
        margin-left: -4.75rem;
        margin-top: -0rem;
    }

    .sr3-bubble-header span{
        color: #fff;
    }

    @media (orientation: portrait) {
        .bubble-one-sr3 {
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-sr3{
            top: 24.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .sr3-2-preview{
            width: 55.5vw;
            height: 44.835vw;
            top: 37%;
            left: 10%;
        }

        .sr3-1-preview{
            width: 46.5vw;
            height: 24.6vw;
            top: 40%;
            left: 52%;
        }

        .game2-video{
            top: 60% !important;
            width: 30vw !important;
            height: 23vw !important;
        }
    }
</style>