<template>
    <div id="app">
        <v-app v-cloak>
            <transition name="slide-fade" mode="out-in">
                <home-slider ref="home"/>
            </transition>
        </v-app>
        <loader v-if="true" @loadComplete="this.loadComplete()" ref="loaderPage"/>
    </div>
</template>

<script>
import HomeSlider from './components/HomeSlider.vue'

import Loader from './components/Loader.vue'



export default {
  name: 'app',
  
  components: {
    Loader,
    HomeSlider,
    
    
  },
    data: () => ({
        loading: false,
        bio_visible: false,
  }),
  mounted() {
    
  },

  created() {
            document.title = 'Chris Ottinger - Creative Director + Team Leader'
            document.touchmove = function(e){ 
                e.preventDefault(); 
            };
            var paths = [   require('@/assets/chris-black.png'), require('@/assets/chris.png'), require('@/assets/loader/andy.jpg'), require('@/assets/loader/georgia.jpg'), 
                            require('@/assets/loader/salvador.jpg'), require('@/assets/loader/keith.jpg'),
                            require('@/assets/loader/pablo.jpg')]
            for (const imageSrc of paths) {    
                const img = new Image();
                img.src = imageSrc;
            }
            
    },
  methods:{
      loadComplete(){
          this.$refs.home.showLogo()
      }
  }
}
</script>

<style>
#app, v-app{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
}
 
  @font-face {
        font-family: 'quoteFont';
        src: url('~@/assets/fonts/Didot.woff') format('woff');
        
    }

    @font-face {
        font-family: 'chrisFont';
        src:    url('~@/assets/fonts/GothamSSm-Medium.woff') format('woff')
                ;
        font-weight: normal;
        font-style: normal;
        text-rendering:optimizeLegibility;
    }

    @font-face {
        font-family: 'chrisFontLight';
        src: url('~@/assets/fonts/GothamSSm-Light.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        text-rendering:optimizeLegibility;
    }

    @viewport {
        width: device-width;
    }
    *{
       backface-visibility: hidden;
    }
    html, body{
        margin: 0;
        padding: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        cursor: default;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .container{
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        position: absolute;

    }

.circle-home{
    position: absolute;
    border-radius: 99%;
    overflow: hidden;
    background-size: 104% 104%;
    background-origin: center;
    background-position: center;
    background-repeat: no-repeat;
  }

  .bubble-text-2{
        position: absolute;
        font-family: 'chrisFont';
        text-align: center;
        font-size: 0.35rem;
        top: 11%;
        left: 9%;
    }
    .bubble-text-1{
        font-size: 0.25rem;
        text-transform: uppercase;
        font-family: 'chrisFont';
        text-align: center;
        visibility: hidden;
        width: 100%;
    }
    .close-btn{
        position: absolute;
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        width: 3.5vw;
        height: 3.5vw;
        text-align: center;
        font-weight: lighter;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }




    .close-btn::before,
    .close-btn::after {
        cursor: pointer;
        position: absolute;
        content: '';
        width: 4vw;
        height: 1px; /* cross thickness */
        
    }

    .arrow-btn{
        cursor: pointer;
        width: 2vw;
        height: 2vw;
        text-align: center;
        font-weight: lighter;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .arrow-btn::before,
    .arrow-btn::after{
        cursor: pointer;
        position: absolute;
        content: '';
        width: 2.85vw;
        height: 1px;
        left: 0;
        background-color: var(--color);
    }

    .arrow-btn::before{
        top: 0;
    }
    .arrow-btn::after{
        bottom: 0;
    }

    .arrow-btn::before {
        transform: rotate(45deg);
    }

    .arrow-btn::after {
        transform: rotate(-45deg);
    }

    .close-btn::before {
        transform: rotate(45deg);
    }

    .close-btn::after {
        transform: rotate(-45deg);
    }

    .circle-wrapper{
        position: absolute;
        border-radius: 99%;
    }
    .home-page-container, .home-container, 
    .detail-project, .home-circles-container{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #000;
        overflow: hidden;
    }

    .home-text-container-overlay{
        text-align: center;
        line-height: 3rem;
    }

    .home-logo-circle-overlay, .home-text-circle-overlay{
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .view-title-home{
        font-family: 'quoteFont';
        font-size: 1.9rem;
    }

    .view-kind-home, .view-this-home{
        display: block;
        font-family: 'chrisFont';
        text-transform: uppercase;
        font-size: 0.8rem;
    }

    .home-circle-overlay{
        width: 100%;
        height: 100%;
        opacity: 0;
        color: white;
        font-size: 1rem;
        visibility: hidden;
    }
    .home-whiteline{
        height: 0.3vh;
        width: 60%;
        background-color: #fff;
        bottom: 40%;
        position: absolute;
    }
</style>
