<template>
    <div class="bio-slider-container">
      <!--<img class="logo" src="@/assets/bio/logo.png" />-->
      
        <slider class="bio-slider bio-background" ref="slider" color="#ed1b24" @changeTo="this.change">
          
            <bio-two  ref="bioTwo" v-touch:swipe="changeTo" @close="this.close" @next="next" @prev="prev"/>
            <bio-three ref="bioThree" v-touch:swipe="changeTo" @close="this.close" @next="next" @prev="prev"/>
            <bio-one  ref="bioOne" v-touch:swipe="changeTo" @close="this.close" @next="next" @prev="prev"/>
          
        </slider>
        
      
    </div>
    
</template>

<script>
import Slider from '../SliderThree.vue'
import BioThree from './BioThree.vue'
import BioOne from './BioOne.vue'
import BioTwo from './BioTwo.vue'


export default { 
    name: "BioSlider",
    components: {
        Slider,
        BioOne,
        BioTwo,
        BioThree
    },
    data: () => ({
      bios : [],
      isChanging: false
      
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      prev(){
        this.changeTo('right')
      },
      next(){
        this.changeTo('left')
      },
      changeTo(direction){
        
        var before = this.$refs.slider.actual, after = this.$refs.slider.actual
        if(direction=='right'){
          after -= 1 
          if(after < 0) after = 2
        }
          
        else if(direction=='left'){
          after += 1 
          if(after >= 3) after = 0
        }
          
          this.change(before, after)
      },
      change(before, after){
        
        if(this.isChanging) return
        if(this.bios[before].isAnimating || this.bios[after].isAnimating) return
        
        this.isChanging = true
        var circleToAfter = ''
        var circleFromAfter = ''
        if(before == 0 && after ==1)
          circleToAfter = this.$refs.bioTwo.initialCircleTop;
        if(before == 1 && after ==2)
          circleFromAfter = this.$refs.bioTwo.initialCircleBottom;
        if(before == 1 && after ==0)
          circleFromAfter = this.$refs.bioTwo.initialCircleTop;
        if(before == 2 && after ==1)
          circleToAfter = this.$refs.bioTwo.initialCircleBottom;

        this.bios[before].toBio(after, circleToAfter, ()=>{
          this.$refs.slider.isInProgress = this.bios[before].isAnimating || this.bios[after].isAnimating
        })
        this.$refs.slider.updateStatus(before, after)
        this.bios[after].fromBio(before, circleFromAfter, ()=>{
            
            this.isChanging = false
            this.$refs.slider.isInProgress = this.bios[before].isAnimating || this.bios[after].isAnimating
          
        })
      },
      callMe(){
        this.$refs.slider.callMe()
      },
      closeMe(){
        this.$refs.slider.closeMe()
      },
      restart(){
        this.$refs.slider.restart()
      }


    
    },
    mounted(){
      this.bios = [this.$refs.bioOne, this.$refs.bioTwo, this.$refs.bioThree]
    }
    
}
</script>

<style>
  .bio-background{
    /*background-image: url('~@/assets/bio/background.jpg');*/
    /*background-color: #000;*/

  }
 
  
</style>