<template>

    <div class="bio-container bio-container-1" ref="bioContainer">
        <!--<img class="example-home" src="@/assets/bio/bio1.jpg" />-->
        <div class="dim-bio1"/>
        <div class="bio1-circle-hide" ref="circleTopHide"/>
        <div class="bio1-circle" id="bio1-circle-non-change" />
        <div class="bio1-circle" ref="circleTop">
            <div class="bio1-text">
                <div class="quote-symbol position-bio1" >“</div>
                <p class="bio1-header">Hi there and welcome.</p> 
                <p class="bio1-p">
                    I'm Chris, a Creative Director with 20+ years<br/>
                    of experience as a creative team<br/>
                    leader and designer.<span>”</span>
                </p>
            </div>
            <div id="bio-name-non-change" class="bio-name"> 
                <img :src="require('@/assets/chris-black.png')" />
            </div>
            <div class="bio-name bio-name-1"> 
                <img class="" :src="require('@/assets/chris-black.png')" />
            </div>


            <div class="arrow-safe-distance arrow-safe-distance-left arrow-bio-1-left arrow-bio visible-landscape" 
                    @mouseover="hover(0)" @mouseleave="leave(0)" @click="prev()">
                <div class="arrow-container prev-arrow arrow-btn" />
            </div>
            

            
        </div>
        
        
        <img class="bio1-chris" src="@/assets/bio/chris1-b.jpg">
        <img class="bio1-chris bio1-img-fade" src="@/assets/bio/chris1.jpg">
        <div class="bio1-chris"  @mouseover="this.fadeOutImage()" @mouseleave="this.fadeInImage()"/>
        
        <div class="close-btn close-bio-btn-1" @click="close()" @mouseover="closeHover()"  @mouseleave="closeLeave()"> &zwnj;</div> 
        <div class="arrow-safe-distance arrow-safe-distance-right arrow-bio-1-right arrow-bio visible-landscape " 
            @mouseover="hover(1)" @mouseleave="leave(1)"  @click="next()">
            <div class="arrow-container next-arrow arrow-btn" />
        </div>
        <!--<img class="example" src="@/assets/bio/bio1.jpg" />-->
        <!-- <img class="example" src="@/assets/bio/bio-mobile.jpg" /> -->
        
    </div>
    
</template>

    
<script>

import gsap from 'gsap'
var elem0 = [];

export default {
    name: "bioOne",
    components: {
    },
    data: () => ({
      initialCircle : '',
      closeCircle:'',
      openFromHomeAnimation: undefined,
      hideCircleTop:'', 
      initialNameStyle: undefined,
      endNameStyle: undefined,
      closing: false,
      direction: '',
      isAnimating: false, 
      
    }),
    methods: {
        hover(index){
            var arrow = '.arrow-bio-1-left'
            if(index == 1) arrow = '.arrow-bio-1-right'
            gsap.to(arrow, {autoAlpha: 0.75})
        }, 
        leave(index){
            var arrow = '.arrow-bio-1-left'
            if(index == 1) arrow = '.arrow-bio-1-right'
            gsap.to(arrow, {autoAlpha: 0.5})
        },
        closeLeave(){
            if(!this.closing)
            gsap.to('.close-bio-btn-1', {autoAlpha: 0.5})
        },
        closeHover(){
            if(!this.closing)
            gsap.to('.close-bio-btn-1', {autoAlpha: 0.75})
        },
        fadeInImage(){
            gsap.to('.bio1-img-fade', {autoAlpha: 1})
        },
        fadeOutImage(){
            gsap.to('.bio1-img-fade', {autoAlpha: 0})
        },
        openInitial(toCircle, toClose){

            if(this.closeCircle=='')
                this.closeCircle = toClose
            
            this.isAnimating = true
            var elem =window.getComputedStyle(document.querySelector("#home-chris-non-change"))
            var bioPos =window.getComputedStyle(document.querySelector("#bio-name-non-change"))
            
            gsap.config({force3D: false});
            gsap.ticker.lagSmoothing(0);

            var tl = gsap.timeline()
            
            tl  .set(this.$refs.circleTop, {clipPath: toCircle})
                .set('.bio-container-1', {autoAlpha:1})
                
                .set('.home-arrow', {display: 'none'})
                .set('.home-bullets', {display: 'none'})
                .set('.bio-slider-container', {autoAlpha:1, display:'block'})
                .set('.bio-background', { backgroundColor: 'unset', autoAlpha: 1})
                .set('.bio1-chris', {scale: 0})
                
                
                
                .set('.popup-container', {autoAlpha: 1})
                .to(this.$refs.circleTop, {clipPath: this.initialCircle, duration: 1.5})
                .fromTo('.close-bio-btn-1', { autoAlpha: 0}, { autoAlpha: 0.5, duration: 1.5}, '-=1.5')
                .fromTo('.arrow-bio-1-right', { autoAlpha: 0}, { autoAlpha: 0.5, duration: 1.5}, '-=1.5')

                .to('.bio-background', { backgroundColor: '#000'}, '-=1.5')
                .to('.bio1-chris', {scale: 1, duration: 1.5, onComplete:()=>{this.isAnimating = false}}, '-=1.5')
                .fromTo('.bio-name-1', 
                    
                     {  
                        top: Math.round(elem.top.replace('px', '')), 
                        left: Math.round(elem.left.replace('px', '')),
                        width: Math.round(elem.width.replace('px', '')),
                        height: Math.round(elem.height.replace('px', '')),
                        
                        
                        
                    },
                    {
                        top: bioPos.top, 
                        left: bioPos.left,
                        width: bioPos.width,
                        height: bioPos.height,
                        
                        duration: 1.5,
                        onComplete:()=>{this.isAnimating = false}
                    },
                   
                    '-=1.5')
                    
                
                
            this.openFromHomeAnimation = tl
            
        },
        toBio(index, circle, onComplete){
            if(index==1)
                this.toBioTwo(circle, onComplete)
            else if(index==2)
                this.toBioThree(onComplete)
            
        },
        toBioTwo(circle, onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
                tl.set('.close-bio-btn-1', { autoAlpha: 0})
                tl.to(this.$refs.circleTop, {clipPath: circle, duration: time})
                .to('.bio1-chris', {scale: 0, duration: 0.3}, '-='+time)
                
                .set('.bio-container-1', {autoAlpha: 0, onComplete:()=>{
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
                
            
        },
        toBioThree(onComplete){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            //tl.set('.close-bio-btn-1', { autoAlpha: 0})
            tl.set('.arrow-bio-3-left', {autoAlpha: 0.5})
                .set('.arrow-bio-3-right', {autoAlpha: 0.5})
                .fromTo(this.$refs.circleTop, {clipPath: this.initialCircle}, {clipPath: this.hideCircleTop, duration: time})
                .to('.bio1-chris', {scale: 0, duration: time}, '-='+time)
                .set('.bio-container-1', {autoAlpha: 0, onComplete:()=>{
                    this.isAnimating = false
                    if(onComplete) onComplete()
                }})
        },
        fromBio(index, circle, onFinish){
            
            if(index == 2)
                this.fromBioThree(onFinish)
            if(index == 1)
                this.fromBioTwo(circle, onFinish)
        },
        fromBioTwo(circle, onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var tl = gsap.timeline()
            var time = 0.85
            tl .set('.bio-container-1', {autoAlpha: 1})
                .set('.close-bio-btn-1', { autoAlpha: 0.5})
                .set('.bio1-chris', {scale: 0})
                .set('.bio-background', { backgroundColor: '#000'})
                .fromTo(this.$refs.circleTop, {clipPath: circle}, {clipPath: this.initialCircle, duration: time})
                .to('.bio1-chris', {scale: 1, duration: time}, '-='+time)
                .set('.close-bio-btn-1', { autoAlpha: 0.5, onComplete:()=>{
                    
                    this.isAnimating = false
                    onFinish()
                }}, '-=0.3')

                
        },
        fromBioThree(onFinish){
            if(this.isAnimating) return
            this.isAnimating = true
            var time = 0.85
            var tl = gsap.timeline()
            tl .set('.bio-container-1', {autoAlpha: 1})
                .set('.close-bio-btn-1', { autoAlpha: 0.5})
                .set('.bio1-chris', {scale: 0})
                .set('.bio-background', { backgroundColor: '#000'})
                .set('.arrow-bio-1-left', {autoAlpha: 0.5})
                .set('.arrow-bio-1-right', {autoAlpha: 0.5})
                .fromTo(this.$refs.circleTop, {clipPath: this.hideCircleTop}, {clipPath: this.initialCircle, duration: time})
                .to('.bio1-chris', {scale: 1, duration: time, onComplete:()=>{
                    
                    this.isAnimating = false
                    onFinish()
                }}, '-='+time)

        },
        hola(){
            
        },
        close(){
            if(this.isAnimating) return
            this.closing = true
            var elem =window.getComputedStyle(document.querySelector("#home-chris-non-change"))
            var tl = gsap.timeline()
            
            tl.set('.dim-bio1', {autoAlpha: 1})
            
                .to(this.$refs.circleTop, {clipPath: window.getComputedStyle(document.querySelector('.bio1-circle-hide')).clipPath, duration: 1.5})
                .to('.bio1-chris', {scale: 0, duration: 1.5}, '-=1.5')
                .to('.dim-bio1', {autoAlpha: 0}, '-=1.5')
                
                
                .to('.bio-name-1',  {  
                    top: Math.round(elem.top.replace('px', '')), 
                    left: Math.round(elem.left.replace('px', '')),
                    width: Math.round(elem.width.replace('px', '')),
                    height: Math.round(elem.height.replace('px', '')),
                    duration: 1.5
                }, '-=1.5')

                .fromTo('.home-chris', {
                    top: Math.round(elem0.top.replace('px', '')), 
                    left: Math.round(elem0.left.replace('px', '')),
                    width: Math.round(elem0.width.replace('px', '')),
                    height: Math.round(elem0.height.replace('px', '')),
                },
                 {  
                    top: Math.round(elem.top.replace('px', '')), 
                    left: Math.round(elem.left.replace('px', '')),
                    width: Math.round(elem.width.replace('px', '')),
                    height: Math.round(elem.height.replace('px', '')),
                    duration: 1.5
                }, '-=1.5')
                
                .to('.bio-background', { backgroundColor: 'unset', duration: 1.5}, '-=1.5')
                .to('.close-bio-btn-1', { autoAlpha: 0, duration: 0.5}, '-=1.5')
                .to('.arrow-bio-1-right', { autoAlpha: 0, duration: 0.5}, '-=1.5')
                .set('.bio-container-1', {autoAlpha: 0})
                
                .set('.popup-container', {autoAlpha: 0})
                .set('.top-circle-hover-zone', {autoAlpha: 1})
                .set('.bottom-circle-hover-zone', {autoAlpha: 1})
                .set('.home-arrow', {display: 'block'})
                .set('.home-bullets', {display: 'block', onComplete:()=>{ this.closing=false}})
                

            /*this.openFromHomeAnimation.reverse()

            this.openFromHomeAnimation.eventCallback("onReverseComplete", ()=>{
                gsap.set('.top-circle-hover-zone', {autoAlpha: 1})
                gsap.set('.bottom-circle-hover-zone', {autoAlpha: 1})
                gsap.set('.home-arrow', {display: 'block'})
                gsap.set('.home-bullets', {display: 'block'})
                this.openFromHomeAnimation.eventCallback("onReverseComplete", null)
            });*/

            
            this.$emit('close')
        },
        updateScreen(){
            var circle =document.getElementById('bio1-circle-non-change')
            var tempDir =''
            
            var elem = document.getElementById("landscape")
            if(window.getComputedStyle(elem).display=='none'){
                tempDir = 'portrait'
                circle.classList.remove('bio1-circle-landscape')
                circle.classList.add('bio1-circle-portrait')
                gsap.set('.close-bio-btn-1', {autoAlpha: 1})
            }else{
                tempDir = 'landscape'
                circle.classList.add('bio1-circle-landscape')
                circle.classList.remove('bio1-circle-portrait')
                gsap.set('.close-bio-btn-1', {autoAlpha: 0.5})
            }
            if(circle.style.clipPath){
                circle = circle.style
            }else{
                circle = window.getComputedStyle(document.querySelector('#bio1-circle-non-change'))
                
            }
                
            if(tempDir && tempDir!=this.direction){
                gsap.set(this.$refs.circleTop,{clipPath: circle.clipPath})
            }

            elem0 = window.getComputedStyle(document.querySelector('#bio-name-non-change'))
            

            gsap.set('.bio-name-1',  {  
                top: Math.round(elem0.top.replace('px', '')), 
                left: Math.round(elem0.left.replace('px', '')),
                width: Math.round(elem0.width.replace('px', '')),
                height: Math.round(elem0.height.replace('px', ''))
            })
            this.initialCircle = circle.clipPath
        },
        next(){
            this.$emit('next')
        },
        prev(){
            this.$emit('prev')
        },
        
    },
    computed: {
        

    },
    created(){
        window.addEventListener("resize", this.updateScreen);
    },
    mounted() {
        var circle = window.getComputedStyle(this.$refs.circleTop)

        this.initialCircle = circle.clipPath
        this.hideCircleTop = window.getComputedStyle(this.$refs.circleTopHide).clipPath
        

        gsap.set('.arrow-bio-1-left', {'--color' : '#000', overwrite: 'auto'})
        gsap.set('.arrow-bio-1-right', {'--color' : '#ed1b24', overwrite: 'auto'})
        this.updateScreen()
        //this.endNameStyle = window.getComputedStyle(document.querySelector(".home-chris"))
            
    }
}
</script>

<style>
.dim-bio1{
    position: absolute;
    background-color: #000;
    visibility: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
#bio-name-non-change{
    visibility: hidden;
}

#bio1-circle-non-change{
    background-color: blue;
    visibility: hidden;
}

.position-bio1{
    top: 10% !important;
    left: -8.5% !important;
}

.close-bio-btn-1, .close-bio-btn-3{
    top: 7%;
    opacity: 0.5;
    right: 6%;
  }

  .close-bio-btn-1:hover, .close-bio-btn-2:hover, .close-bio-btn-3:hover{
      opacity: 1;
  }
    .close-bio-btn-1::after,
    .close-bio-btn-1::before{
        background-color: #ed1b24;
        
    }
    .bio1-chris{
        position: absolute;
        left: 62%;
        top: 54%;
        width: 18vw;
        height: 18vw;
        border-radius: 99%;
    }
    .bio1-header{
        font-size: 4.55rem;
    }
    
    .bio1-text{
        font-family: 'quoteFont';
        color: white;
        position: absolute;
        top: 27%;
        left: 26.5%;
    }
    .bio1-p{
        font-size: 1.42rem;
        margin-top: -4rem;
        line-height: 5.5vh;
        font-family: 'chrisFont';
    }
    .bio-name img, .bio-name-1 img, .bio-name-2 img, .bio-name-3 img{
        width: 100%;
        height: 100%;
        
    }
    .bio-name, .bio-name-1{
        font-family: 'chrisFont';
        position: absolute;
        width: 16.15vw;
        top: 23%;
        left: 23.2%;
        height: 1.2vw;
    }

    .bio-container{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .bio1-circle, .bio1-circle-landscape{
        position:absolute; 
        background-color: #ed1b24; 
        width: 100%;
        height: 100%;
        top: 0;
        clip-path: circle(67% at 36% 9%);
    }    

    .bio1-circle-hide{
        position:absolute; 
        width: 100%;
        height: 100%;
        top: 0;
        clip-path: circle(61% at -50% -50%);
        visibility: hidden;
    }

@media (orientation: portrait) {
    .bio1-circle, .bio1-circle-portrait{
        clip-path: circle(70% at 8% 24%);
    }

    .bio1-chris {
        left: 51%;
        top: 55%;
        width: 34vw;
        height: 34vw;
    }

    .bio-name {
        top: 21.7%;
        left: 7%;
        width: 41.7vw;
        height: 3.2vw;
    }

    .bio1-text {
        left: 6.5%;
        top: 25%;
    }

    .bio1-header {
        font-size: 9.9rem;
        padding-left: 9rem;
        line-height: 11rem;
        width: 60%;
    }

    .bio1-p {
        font-size: 3.57rem;
        margin-top: -6rem;
        line-height: 4vh;
        padding-left: 9rem;
    }

    .position-bio1 {
        top: 9% !important;
        left: 0 !important;
        font-size: 17rem !important;
    }

    .close-bio-btn-1::after,
    .close-bio-btn-1::before{
        background-color: #000;
        
    }

    .close-bio-btn-1, .close-bio-btn-2, .close-bio-btn-3{
        opacity: 1;
    }
    
}
</style>