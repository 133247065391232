<template>
    <project  ref="project"
        classContainer="united-container united-2" 
        classOverlay="overlay-united"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"
        :vimeoContainer="this.vimeoContainer"/>
    <!--<img class="example" src="@/assets/united/home2.jpg" />-->
    <!--<img class="example" src="@/assets/vizio/vizio1-portrait.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'

    export default {
        components: {Project}, 
        name: "unitedTwo",
        data: () => ({
            ghostVideo: true,
            vimeoContainer:{
                name: 'united2'
            },
            bubbleOneData: {
                class: 'bubble-one-united-2',
                text: "<p>"+
                                "The United Way sees generational<br/>"+ 
                                "poverty as their biggest fight and<br/>"+
                                "wanted that to be center stage. The<br/>" + 
                                "story follows an impoverished only<br/>"+
                                "child of a single mother who is<br/>"+
                                "encouraged to follow his dream and<br/>"+
                                "ultimately achieves it, and who then<br/>"+
                                "pays it forward, encouraging another<br/>"+
                                "young person at a crucial time <br/>"+
                                "in their life."+
                            " </p>",
                title:'Donation Video', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop:'4%', color:'#373960', colorOverlay:'#fff', colorText: '#0f3456'
            },
            videoOneData:{
                class: 'united-2-video-1',
                videoUrl: 'assets/united/full-video.mp4',
                poster: 'assets/united/h2-video.jpg',
                isUnitedCenter: true,
                img: 'assets/united/h2-video.jpg',
                onAxisY:false
            },
            videoTwoData:{
                class: 'united-2-video-2',
                videoUrl: 'assets/united/home2-left.mp4',
                poster:'assets/united/united2-cover-1.jpg',
                onAxisY:false
            },
            videoThreeData:{
                class: 'united-2-video-3',
                videoUrl: 'assets/united/home2-right.mp4',
                poster:'assets/united/united2-cover-3.jpg',
                onAxisY:false
            },
        }),

        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            },
            closeMainVideo(){
                return this.$refs.project.closeMainVideo()
            }
        },
    }
</script>

<style>
    .united-2-video-1{
        top: 23.5% !important;
        left: 25% !important;
        width: 48.5vw !important;
        height: 27vw !important;
    }

    .united-2-video-2{
        top: 57% !important;
        left: 14.5% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .united-2-video-3{
        top: 29.5% !important;
        left: 66.5% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }
    
    .bubble-one-united-2{
        left: 72.25%;
        top: 60.25%;
        height: 17.5vw;
        width: 17.5vw;
    }

    @media (orientation: portrait) {
        .bubble-one-united-2 {
            left: 40.25%;
            top: 50.25%;
            height: 57vw;
            width: 57vw;
        }

        .united-2-video-3{
            top: 58% !important;
            
            left: 10.5% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-2-video-2{
            top: 24% !important;
            left: 52.25% !important;
            width: 34vw !important;
            height: 19vw !important;
        }

        .united-2-video-1{
            top: 33% !important;
            left: 6.5% !important;
            width: 84.5vw !important;
            height: 48vw !important;
        }
    }
</style>