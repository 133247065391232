<template>
<project ref="project"
        classContainer="hatford-container" 
        classOverlay="overlay-financial"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    <!--<img class="example" src="@/assets/hatford/home1.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue';
    
    export default {
        components: { Project }, 
        name: "hatford",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-hatford',
                text: "<p>"+
                            "Hartford Funds needed to replatform<br/>"+ 
                            "and also wanted to refresh their<br/>"+
                            "brand, a relatively common project<br/>" + 
                            "description in my experience.<br/>"+"</p><br/>"+
                            "<p>Financial advisors scanning info were<br/>"+
                            "our primary audience so clean and<br/>"+
                            "effective data visualization was a key<br/>"+
                            "aspect of the projects success."+
                        " </p>",
                title:'Financial', 
                fontSizeInnerText:"0.85rem",
                marginLeft:"-1.45rem",
                marginTop:"4%",
                color:"#465054",
                colorOverlay:"#fff",
                colorText:"#465054"
            },
            bubbleTwoData:{
                class:  'bubble-two-hatford-1',
                text:   "<p>BRANDING</p><br/>"+ 
                        "<p>CREATIVE DIRECTION</p><br/>" +
                        "<p>DIGITAL DESIGN & UI DESIGN</p><br/>"+
                        "<p>DATA VISUALIZATION <br/>& MODULAR DESIGN</p>" ,
                color:"#465054",
                colorOverlay:"#74848b",
                colorText:"#fff"
            },
            previewOneData:{
                class: "hatford-1-preview",
                img:"assets/hatford/hatford_slide_2.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"hatford-2-preview",
                img:"assets/hatford/hatford_slide_3.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
            previewThreeData:{
                class:"hatford-3-preview",
                img:"assets/hatford/hatford_slide_1.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .hatford-3-preview{
        width: 30vw;
        height: 26vw;
        top: 21%;
        left: 19%;
    }

    .hatford-2-preview{
        width: 8vw;
        height: 17vw;
        top: 27%;
        left: 72%;
    }

    .hatford-1-preview{
        width: 19vw;
        height: 36vw;
        top: 19%;
        left: 49.5%;
    }
    
    .bubble-two-hatford-1{
        left: 78.5%;
        top: 63.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-hatford{
        left: 20%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .hatford-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-hatford {
            left: 6%;
            top: 63.75%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-hatford-1{
            left: 48.5%;
            top: 21.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .hatford-3-preview{
            width: 55vw;
            height: 48vw;
            top: 34%;
            left: 0%;
        }

        .hatford-2-preview{
            width: 16vw;
            height: 34vw;
            top: 45%;
            left: 79%;
        }

        .hatford-1-preview{
            width: 39vw;
            height: 72vw;
            top: 32%;
            left: 47.5%;
        }
    }
   
</style>