<template>
    
    <div class="video-container" ref="videoContainer" @mouseover="hover()" @mouseleave="leave()" @click="onClick()" @touchstart="hover()">
        
        <div class="close-container  visible-landscape" v-if="isUnitedCenter" @mouseover="changeOpacity(0.5)" @mouseleave="changeOpacity(0.15)"
            @click="closeVideo()" ref="closeBtn"> 
            <div  class="close-btn close-btn-video-united" ref="closeX">  </div>  
        </div>
        
        <div v-if="isUnitedCenter" class="united-video united-video-background" ref="unitedVideoBackground" @click="closeVideo()"/>
        <video v-if="!isUnitedCenter" :muted="!isUnitedCenter" loop ref="video" 
            class="video-border" :controls="false" playsinline :poster="require('@/'+this.poster)" preload="metadata">
            <!--<source :src="require('@/'+this.videoUrl)" type="video/mp4">-->
            Your browser does not support the video tag.
        </video>

        <video-player v-if="isUnitedCenter" class="video-player-box"
            ref="videoPlayer"
            :options="this.playerOptions"
            :playsinline="true" />

        <!--<div v-if="isUnitedCenter" class="united-video" ref="unitedVideoLayer" >
            <div class="video-text top-text">watch the</div>
            <img class="united-video-icon" src="@/assets/united/play-btn.png" />
            <div class="video-text bottom-text">full video</div>
        </div>-->
    </div>
    
</template>

<style>


.close-btn-video-united{
    top: 2.5vw !important;
    right: 2.5vw !important;
    /*background-color: red;*/
    width: 5vw;
    height: 5vw;
}

.close-container{
    position: absolute;
    top: -42% !important;
    right: -43.5% !important;
    width: 5vw;
    height: 5vw;
    z-index: 3;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    
}

.close-btn-video-united::after,
.close-btn-video-united::before{
    background-color: white;
}
    .top-text{
        top: 35%;
    }

    .bottom-text{
        bottom: 35%;
    }
    .video-text{
        position: absolute;
        font-family: 'chrisFont';
        text-transform: uppercase;
        color: #fff;
        font: 1.4rem;
        left: 50%;
        transform: translateX(-50%);
    }
    .united-video-icon{
        position: absolute;
        width: 5vw;
        height: 5vw;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .united-video, .united-img-home{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 5px; top: 5px;
        opacity: 0;
        visibility: hidden;
        background-color: #373960;
    }
    .video-container video{
        width: 100%;
        height: 100%;
        
        object-fit: cover;
        
        position: absolute;
        
        font-size: 0;
        margin: 0;
        padding: 0;
    }
    .video-border{
        border: 5px solid white;
        border-radius: 7px;

    }

    .video-container video::-webkit-media-controls-start-playback-button {
        display: none !important;
    }

    .video-container{
        position: absolute;
        width: 100%;
        height: 100%;
        will-change: transform;
        background-color: black;
       /* animation: lateral-dis 6s infinite ease-in-out;
        will-change: transform;
*/
    }


    

@media (orientation: portrait) {
    .close-container {
        position: absolute;
        top: -108% !important;
        right: -4.5% !important;
        opacity: 1;
    }

    .video-text{
        font-size: 3.2rem;
    }

    .united-video-icon{
        width: 10vw;
        height: 10vw;
    }

    .top-text{
        top: 25%;
    }

    .bottom-text{
        bottom: 25%;
    }
}

.video-player-box{
    width: 100%;
    height: 100%;
    
}
.video-js{
    width: 100%;
    height: 100%;
    
    
}
 video[poster]{
    object-fit: cover;
  }
  .vjs-poster {
    background-size: cover;
    background-position: inherit;
  }
   
.vjs-theme-forest {
    --vjs-theme-forest--primary: #000 !important;
    --vjs-theme-forest--secondary: #fff;
}

.video-js:hover .vjs-big-play-button{
  background-color: rgba(0, 0, 0, 0);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' fill='%23000 '%3E%3Cpath fill-rule='evenodd' d='M44 88C19.738 88 0 68.262 0 44S19.738 0 44 0s44 19.738 44 44-19.738 44-44 44zm0-85C21.393 3 3 21.393 3 44c0 22.608 18.393 41 41 41s41-18.392 41-41C85 21.393 66.607 3 44 3zm16.063 43.898L39.629 60.741a3.496 3.496 0 01-3.604.194 3.492 3.492 0 01-1.859-3.092V30.158c0-1.299.712-2.483 1.859-3.092a3.487 3.487 0 013.604.194l20.433 13.843a3.497 3.497 0 01.001 5.795zm-1.683-3.311L37.946 29.744a.49.49 0 00-.276-.09.51.51 0 00-.239.062.483.483 0 00-.265.442v27.685c0 .262.166.389.265.442.1.053.299.118.515-.028L58.38 44.414A.489.489 0 0058.6 44a.49.49 0 00-.22-.413z'/%3E%3C/svg%3E");
  
}

</style>

<script>

    import gsap from 'gsap'
    import VideoPlayer from './VideoPlayer.vue'
    
    
    export default {
        name: "videoContainer",
        components:{
            VideoPlayer
        },
        props:{
            videoUrl: String,
            isUnitedCenter:{type:Boolean, default: false},
            poster: {type:String, default: ''},
            onAxisY: {type:Boolean, default: false}
        },
        data: () => ({
            
            clickActive: false,
            animationH: undefined,
            animationR:undefined,
            hDirection: 1,
            vDirection: 1,

            
       }),
        created() {
            window.addEventListener("resize", this.opacityUpdate);
            if(!this.isUnitedCenter){
                var url = require('@/'+this.videoUrl)
                
                //var v = document.querySelector('video');
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onreadystatechange = () => {
                    //console.log('state changed' , xhr.status)
                    if(xhr.status === 200) {
                        //console.log('success');
                        var blob = new Blob([xhr.response], {type: 'video/mp4'});
                        var url = URL.createObjectURL(blob);
                        this.$refs.video.src = url;
                    }
                }
                
                xhr.send();

            }
        },
        
        methods: {

            restart(){
                if(this.animationH!==undefined)
                    this.animationH.pause(0)
                if(this.animationR!==undefined)
                    this.animationR.pause(0)
            },
            animate(){
                if(this.animationH!==undefined)
                    this.animationH.play()
                if(this.animationR!==undefined)
                    this.animationR.play()
            },
             pauseAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.pause()
                if(this.animationR!==undefined)
                    this.animationR.pause()
            },
            resumeAnimations(){
                if(this.animationH!==undefined)
                    this.animationH.resume()
                if(this.animationR!==undefined)
                    this.animationR.resume()
            },
            isPortrait(){
                var elem = document.getElementById("landscape")
                if(window.getComputedStyle(elem).display=='none') return true
                return false    
            },
            opacityUpdate(){
                var elem = document.getElementById("landscape")
                
                if(window.getComputedStyle(elem).display=='none'){
                    gsap.to(this.$refs.closeX, {autoAlpha: 1})
                    return   
                }
                gsap.to(this.$refs.closeX, {autoAlpha: 0.15})
                
            },
            changeOpacity(val){
                var elem = document.getElementById("landscape")
                
                if(window.getComputedStyle(elem).display=='none')
                    return
                gsap.to(this.$refs.closeX, {autoAlpha: val})
            },
            hover(){
                if(this.clickActive) return
                gsap.set(this.$refs.videoContainer, {zIndex: 1})
                //if(this.isUnitedCenter)
                    //gsap.to(this.$refs.unitedVideoLayer,{autoAlpha:0.7})
                if(!this.isUnitedCenter)  this.$refs.video.play()
                this.$emit('videoHover')
            },
            manualReset(){
                
                if(!this.isUnitedCenter){
                    this.$refs.video.currentTime = 0
                    //this.$refs.video.load()
                    this.$refs.video.pause()
                    var vid = this.$refs.video.src
                    this.$refs.video.src = ""
                    this.$refs.video.src = vid
                    

                }
            },
            leave(){
                
                if(this.clickActive) return
                gsap.set(this.$refs.videoContainer, {clearProps:'z-index'})
                gsap.set(this.$refs.videoContainer, {zIndex: 0})
                //if(this.isUnitedCenter)
                    //gsap.to(this.$refs.unitedVideoLayer,{autoAlpha:0})
                if(!this.isUnitedCenter){
                    this.$refs.video.currentTime = 0
                    //this.$refs.video.load()
                    this.$refs.video.pause()
                }
                
                
                this.$emit('videoLeave')
            },
            random(min, max) {
                var delta = max - min;
                var r = (min + delta * Math.random());
                
                return r
            },
            moveX( ) {
                this.hDirection *= -1
                var distance = this.random(1, 25) * this.hDirection
                var dur = this.random(3, 5)
                gsap.config({force3D: false});
                gsap.ticker.lagSmoothing(0);
                this.animationH = gsap.to(this.$refs.videoContainer, {x: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveX()
                }})
            },
            moveY( ) {
                this.hDirection *= -1
                var distance = this.random(1, 25) * this.hDirection
                var dur = this.random(3, 5)
                gsap.config({force3D: false});
                gsap.ticker.lagSmoothing(0);
                this.animationH = gsap.to(this.$refs.videoContainer, {y: distance, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.moveY()
                }})
            },
            rotate( ) {
                
                //var angle = this.random(-1, 1)
                //var dur = this.random(5, 10)
                gsap.to(this.$refs.videoContainer, {rotate: 0.01})
                /*this.animationR = gsap.to(this.$refs.videoContainer, {rotate: angle, duration: dur, ease: "sine.inOut", onComplete:()=>{
                    this.rotate()
                }})*/
            },
            pulse(){
                
                if(!this.onAxisY) this.moveX()
                else  this.moveY()
                //this.rotate()
            },
            pause(){
                
            },
            play(){
                
            },
            onClick(){
                if(this.isUnitedCenter && !this.clickActive){
                    this.clickActive = true
                    this.pauseAnimations()
                    if(this.$refs.video) this.$refs.video.controls = true
                    
                    gsap.set('.btn-project-container', {autoAlpha: 0})
                    gsap.to(this.$refs.unitedVideoBackground, {autoAlpha: 0.9, scale: 10})
                    //gsap.to(this.$refs.unitedVideoLayer,{autoAlpha:0})
                    gsap.to('.h1-close-btn-2', {autoAlpha: 0})
                    gsap.to(this.$refs.videoContainer, {zIndex: 3})
                    

                    
                    
                    gsap.to('.project-arrow', {autoAlpha: 0})
                    gsap.to('.project-bullets', {autoAlpha: 0})
                     
                    
                    gsap.to(this.$refs.closeBtn, {autoAlpha: 1, onComplete:()=>{
                        if(this.$refs.video) this.$refs.video.play()
                        
                    }})
                    
                    
                }
            },
            closeVideo(){
                //console.log('HEY ' + this.isUnitedCenter + ' -> '+ this.clickActive)
                if(this.isUnitedCenter && (this.clickActive || this.isPortrait())){
                    gsap.set(this.$refs.videoContainer, {clearProps:'z-index'})
                    if(this.$refs.video){
                        this.$refs.video.load()
                        this.$refs.video.pause()
                        this.$refs.video.currentTime = 0
                        this.$refs.video.controls = false
                    }
                    if(this.$refs.videoPlayer){
                        this.$refs.videoPlayer.player.load()
                        this.$refs.videoPlayer.player.pause()
                        //this.$refs.videoPlayer.player.currentTime = 0
                        
                    }
                    gsap.to(this.$refs.unitedVideoBackground, {autoAlpha: 0, scale: 1, onComplete:()=>{
                        gsap.set('.btn-project-container', {autoAlpha: 1})
                        this.clickActive = false
                    }})
                    gsap.to(this.$refs.closeBtn, {autoAlpha: 0})
                    
                    var elem = document.getElementById("landscape")
                
                    if(window.getComputedStyle(elem).display=='none'){
                        gsap.to('.h1-close-btn-2', {autoAlpha: 1})
                        
                    }else{
                        gsap.to('.h1-close-btn-2', {autoAlpha: 0.15})
                    }
                    
                    gsap.to('.project-arrow', {autoAlpha: 1})
                    gsap.to('.project-bullets', {autoAlpha: 0.15})
                    
                    this.$emit('videoClose')
                }
                
            }
        },
        mounted() {

            if(this.isUnitedCenter){
                this.changeOpacity(0.15)
            }
                
            this.pulse()
            this.pauseAnimations()
            
            
        },
        computed:{
            playerOptions(){
                
                return {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    controls: true,

                    sources: [{
                        type: "video/mp4",
                        src: require('@/'+this.videoUrl)
                    }],
                    poster: require('@/'+this.poster),
                }
            }
            
        }
        

    }
</script>