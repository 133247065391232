<template>
    <project ref="project"
        classContainer="dustland-container" 
        classOverlay="overlay-agency"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
    
    <!--<img class="example" src="@/assets/dustland/home1.jpg" />-->
</template>

<script>
import Project from '../../Project.vue'
    
    export default {
        components: {Project  }, 
        name: "dustland",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-dustland",
                text: "<p>"+
                            "I helped Dustland develop their brand<br/>"+ 
                            "and design their site. We wanted both<br/>"+
                            "to feel sleek, innovative and modern<br/>" + 
                            "but also extremely approachable,<br/>"+
                            "down to earth and friendly as that<br/>"+
                            "truly was the culture of the company."+
                        "</p><br/>"+
                        "<p>"+
                            "I really enjoyed my time at Dustland<br/>"+
                            "and the branding and site effort was a<br/>"+
                            "success: the site won a Pixel Award<br/>"+
                            "and the overall effort helped the<br/>"+
                            "agency get acquired."+
                        "</p>",
                title:"Agency Branding",
                fontSizeInnerText:"0.75rem",
                marginLeft:"-2.2rem",
                marginTop:"4%",
                color:"#411c4b",
                colorOverlay:"#fff",
                colorText:"#411c4b"
            },
            bubbleTwoData:{
                class:"bubble-two-dustland",
                text:    "<p>BRANDING</p><br/>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>ANIMATION & MOTION</p>",
                color:"#411c4b",
                colorOverlay:"#813795",
                colorText:"#fff"
            },
            previewOneData:{
                class:"agency-3-preview-1",
                img:"assets/dustland/dustland_slide_2.jpg",
                forward:true,
                upDown:true,
                isBrik: false
            },
            previewTwoData:{
                class:"agency-3-preview-2",
                img:"assets/dustland/dustland_slide_3.jpg",
                forward:true,
                upDown:false,
                isBrik: false,
            },
            previewThreeData:{
                class:"agency-3-preview-3",
                img:"assets/dustland/dustland_slide_1.jpg",
                forward:false,
                upDown:true,
                isBrik: false
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-agency{
        background-color: #6F3483;
    }

    .agency-3-preview-1{
        width: 29vw;
        height: 17vw;
        top: 39%;
        left: 49%;
    }

    .agency-3-preview-2{
        width: 7vw;
        height: 14.5vw;
        top: 42%;
        left: 78%;
    }

    .agency-3-preview-3{
        width: 35vw;
        height: 23vw;
        top: 26%;
        left: 18%;
    }
    .bubble-two-dustland{
        left: 52%;
        top: 70.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-dustland{
        left: 26.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .dustland-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-dustland {
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }
        .bubble-two-dustland{
            top: 20.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .agency-3-preview-1{
            width: 46vw;
            height: 34vw;
            top: 39%;
            left: 49%;
        }

        .agency-3-preview-2{
            width: 14vw;
            height: 29vw;
            top: 48%;
            left: 83%;
        }

        .agency-3-preview-3{
            width: 55vw;
            height: 41vw;
            top: 35%;
            left: 4%;
        }
        
    }
</style>