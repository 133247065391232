<template>
    <project ref="project"
        classContainer="vizio-container vizio-1" 
        classOverlay="overlay-vizio"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"/>
    <!--<img class="example" src="@/assets/home1-detail-3.jpg" />-->
    <!--<img class="example" src="@/assets/vizio/mobile.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'

    export default {
        components: { Project }, 
        name: "VizioOne",
        data: () => ({
            ghostVideo: true,
            bubbleOneData: {
                class: 'bubble-one-vizio-1',
                text: "<p>"+
                            "This massive project entailed<br/>"+ 
                            "designing a distinctive digital<br/>"+
                            "ecosystem for several new connected<br/>" + 
                            "devices for Vizio.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "I quickly needed to bring in a team<br/>"+
                            "large enough to handle the workload<br/>"+
                            "and had not designed for TV before –<br/>"+
                            "super challenging.<br/>"+
                        "</p>",
                title:'Connected Devices', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop: "4%", color:"#03314e", 
                colorOverlay:"#fff", colorText:"#0f3456"
            },
            bubbleTwoData:{
                class:  'bubble-two-vizio-1',
                text:   "<p>BRANDING & DIGITAL <br/>ECOSYSTEM DESIGN</p><br/>"+
                        "<p>MOBILE, TABLET & TV DESIGN</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>MOTION & ANIMATION</p>",
                color: "#03314e",
                colorOverlay :"#174e82",
                colorText:"#fff"
            },
            videoOneData:{
                class: 'vizio-1-video-1',
                videoUrl: 'assets/vizio/home1-center.mp4',
                poster: 'assets/vizio/vizio1-cover-2.jpg',
                isUnitedCenter: false,
                onAxisY:false
            },
            videoTwoData:{
                class: 'vizio-1-video-2',
                videoUrl: 'assets/vizio/home1-left.mp4',
                poster:'assets/vizio/vizio1-cover-1.jpg',
                onAxisY:false
            },
            videoThreeData:{
                class: 'vizio-1-video-3',
                videoUrl: 'assets/vizio/home1-right.mp4',
                poster:'assets/vizio/vizio1-cover-3.jpg',
                onAxisY:false
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
            
        },
    }
</script>

<style>
    .vizio-1-video-3{
        width: 12vw !important;
        height: 16.5vw !important;
        top: 28% !important;
        left: 69.5% !important;
    }

    .vizio-1-video-2{
        width: 7.5vw !important;
        height: 13.5vw !important;
        top: 52% !important;
        left: 19.5% !important;
    }

    .vizio-1-video-1{
        width: 48.5vw !important;
        height: 27vw !important;
        top: 22% !important;
        left: 25.5% !important;
    }

    .bubble-two-vizio-1{
        left: 67.25%;
        top: 69.75%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-vizio-1{
        left: 14.5%;
        top: 27.25%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .vizio-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

@media (orientation: portrait) {
    .vizio-1-video-3{
        width: 24vw !important;
        height: 33vw !important;
        top: 20.5% !important;
        left: 62.5% !important;
    }

    .vizio-1-video-2{
        width: 15vw !important;
        height: 27vw !important;
        top: 55% !important;
        left: 11.5% !important;
    }

    .vizio-1-video-1{
        width: 84vw !important;
        height: 47vw !important;
        top: 33% !important;
        left: 7.5% !important;
    }

    .project-bullets {
        bottom: 11% !important;
    }
    
    .bubble-one-vizio-1 {
        left: 40%;
        top: 55.75%;
        height: 57vw;
        width: 57vw;
    }

    .bubble-two-vizio-1{
        left: 14.5%;
        top: 15.25%;
        height: 42.5vw;
        width: 42.5vw;
    }

    
}

</style>
