<template>
    <project ref="project"
        classContainer="dick-container dick-3" 
        classOverlay="overlay-dick"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewSliderOneData="this.previewSliderOneData"
        :previewSliderTwoData="this.previewSliderTwoData"/>
    
    <!--<img class="example" src="@/assets/dick/home3.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {
            Project 
        }, 
        name: "dickThree",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-dick-1",
                text: "<p>"+
                            "The new site had many engaging<br/>"+ 
                            "animation-driven experiences built to<br/>"+
                            "initially draw the user in, and then<br/>" + 
                            "content and community driven<br/>"+
                            "experiences built to be sticky and<br/>"+
                            "encourage repeat visits. Overall,<br/>"+
                            "Dick’s was thrilled with the new site<br/>"+
                            "and with how the new aesthetic could<br/>"+
                            "influence their overall brand."+
                        "</p>",
                title:"Branding & Redesign",
                fontSizeInnerText:"0.79rem",
                marginLeft:"-2.4rem",
                fontSize:"2rem", 
                marginTop:"4%",
                color:"#08483d",
                colorOverlay:"#fff",
                colorText:"#08483d",
                textSlice:5
            },
            bubbleTwoData:{
                class: "bubble-two-dick-1",
                text:   "<p>BRANDING</p>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>CASTING & PHOTOSHOOT ART DIRECTION</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p>",
                color:"#08483d",
                colorOverlay:"#12a58d",
                colorText:"#fff"
            },
            previewOneData:{
                class:"dick-3-preview",
                img:"assets/dick/dick3_slide_1.jpg",
                upDown:false,
                forward:true,
                isBrik: false
            },
            previewSliderOneData:{
                class:"dick-3-video-1",
                images:['assets/dick/dick1/3_Dicks_Screen_L_1_L.jpg', 'assets/dick/dick1/3_Dicks_Screen_L_2_L.jpg', 'assets/dick/dick1/3_Dicks_Screen_L_3_L.jpg']
            },
            previewSliderTwoData:{
                class:"dick-3-video-2",
                images:['assets/dick/dick1/3_Dicks_Screen_1_R.jpg', 'assets/dick/dick1/3_Dicks_Screen_2_R.jpg', 'assets/dick/dick1/3_Dicks_Screen_3_R.jpg']
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-dick{
        background-color: #026A5A;
    }
    .dick-3-preview{
        width: 38vw;
        height: 27.9vw;
        top: 20%;
        left: 33%;
    }

    .dick-3-video-2{
        top: 54.5% !important;
        left: 65% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .dick-3-video-1{
        top: 26% !important;
        left: 15.5% !important;
        width: 17.5vw !important;
        height: 11vw !important;
    }

    .bubble-two-dick-3{
        left: 69%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-dick-3{
        left: 23%;
        top: 58.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
@media (orientation: portrait) {
        .bubble-one-dick-3 {
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }
        .bubble-two-dick-3{
            top: 20.5%;
            left: 55%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .dick-3-preview{
            width: 57vw;
            height: 44.25vw;
            top: 37%;
            left: 21%;
        }

        .dick-3-video-2{
            top: 54.5% !important;
            left: 65% !important;
            width: 27.75vw !important;
            height: 16.5vw !important;
        }

        .dick-3-video-1{
            top: 36% !important;
            left: 7.5% !important;
            width: 26.25vw !important;
            height: 16.5vw !important;
        }
    }
    
</style>