<template>
    <project ref="project"
        classContainer="vizio-container vizio-3" 
        classOverlay="overlay-vizio"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :videoOneData="this.videoOneData"
        :videoTwoData="this.videoTwoData"
        :videoThreeData="this.videoThreeData"/>    
</template>

<script>
    import Project from '../../Project.vue'
    export default {
        components: { Project }, 
        name: "VizioThree",
        data: () => ({
            ghostVideo: true,
            bubbleOneData: {
                class: 'bubble-one-vizio-2',
                text: "<p>"+
                            "Vizio’s new ecosystem was unveiled<br/>"+ 
                            "at CES where it won a Best of CES.<br/>"+
                            "Vizio wanted to make a splash at this<br/>" + 
                            "show so were thrilled.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "Vizio also received an enthusiastic<br/>"+
                            "response from the CE industry, who<br/>"+
                            "praised the ecosystem for it’s style<br/>"+
                            "and edge.<br/>"+
                        "</p>",
                title:'Connected Devices', fontSizeInnerText:'0.85rem',
                marginLeft:'-2.2rem', marginTop: "4%", color:"#03314e", 
                colorOverlay:"#fff", colorText:"#0f3456"
            },
            videoOneData:{
                class: 'vizio-3-video-1',
                videoUrl: 'assets/vizio/home3-center.mp4',
                poster: 'assets/vizio/vizio3-cover-2.jpg',
                isUnitedCenter: false,  
                onAxisY:false
            },
            videoTwoData:{
                class: 'vizio-3-video-2',
                videoUrl: 'assets/vizio/home3-left.mp4',
                poster:'assets/vizio/vizio3-cover-1.jpg',
                onAxisY:false
            },
            videoThreeData:{
                class: 'vizio-3-video-3',
                videoUrl: 'assets/vizio/home3-right.mp4',
                poster:'assets/vizio/vizio3-cover-3.jpg',
                onAxisY:false
            },
            bubbles: [],
            nBubbles: ['.bubble-one-vizio-3'],
            videos: ['.vizio-3-video-1', '.vizio-3-video-2', '.vizio-3-video-3'],
            tl: undefined,
            vizioDetailOne: "<p>"+
                                "Vizio’s new ecosystem was unveiled<br/>"+ 
                                "at CES where it won a Best of CES.<br/>"+
                                "Vizio wanted to make a splash at this<br/>" + 
                                "show so were thrilled.<br/>"+
                            "</p><br/>"+
                            "<p>"+
                                "Vizio also received an enthusiastic<br/>"+
                                "response from the CE industry, who<br/>"+
                                "praised the ecosystem for it’s style<br/>"+
                                "and edge.<br/>"+
                            "</p>",
        }),

        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .overlay-vizio {
        background-color: #163F67;   
    }
    .vizio-3-video-3{
        top: 25.5% !important;
        left: 74.5% !important;
        width: 7.5vw !important;
        height: 14vw !important;
    }

    .vizio-3-video-2{
        top: 46.5% !important;
        left: 19.5% !important;
        width: 12.5vw !important;
        height: 17vw !important;
    }
    .vizio-3-video-1{
        top: 22% !important;
        left: 27.5% !important;
        width: 48vw !important;
        height: 27vw !important;
    }

    .bubble-one-vizio-3{
        left: 72.5%;
        top: 60%;
        height: 17.5vw;
        width: 17.5vw;
    }

    @media (orientation: portrait) {
        .bubble-one-vizio-3 {
            left: 35.5%;
            top: 47.25%;
            height: 57vw;
            width: 57vw;
        }

        .vizio-3-video-3{
            width: 15vw !important;
            height: 28vw !important;
            top: 20.5% !important;
            left: 62.5% !important;
        }

        .vizio-3-video-2{
            width: 25vw !important;
            height: 34vw !important;
            top: 55% !important;
            left: 11.5% !important;
        }

        .vizio-3-video-1{
            width: 84vw !important;
            height: 47vw !important;
            top: 33% !important;
            left: 7.5% !important;
        }
    }
</style>