<template>
    <project ref="project"
        classContainer="wawa-container wawa-1" 
        classOverlay="overlay-wawa"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"
        :previewThreeData="this.previewThreeData"/>
   
    <!--<img class="example" src="@/assets/wawa/home1.jpg" />-->
</template>

<script>
    import Project from '../../Project.vue'
    
    export default {
        components: {
            Project 
        }, 
        name: "wawaOne",
        data: () => ({
            bubbleOneData: {
                class: 'bubble-one-wawa-1',
                text: "<p>"+
                            "Wawa is one of many clients I’ve<br/>"+ 
                            "worked with over the years where we<br/>"+
                            "worked in a longterm retained team<br/>" + 
                            "relationship.<br/>"+
                        "</p><br/>"+
                        "<p>"+
                            "I love working with brands longterm –<br/>"+
                            "the depth of understanding you<br/>"+
                            "develop with the brand is great. It<br/>"+
                            "certainly helped on this one – Wawa<br/>"+
                            "loved it.<br/>"+
                        " </p>",
                title:"Digital Retainer",
                fontSizeInnerText:"0.79rem",
                marginLeft:"-1.5rem",
                marginTop:"4%",
                color:"#751420",
                colorOverlay:"#fff",
                colorText:"#751420"
            },
            bubbleTwoData:{
                class:  'bubble-two-wawa-1',
                text:   "<p>BRANDING</p><br/>"+
                            "<p>CONCEPTING & CREATIVE DIRECTION</p><br/>"+
                            "<p>DIGITAL & UI DESIGN</p><br/>"+
                            "<p>E-COMMERCE DESIGN</p>",
                color:"#751420",
                colorOverlay:"#cd2337",
                colorText:"#fff"
            },
            previewOneData:{
                class:"wawa-1-preview-1",
                img:"assets/wawa/wawa_slide_2.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            previewTwoData:{
                class:"wawa-1-preview-2",
                img:"assets/wawa/wawa_slide_3.jpg",
                forward:false,
                isBrik: false,
                upDown:true
            },
            previewThreeData:{
                class:"wawa-1-preview-3",
                img:"assets/wawa/wawa_slide_1.jpg",
                forward:true,
                isBrik: false,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-wawa{
        background-color: #C51230;
    }

    .wawa-1-preview-1{
        width: 25vw;
        height: 21vw;
        top: 34%;
        left: 26%;
    }

    .wawa-1-preview-2{
        width: 8vw;
        height: 17vw;
        top: 33%;
        left: 18%;
    }

    .wawa-1-preview-3{
        width: 29vw;
        height: 28vw;
        top: 19%;
        left: 52%;
    }

    .bubble-two-wawa-1{
        left: 70%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }
    .bubble-one-wawa-1{
        left: 23.5%;
        top: 60.75%;
        height: 17.5vw;
        width: 17.5vw;
    }
    
    .wawa-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-wawa-1 {
            top: 60.75%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-wawa-1{
            left: 55%;
            top: 17.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .wawa-1-preview-1{
            width: 50vw;
            height: 42vw;
            left: 12%;
        }

        .wawa-1-preview-2{
            width: 16vw;
            height: 34vw;
            top: 42%;
            left: 5%;
        }

        .wawa-1-preview-3{
            width: 46vw;
            height: 46vw;
            top: 31%;
            left: 51%;
        }
    }

</style>