<template>
    <project  ref="project"
        classContainer="brink-container brink-1" 
        classOverlay="overlay-brink"
        :ghostVideo="this.ghostVideo"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :videoOneData="this.videoOneData"
        :previewOneData="this.previewOneData"/>

    <!--<img class="example" src="@/assets/brink/home1.jpg" />-->
    <!--<img class="example" src="@/assets/brink/brink-portrait.jpg" />-->
</template>

<script>
import Project from '../../Project.vue'

    export default {
        components: {
            Project 
        }, 
        name: "brinkOne",
        data: () => ({
            ghostVideo: false,
            bubbleOneData: {
                class: 'bubble-one-brink-1',
                text: "<p> Brink is a video game about<br/>"+
                            "civilization on the brink of collapse <br/>"+
                            "and at war on the Ark, the last above<br/>"+
                            "water surface on Earth.</p><br/>"+
                            "<p>This game-to-web integration site<br/> needed to make it easy for players to<br/>"+
                            "get live views of gameplay and<br/>assess weapons and performance.</p>",
                title: "Data Viz", fontSizeInnerText:"0.85rem", marginLeft:"-2rem",
                marginTop:"2%", color:"#14677b", colorOverlay:"#fff", colorText:"#14677b"
            },
            bubbleTwoData:{
                class:  'bubble-two-brink-1',
                text:   "<p>UI DESIGN</p><br/><p>DATA VISUALIZATION</p><br/>"+
                            "<p>ICONOGRAPHY DESIGN</p><br/> <p>DIGITAL DESIGN</p>",
                color:"#14677b", colorOverlay:"#22afd3", colorText:"#fff"
            },
            videoOneData:{
                class: 'brink-1-video',
                videoUrl: 'assets/brink/home1-left.mp4',
                poster: 'assets/brink/brink1-cover.jpg',
                isUnitedCenter: false,
                onAxisY:false
            },
            previewOneData:{
                class: 'brink-1-preview',
                img:"assets/brink/brink_slide_1.jpg",
                isBrik: true,
                forward:true,
                upDown:true
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>
    .overlay-brink{
        background-color: #1399B6;
    }

    .brink-1-preview{
        width: 40vw;
        height: 32vw;
        top: 15%;
        left: 38.5%;
    }
    
    .brink-1-video{
        top: 29% !important;
        left: 17% !important;
        width: 19.5vw !important;
        height: 11vw !important;
    }

    .bubble-two-brink-1{
        left: 78.75%;
        top: 61.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-brink-1{
        left: 26.5%;
        top: 60.5%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .brink-preview-img{
        width: 100%;
        position: absolute;
    }

    .brink-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-brink-1 {
            left: 10.5%;
            top: 60.5%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-brink-1{
            left: 54.75%;
            top: 19.5%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .brink-1-video{
            top: 35% !important;
            left: 1% !important;
            width: 39vw !important;
            height: 22vw !important;
        }

        .brink-1-preview{
            width: 80vw;
            height: 64vw;
            top: 31%;
            left: 15.5%;
        }
    }

</style>