<template>
    
    <div class="preview-container" ref="previewContainer">
        <div class="preview-css" ref="preview" @mouseover="hover()" @mouseleave="leave()">
           <img ref="previewImg"  class="preview-img" :src="require('@/'+this.img )"/>
        </div>
    </div>
    
</template>

<style>

    .preview-css{
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .preview-img{
      position: absolute;
      width: 100%;
    }

    .preview-container {
        position: absolute;
        perspective: 250px;
       
    }

</style>

<script>
    import gsap from 'gsap'
    export default {
        name: "preview",
        props:{
            img : String,
            forward: Boolean,
            upDown: {type: Boolean, default: true},
            isBrik: {type: Boolean, default: false},

        },
        data: () => ({
            animation: '',
            data:'',
            initialTransform: '',
            hoverTransform: '',
            upAndDownAnimation: undefined,
            isHover: false, 
            diagonalDisplacement: undefined,
            hoverTl: undefined,
            isFullPause: false,
            promiseCall: undefined
       }),
        
        computed: {
  
           
            
        },
        methods: {
            fullPause(){
                this.isFullPause = true
                if(this.diagonalDisplacement)
                    this.diagonalDisplacement.pause()
                
                if(this.upAndDownAnimation!==undefined){
                    this.upAndDownAnimation.pause()
                    if(this.promiseCall){
                        this.promiseCall.kill()
                        this.promiseCall =undefined
                    } 
                }
                    
            },
            displacementPause(){
                if(this.diagonalDisplacement)
                    this.diagonalDisplacement.pause()
            },

            hover(){
                if(this.isHover) return
                this.isHover = true
                this.$emit('previewHover')
                this.diagonalDisplacement.pause()
                gsap.ticker.lagSmoothing(0);
                var trans = this.$refs.preview.style.transform
                var tl = gsap.timeline()
                tl.set(this.$refs.previewContainer,    {zIndex: 1})
                .fromTo(this.$refs.preview, {transform: trans} ,  {transform: this.hoverTransform, duration:0.25})
                this.hoverTl = tl
                
                
                
                
            },
            leave(){
                this.isHover = false
                this.$emit('previewLeave')
                if(!this.hoverTl.reversed()){
                    this.hoverTl.reverse()
                    this.hoverTl.eventCallback("onReverseComplete", ()=>{
                        gsap.set(this.$refs.previewContainer,    {zIndex: 0})
                        this.diagonalDisplacement.play()
                        this.hoverTl.eventCallback("onReverseComplete", null)
                        this.isFullPause = false
                    })
                }
                
                
                
            },
            createAnimation(I){
                
                var elem = this.$refs.previewContainer
                
                var height = window.getComputedStyle(elem).height.replace('px','')
                var width = window.getComputedStyle(elem).width.replace('px','')
                
                var scaleFactor = width/I.width
                var newHeight = I.height*scaleFactor
                var displacement = Math.max(newHeight- height, 0)
                
                gsap.ticker.lagSmoothing(0);
                var tl = gsap.timeline()
                tl.fromTo(this.$refs.previewImg,{translateY:0}, {y: -displacement, duration: 12, yoyo: true, repeatDelay:2, repeat: -1})

                
                
                this.updateMovement()
                return tl
                
                

            },
            animate(){
                gsap.delayedCall(3, ()=>{
                    if(this.isHover || this.isFullPause) return
                    if(this.upAndDownAnimation!==undefined)
                        this.upAndDownAnimation.resume()
                    if(this.diagonalDisplacement !== undefined)
                        this.diagonalDisplacement.resume()
                })
                /*var timer = this.random(0, 5)
                    
                gsap.delayedCall(timer, ()=>{
                    if(this.upAndDownAnimation!==undefined)
                        this.upAndDownAnimation.resume()
                    if(this.diagonalDisplacement !== undefined)
                        this.diagonalDisplacement.resume()
                });*/

                    
                
                    
            },
            restart(){
                if(this.upAndDownAnimation !== undefined)
                    this.upAndDownAnimation.pause(0)
                
            },
            random(min, max) {
                var delta = max - min;
                var r = (min + delta * Math.random());
                
                return r
            },
            resumeAnimations(){
                
                this.isFullPause = false
            
                if(this.upAndDownAnimation!==undefined)
                   this.promiseCall= gsap.delayedCall(1, ()=>{ this.upAndDownAnimation.resume() }) 
                if(this.diagonalDisplacement !== undefined)
                    this.diagonalDisplacement.play()
                
            },
            pauseAnimations(){
                if(this.isHover) return
                if(this.upAndDownAnimation!==undefined){
                    this.upAndDownAnimation.pause()
                    if(this.promiseCall){
                        this.promiseCall.kill()
                        this.promiseCall =undefined
                    } 
                }
                    
                if(this.diagonalDisplacement !== undefined)
                    this.diagonalDisplacement.pause()
            },
            pause(){
                //this.animation.pause()
            },
            play(){
                if(this.upAndDownAnimation!==undefined)
                    this.upAndDownAnimation.play()
                if(this.diagonalDisplacement !== undefined)
                    this.diagonalDisplacement.play()
            },
            updateMovement(){
                var elem = document.getElementById("landscape")
                
                var magicNumber = 15
                if(window.getComputedStyle(elem).display=='none')
                    magicNumber = 7
                
                var x = (this.forward? -magicNumber : -magicNumber)
                var y = (this.forward? magicNumber : -magicNumber)
                this.diagonalDisplacement = gsap.fromTo(this.$refs.preview, {y: -y},   {y: y, x: x, yoyo: true, duration:12, repeat: -1}).delay(Math.random()*3)

            }
            
        },
        mounted() {
            
            
            this.initialTransform = 'rotate3d(1, 0, 0, 0deg) rotate3d(0, 1, 0, '+ (this.forward?'-':'')+ '3deg) rotate3d(0, 0, 1,   '+ (this.forward?'':'-') + '18deg) skew( '+ (this.forward?'-':'') + '5deg)'
            
            
            this.hoverTransform += 'rotate3d(1, 0, 0, 0deg) rotate3d(0, 1, 0, 0deg) rotate3d(0, 0, 1, '+ (this.forward?'':'-')+ '10deg) skew(0deg)'
            
            gsap.set(this.$refs.preview,    {transform: this.initialTransform})
              
            
            var I = new Image()
            I.onload = ()=>{
                this.upAndDownAnimation = this.createAnimation(I)
                this.pauseAnimations()        
            }
            I.src = require('@/'+this.img )
            
        },
        created(){
            window.addEventListener("resize", this.updateMovement)
        }



    }
</script>


