<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import HatfordVue from './financial/Hatford.vue'
import ChaseVue from './financial/Chase.vue'
import MTBankVue from './financial/MTBank.vue'

export default { 
    name: "financialSlider",
    components: {
        Chapter
    },
    data: () => ({
      sliderData:{
        classContainer: 'financial-slider-container',
        classOverlay: 'financial-crop-overlay',
        classSlider: 'hatford-background',
        classCloseBtn: 'close-btn-financial',
        logos:[
          {
            path:require('@/assets/hatford/logo.png'),
            class: 'hatford-logo'
          },
          {
            path:require('@/assets/chase/logo.svg'),
            class: 'chase-logo'
          },
          {
            path:require('@/assets/mtbank/logo.svg'),
            class: 'mtbank-logo'
          }
        ],
        slides:[<HatfordVue/>, <ChaseVue/>, <MTBankVue/>]
      }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>
  .hatford-background{
    background-image: url('~@/assets/hatford/background.jpg');
  }

  .hatford-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 14% !important;
  }
  .chase-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 14.5% !important;

  }
  .mtbank-logo{
    top: 7% !important;
    left: 6vw !important;
    width: 14% !important;
  }

@media (orientation: portrait) {
  .hatford-logo{
    height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
  .chase-logo{
    height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;

  }
  .mtbank-logo{
    height: 7vw !important;
    top: 4.5% !important;
    left: 6vw !important;
    width: auto !important;
  }
}
</style>