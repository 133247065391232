<template>
    <project ref="project"
        classContainer="tbn-container" 
        classOverlay="overlay-replatform"
        :bubbleOneData="this.bubbleOneData"
        :bubbleTwoData="this.bubbleTwoData"
        :previewOneData="this.previewOneData"
        :previewTwoData="this.previewTwoData"/>
    
    <!--<img class="example" src="@/assets/tbn/home1.jpg" />-->
    
</template>

<script>
    import Project from '../../Project.vue'

    
    export default {
        components: {
                Project 

        }, 
        name: "tbn",
        data: () => ({
            bubbleOneData: {
                class:"bubble-one-tbn",
                text: "<p>"+
                            "TBN needed to replatform, refresh<br/>"+ 
                            "their brand and provide better ways<br/>"+
                            "for viewers to find shows and show<br/>" + 
                            "content–quite a task considering their<br/>"+
                            "vast catalog of content going back<br/>"+
                            "decades. Through a robust taxonomy,<br/>"+
                            "UX and design effort, we created a<br/>"+
                            "solution that simplified show-finding<br/>"+
                            "and elevated the brand.<br/>"+
                        "</p>",
                title:"Replatform & Redesign",
                textSlice:8,
                fontSizeInnerText:"0.79rem",
                marginLeft:"-5rem",
                fontSize:"2rem", 
                marginTop:"5%",
                color:"#463e2d",
                colorOverlay:"#fff",
                colorText:"#463e2d"
            },
            bubbleTwoData:{
                class:"bubble-two-tbn",
                text:   "<p>CONCEPTING</p><br/>"+
                        "<p>CREATIVE DIRECTION</p><br/>"+
                        "<p>DIGITAL & UI DESIGN</p><br/>"+
                        "<p>MODULAR DESIGN</p>",
                color:"#463e2d",
                colorOverlay:"#867656",
                colorText:"#fff"
            },
            previewOneData:{
                class:"tbn-preview-1",
                img:"assets/tbn/tbn_slide_2.jpg",
                upDown:true,
                forward:true,
                isBrik: false
            },
            previewTwoData:{
                class:"tbn-preview-2",
                img:"assets/tbn/tbn_slide_1.jpg",
                upDown:true,
                forward:false,
                isBrik: false,
            },
        }),
        methods: {
            openNonAnimated(){
                this.$refs.project.openNonAnimated()
            },
            open(onOpened){
                this.$refs.project.open(onOpened)
            },
            close(){
                this.$refs.project.close()
            },
            pause(){
                this.$refs.project.pause()
            },
            play(){
                this.$refs.project.play()
            },
            isHovering(){
                return this.$refs.project.isHovering
            }
        },
    }
</script>

<style>

    .tbn-preview-1{
        width: 22vw;
        height: 26vw;
        top: 30%;
        left: 58%;
    }

    .tbn-preview-2{
        width: 39vw;
        height: 26vw;
        top: 20%;
        left: 19.5%;
    }

    .bubble-two-tbn{
        left: 77.5%;
        top: 69.5%;
        height: 13vw;
        width: 13vw;
    }

    .bubble-one-tbn{
        left: 12.5%;
        top: 58.75%;
        height: 17.5vw;
        width: 17.5vw;
    }

    .tbn-container{
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (orientation: portrait) {
        .bubble-one-tbn {
            top: 60.5%;
            height: 57vw;
            width: 57vw;
        }

        .bubble-two-tbn{
            top: 20.5%;
            left: 55%;
            height: 42.5vw;
            width: 42.5vw;
        }

        .tbn-preview-1{
            width: 44vw;
            height: 52vw;
            top: 30%;
            left: 51%;
        }

        .tbn-preview-2{
            width: 59.5vw;
            height: 39vw;
            top: 33%;
            left: 4.5%;
        }
    }
</style>