<template>
  <chapter ref="chapter" :sliderData="this.sliderData"/>
</template>

<script>
import Chapter from '../Chapter.vue'

import UnitedOneVue from './united/UnitedOne.vue'
import UnitedTwoVue from './united/UnitedTwo.vue'
import UnitedThreeVue from './united/UnitedThree.vue'

export default { 
    name: "UnitedSlider",
    components: {
        Chapter
    },
    data: () => ({
        sliderData:{
          classContainer: 'united-slider-container',
          classOverlay: 'united-crop-overlay',
          classSlider: 'united-background',
          classCloseBtn: 'close-btn-united',
          logos:[
            {
              path:require('@/assets/united/logo.svg'),
              class: 'united-logo'
            }
          ],
          slides:[<UnitedOneVue />, <UnitedTwoVue/>, <UnitedThreeVue/>]
        }
    }),
    methods:{
      pauseProject(){
        this.$refs.chapter.pauseProject()
      },
      playProject(){
        this.$refs.chapter.playProject()
      },
      open(openFromHome){
        this.$refs.chapter.open(openFromHome)
      },
      mask(color){
        this.$refs.chapter.mask(color)
      },

      maskLeave(){
        this.$refs.chapter.maskLeave()
      },
      maskBackground(){
        this.$refs.chapter.maskBackground()
      },
      maskBackgroundLeave(){
        this.$refs.chapter.maskBackgroundLeave()
      },
      unBlur(){
        this.$refs.chapter.unBlur()
      },
      blur(){
        this.$refs.chapter.blur()
      },
      display(){
        this.$refs.chapter.display()
      },
      disapearTo(onComp){
        this.$refs.chapter.disapearTo(onComp)
      }
    },
}
</script>

<style>


  .united-background{
    background-image: url('~@/assets/united/background.jpg');

  }

  .slider-container-crop,
  .slider-container,
  .crop-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    
    overflow: hidden;
    
  }
  .crop-overlay {
    background-color: var(--color);
    
  }

@media (orientation: portrait) {
  .united-logo{
    height: 12vw;
  }
}
  
</style>